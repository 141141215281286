import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import '../../../../../styles/custom.css';
import { TicketStatus, userRoles, TimeZones, routes } from '../../../../../utility/constants/constants';
import {
    CustomNoDataComponent,
    trimString,
    makeFirstLetterCapital,
    convertDateTimezone,
    convertDateAndTimeTimezone, getUser, getWeekStartAndEndDay, convertTicketsDataToCsvType, convertDateToDifferentTZ, convertAreaScheduleTime, converShiftScheduleTime, convertDateFormatV5
} from '../../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import { SwapOutlined } from '@ant-design/icons';
import * as actions from '../../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { MagicSpinner, PushSpinner, SwishSpinner, WaveSpinner, ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import storage from '../../../../../utility/storage';
import cloneDeep from 'clone-deep';
import { tzDateConverterTool_V1, tzDateConverterTool_V2, tzTimeConversionTool_V1, tzTimeConversionToolwithAbb_V1, tzUTCDateConverterTool_V2 } from '../../../../../utility/TimezoneOperations/timezoneUtility';
import ShiftCancelRequestForm from '../ShiftCancelComponent/ShiftCancelRequestForm';
var moment = require('moment');

let ShiftTable = (props) => {

    const [passShiftId, setPassShiftId] = useState(null);

    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchShift && state.form.searchShift.values ? state.form.searchShift.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.shift_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.shiftList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.shiftList_no_of_rows);
    const isShiftList = useSelector(state => state.TimeTrackerModReducer.isShiftList);
    const shiftListCurPage = useSelector(state => state.TimeTrackerModReducer.shiftListCurPage);
    const SearchOrResetShiftListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetShiftListClicked);

    const accountTimezone = 'America/Los_Angeles';

    const FormatException = (exceptions) => {
            const filteredExp = exceptions.filter((item) => item.status === 'pending')
            if(filteredExp?.length){
                return <span style={{color : '#c9302c', fontWeight : 'bold'}}>Pending</span>
            }else {
                return <span style={{color : '#19BF3A', fontWeight : 'bold'}}>Updated</span>
            }
    }

    const data = props.shiftList && props.shiftList.map((value) => {
        let shiftDate = moment(tzDateConverterTool_V1(value?.date)).format('YYYY-MM-DD');
        let currentLiveDate = moment(new Date()).format('YYYY-MM-DD');
        return {
            accountName: value && value.accounts ? value.accounts.map((item, i) => makeFirstLetterCapital(`${item.name}${value.accounts.length === i + 1 ? '' : ', '}`)) : '-',
            address: value && value.accounts ? value.accounts.map((item) => makeFirstLetterCapital(item.address?.formatted_address)) : '-',
            date: value && value.date ? tzDateConverterTool_V1(value.date) : '−',
            days: value && value.date ? tzDateConverterTool_V2(value.date) : '-',
            shift_time: value && value.shift ? `${tzTimeConversionToolwithAbb_V1(value.shift?.start_time, moment(new Date()).format('YYYY-MM-DD'))} - ${tzTimeConversionToolwithAbb_V1(value.shift?.end_time, moment(new Date()).format('YYYY-MM-DD'))}` : '-',
            createdBy: value && value.shift && value.shift.createdBy ? `${makeFirstLetterCapital(value.shift.createdBy.first_name)} ${makeFirstLetterCapital(value.shift.createdBy.last_name)}` : '-',
            exception : value && value.complaints?.length ? FormatException(value.complaints) : 'N/A',
            actions:
                <>

                {
                    props.match?.params?.id ? <>
                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="View Shift Details" className="mr_20 account_edit_icn" onClick={() => props.history.push(`/shift-schedule/preview-shift-schedule/${value.id}`)}  ><i class="fas fa-eye" aria-hidden="true" ></i></span>
                    {value?.status == 'cancelled' || value?.status == 'closed' ? null : (
                        <>
                            <span data-tip="Edit Single shift day" className="mr_20 account_edit_icn" onClick={() => { props.handleEditPerDayShiftDetailClicked(value.id); }}><i class="fa fa-pencil" aria-hidden="true" ></i></span>
                            <button data-tip="Cancel Shift" onClick={() => { props.handleFrequentTimeTracker_openModal(); setPassShiftId(value.id); }} data-test="cancel_button" type="button" className="btn cst_btn btn-danger mr-3" data-toggle="modal" data-target="#shiftCancelRequest" data-backdrop="static" data-keyboard="false"> Cancel </button>
                        </>
                    )}
                    </> 
                    : <>
                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="View Shift Details" className="mr_20 account_edit_icn" onClick={() => props.history.push(`/shift-schedule/preview-shift-schedule/${value.id}`)}><i class="fas fa-eye" aria-hidden="true" ></i></span>
                    {(value?.status == 'cancelled' || value?.is_swap == true) ? null : (shiftDate < currentLiveDate) ? null : value?.check_int_at?.length > 0 ? null : (
                        <>
                            <span data-tip="Shift Swap" className="mr_20 account_edit_icn shiftSwapIcon" onClick={() => { props.handleSwapRequestSanction(value.id) }} data-test="account_trash"><SwapOutlined style={{ fontSize: '24px' }} /></span>
                        </>
                    )}

                    {(value?.status == 'cancelled' || value?.is_time_card == true) ? null : (shiftDate > currentLiveDate || shiftDate !== currentLiveDate) ? null : !(value?.check_out_at?.length) ? null : (
                        <>
                            <span data-tip="Create Timecard correction" className="mr_20 account_edit_icn" onClick={() => { props.handleEditTimeCardCorrectionFormClicked({ shiftId: value.shift_id, shiftDetailId: value.id }); }}><i class="fa fa-clock" aria-hidden="true" ></i></span>
                        </>
                    )}
                    </>
                 }
                 
                </>
        }
    })

    const columns = [
        {
            Header: <><span> Job </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'accountName',
            show: props.shiftList.length !== 0,
        },
        {
            Header: <><span> Job / Address</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'address',
            show: props.shiftList.length !== 0,
        },
        {
            Header: <><span> Date </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'date',
            show: props.shiftList.length !== 0,
        },
        {
            Header: <><span> Days </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'days',
            show: props.shiftList.length !== 0,
        },
        {
            Header: <><span> Time </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'shift_time',
            show: props.shiftList.length !== 0,
        },
        {
            Header: <><span> Created by </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'createdBy',
            show: props.shiftList.length !== 0,
        },
        {
            Header: <><span> Exception </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'exception',
            show: props.shiftList.length !== 0,
        },
        {
            Header: '',
            accessor: 'actions',
        }
    ]

    const [tablePage, setTablePage] = useState(0)
    const tablePageNew = React.useRef()
    if (shiftListCurPage !== tablePageNew.current) {
        tablePageNew.current = shiftListCurPage;
    }
    const setTablePageFn = (page) => {
        setTablePage(page)
        props.shiftScheduleListPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetShiftListClicked) {
            dispatch(actions.resetSearchOrResetInventory())
        } else {
            if (!DateGenerate && !props.isShiftList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : shiftListCurPage + 1;
                const inventoryItemsList_no_of_rows = state.pageSize;
                if (No_of_rows != inventoryItemsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                let isFuture = SearchValue.status && SearchValue.status === 'upcoming' ? true : null;
                let isCurrent = SearchValue.status && SearchValue.status === 'today' ? true : null;
                let isPast = SearchValue.status && SearchValue.status === 'past' ? true : null;
                let exception = SearchValue.exception ? SearchValue.exception : null;
                let newDate = new Date()
                let currentDate = tzUTCDateConverterTool_V2(newDate, 'to');
                let userId = props.match?.params?.id ? props.match?.params?.id : props.user?.id

                props.getShift({ page, limit, order, orderBy, isCurrent, isFuture, isPast, currentDate, userId, exception }, values)
            }
        }
    }

    return (
        SearchOrResetShiftListClicked && props.isShiftList ?
            <div  >
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
            :
            <>
            <ReactTable
                data={data}
                loading={props.isShiftList}
                minRows={0}
                defaultPageSize={No_of_rows}
                pages={No_of_pages}
                gotoPage={shiftListCurPage}
                page={shiftListCurPage}
                onPageChange={(page) => { setTablePageFn(page) }}
                // onPageSizeChange={props.onUserPageSizeChange}
                manual
                onFetchData={fetchData}
                showPaginationTop={true}
                showPaginationBottom={false}
                showPagination={props.shiftList.length !== 0}
                columns={columns}
                // style={{marginTop: '10px'}}
                NoDataComponent={() => CustomNoDataComponent(props.isShiftList, 'No Shift Schedule List Found')}
                LoadingComponent={() =>
                    isShiftList ?
                        <div style={{
                            display: "block",
                            position: "absolute",
                            left: 0,
                            height: '100%',
                            right: 0,
                            background: "rgba(255,255,255,0.8)",
                            transition: "all .3s ease",
                            top: 0,
                            bottom: 0,
                            textAlign: "center"
                        }}
                            className="loader_btn_block">
                            <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                        </div> : null}
                getTheadThProps={(state, rowInfo, column, instance) => {
                    // console.log(column);
                    return {
                        style: {
                            borderTop: 'none',
                            borderBottom: 'none',
                            borderRight: 'none',
                            textAlign: 'center',
                            color: '#566975',
                            fontSize: '14px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            float: 'left',
                        }
                    }
                }
                }
                getTrProps={(state, rowInfo, column, instance) => {debugger
                    // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                    console.log('rowInfo', rowInfo);
                    return {
                        className: rowInfo.original.exception?.props?.children === 'Updated' ? 'react-table-tr-element-close-status  react-table-tr-element-ticket ' : rowInfo.original.exception?.props?.children === 'Pending' ? 'react-table-tr-element-open-status  react-table-tr-element-ticket' : 'react-table-tr-element-open-status react-table-tr-element-ticket',
                        style: {
                            border: '1px solid #c7cdd1',
                            transition: '.3s ease-in-out',
                            width: '100%',
                            // borderRadius: '4px',
                            // height: '80px',
                            marginTop: '5px',
                            float: 'left'
                        }
                    }

                }}
                getTdProps={(state, rowInfo, column, instance) => {

                    if (column.id == 'image') {
                        return {
                            style: {
                                marginTop: '0px'
                            }
                        }
                    } else {
                        return {
                            className: 'react-td-element',
                        }
                        //  {
                        //   style: {
                        //     borderTop: 'none',
                        //     verticalAlign: 'middle',
                        //     fontSize: '14px',
                        //     color: '#2b3034',
                        //     marginTop: '10px !important',
                        //     textAlign: 'center',
                        //   }
                        // }
                    }
                }
                }
                // getPaginationProps={() => {
                //     return {
                //         style: {
                //             marginTop: '50px',
                //             border: 'none',
                //             boxShadow: 'none'
                //         }
                //     }
                // }
                // }
                getProps={() => {
                    return {
                        style: {
                            border: 'none'
                        }
                    }
                }}
            />

            {props.isTimeTrackerModalOpen ? (
                <ShiftCancelRequestForm {...props} shiftId={passShiftId} />
            ) : null}
            </>
    )
}

export default ShiftTable;