import React from 'react'
import { Input } from '../../../UI/InputElement/InputElement';
import { status } from '../../../../../utility/constants/constants';
import { exceptionTitleFormat } from '../../../../../utility/utility';


export default function ExceptionComponent(props) {debugger


    const { isSuperAdmin, formStates, user} = props

    const getJobName = (job) =>{
       const updateJob = job && job.split('of')
       if(updateJob.length){
        return `${updateJob[1]} - `
       }else{
        return null
       }
    }

    const checkJobOwner = (accounts) => {
     const checkJobOwner = accounts.some((item) => item.owner_user_id == user?.id)
     console.log('checkJobOwner', checkJobOwner);
     
     return checkJobOwner
    }

    return (
        <>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', }}>
                <div style={{ flex: 1.2 }}>
                    <h5>Exception Type</h5>
                    <h5 className="text-muted mt-2 mb-1" style={{ fontSize: '15px' }}>{props.exceptions?.exception_type === 'jobSwitch' ? getJobName(props.exceptions?.reason) : null} {props.exceptions && exceptionTitleFormat(props.exceptions?.exception_type)}</h5>
                </div>
            

                <div style={{ flex: 1.5 }}>
                    <h5>Reason</h5>
                    {isSuperAdmin || user?.id == props.singleShiftScheduleData?.createdBy?.id || checkJobOwner(props.singleShiftScheduleData && props.singleShiftScheduleData.accounts ? props.singleShiftScheduleData.accounts : []) ? (
                        <div className="form-group">
                        <Input instanceId="job_select" name={`shiftDetailJobs.exceptions.[${props?.index}].status`} type="inputSelect" options={status} />
                    </div>
                    )
                        : (
                        <div>{formStates?.shiftDetailJobs && formStates?.shiftDetailJobs?.exceptions && formStates?.shiftDetailJobs?.exceptions[props?.index]?.status?.label ? formStates?.shiftDetailJobs?.exceptions[props?.index]?.status?.label : 'pending'}</div>
                            
                        )
                    }

                </div>
                {
                    formStates?.shiftDetailJobs?.exceptions[props?.index]?.status?.value === 'other' ? (
                        <div style={{ marginLeft: `${isSuperAdmin || user?.id == props.singleShiftScheduleData?.createdBy?.id || checkJobOwner(props.singleShiftScheduleData && props.singleShiftScheduleData.accounts ? props.singleShiftScheduleData.accounts : []) ? '25px' : '-100px'}`, flex: 2 }}>
                            <h5 >Comments</h5>
                            {isSuperAdmin || user?.id == props.singleShiftScheduleData?.createdBy?.id || checkJobOwner(props.singleShiftScheduleData && props.singleShiftScheduleData.accounts ? props.singleShiftScheduleData.accounts : []) ? (
                                <div className="form-group">
                                <Input name={`shiftDetailJobs.exceptions.[${props?.index}].description`} className="form-control input-modifier" type="text" />
                            </div>
                            )
                                : (
                                <div>{formStates?.shiftDetailJobs && formStates?.shiftDetailJobs?.exceptions && formStates?.shiftDetailJobs?.exceptions[props?.index]?.description ? formStates?.shiftDetailJobs?.exceptions[props?.index]?.description : 'pending'}</div>
                                )
                            }
                        </div>

                    ) : <div style={{ marginLeft: `${isSuperAdmin || user?.id == props.singleShiftScheduleData?.createdBy?.id || checkJobOwner(props.singleShiftScheduleData && props.singleShiftScheduleData.accounts ? props.singleShiftScheduleData.accounts : []) ? '25px' : '-100px'}`, flex: 2 }}></div>
                }


            </div>

            {props?.index === props?.array.length - 1 ? null : <br />}
        </>
    )
}
