import React, { useState, useEffect } from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import "react-datepicker/dist/react-datepicker.css";
import { safetyGoodOrBad, safetyYesOrNo } from '../../../../utility/constants/constants';
import { Input } from '../../UI/InputElement/InputElement';
import { golfCartSafetyFormValidator as validate } from '../../../../utility/validator/validator';
import { connect, useDispatch } from 'react-redux';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import * as Scroll from 'react-scroll';

var scroller = Scroll.scroller;

const getAccountsDropDown = (props) => {

    return props.usersListBySelectedRole && props.usersListBySelectedRole.map(account => {
        return {
            value: account.id,
            label: `${account.first_name} ${account.last_name}`,
            email: account.email
        }
    })
}

const getUnitsDropDown = (props) => {

    return props.units && props.units.map(unit => {
        return {
            id: unit.about,
            name: unit.about,
        }
    })
}

let GolfCartFormComponent = (props) => {

    const dispatch = useDispatch()

    const base64Clicked = (i, urlLength) => {
        setIsImageGallery(true);
        setStartIndex((urlLength + i));
    }

    const photoUrlClicked = (i) => {

        setIsImageGallery(true);
        setStartIndex(i);
    }

    const [accounts, setAccounts] = useState(getAccountsDropDown(props));

    useEffect(() => {
        setAccounts(getAccountsDropDown(props))
    }, [props.usersListBySelectedRole])


    const noAccountOptionsMessage = (a, b) => {
        return 'Employee not found';
    }

    //units

    const [units, setUnits] = useState(getUnitsDropDown(props));

    useEffect(() => {
        setUnits(getUnitsDropDown(props))
    }, [props.units])


    const noUnitsOptionsMessage = (a, b) => {
        return 'Units not found';
    }

    function preloader() {
        return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
    }

    const [isImageGallery, setIsImageGallery] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const { handleSubmit, pristine, reset, submitting, error, change } = props;

    const clearDescriptionField = (event, fieldName) => {
        if (event.target.value == 'false') {
            dispatch(change(`data[${fieldName}]`, ''))
            // dispatch(initialize('golf_cart_inspection'))
        }
    }

    const seatOnChangeHandler = (event, filedValue, fieldName) => {
        if (event.target.value == 'true' && filedValue == 'true') {
            dispatch(change(`data[${fieldName}]`, ''))
            // dispatch(initialize('golf_cart_inspection'))
        }
    }

    return (
        <>
            {
                isImageGallery && <ModalImageGallery
                    isImageGallery={isImageGallery}
                    setIsImageGallery={setIsImageGallery}
                    startIndex={startIndex}
                    base64={props.portfolioImages}
                    imageUrls={props.savedPortfolioURls}
                />
            }
            <main className="app-content wraper_content user_newQuote">
                <div className="inspection_content_sec appcontent_Inner">
                    <div className="container-fluid">
                        <div className="pct_form ticket_form">
                            <h2 className="md_title" data-test="create_ticket_header">{'Golf Cart Inspection Report'}</h2>
                            <div className="addQuote_form" >
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Date <sup className="cst_sup_txt text_danger">*</sup></label>

                                            <div className="input-group cst_input_group">
                                                <Input name="data[date]"
                                                    dataTest="ticket_time_picker"
                                                    preventReadOnly={true}
                                                    dateFormat="MM-dd-yyyy" id="from_date" datePickerType={"react-datepicker"} type="date" className="form-control input-modifier test1" />
                                                <div className="input-group-append cursor-pointer" style={{ float: "right", position: "absolute", right: 0, height: "45px", top: 0 }} onClick={e => document.querySelector(".test1").click()}>
                                                    <span className="input-group-text" id="basic-addon2"><i className="fa fa-calendar" alt="Calendar Icon" ></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label name="data[employee_id]" className="form_title">Employee Name <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input instanceId="job_select" name="data[employee_id]" noOptionsMessage={noAccountOptionsMessage} type="inputSelect" options={accounts} />
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Employee Email <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <input
                                                    dataTest="first_name_data"
                                                    disabled={true}
                                                    value={props.formStates?.data?.employee_id?.email}
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[employee_email]"
                                                    id='golfCartEmail'
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Unit Number <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[unit_number]" className="custom-select input-modifier" type="select" options={units} />
                                        </div>

                                    </div>


                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Hours of Operations/Mileage <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[hours_of_operations_mileage]"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Brake Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[brake_lights]" className="custom-select input-modifier" onChange={(value) => clearDescriptionField(value, 'description_brake_lights')} type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.brake_lights == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className="col-md-6">
                                            </div>
                                            <div className={`form-group col-md-6`}>
                                                <label className="form_title">Describe the repair needed for the Break Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="data[description_brake_lights]" className="form-control textarea_modifier" rows="3" />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Turn Signals <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[turn_signals]" onChange={(value) => clearDescriptionField(value, 'description_turn_signals')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Headlight <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[headlight]" onChange={(value) => clearDescriptionField(value, 'description_headlight')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.turn_signals == 'false' || props.formStates?.data?.headlight == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.turn_signals == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Turn Signals <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_turn_signals]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.headlight == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Headlight <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_headlight]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Extension Cord Quality <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[extension_cord_quality]" className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tail Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tail_lights]" onChange={(value) => clearDescriptionField(value, 'description_tail_lights')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.tail_lights == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className="col-md-6">
                                            </div>
                                            <div className={`form-group col-md-6`}>
                                                <label className="form_title">Describe the repair needed for the Tail Lights <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="data[description_tail_lights]" className="form-control textarea_modifier" rows="3" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Front Right PSI <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[front_right_psi]"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Front Left PSI <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[front_left_psi]"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Rear Right PSI <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[rear_right_psi]"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form_title">
                                                    Rear Left PSI <sup className="cst_sup_txt text_danger">*</sup>
                                                </label>
                                                <Input
                                                    dataTest="first_name_data"
                                                    type="text"
                                                    className="form-control input-modifier"
                                                    placeholder=""
                                                    name="data[rear_left_psi]"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Tools/ PSI Gage <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[tools_psi_gage]" className="custom-select input-modifier" type="select" options={safetyYesOrNo} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Charger Plug <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[charger_plug]" onChange={(value) => clearDescriptionField(value, 'description_charger_plug')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />

                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.charger_plug == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className="col-md-6">
                                            </div>
                                            <div className={`form-group col-md-6`}>
                                                <label className="form_title">Describe the repair needed for the Charger Plug <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="data[description_charger_plug]" className="form-control textarea_modifier" rows="3" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Seat Condition <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[seat_condition]" onChange={(value) => seatOnChangeHandler(value, props.formStates?.data?.seat_belt_conditions, 'description_seat_condition')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Seat Belt Conditions <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[seat_belt_conditions]" onChange={(value) => seatOnChangeHandler(value, props.formStates?.data?.seat_condition, 'description_seat_condition')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.seat_condition == 'false' || props.formStates?.data?.seat_belt_conditions == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-12`}>
                                                <label className="form_title">Describe the repair needed for the Seat <sup className="cst_sup_txt text_danger">*</sup></label>
                                                <Input type="textarea" name="data[description_seat_condition]" className="form-control textarea_modifier" rows="3" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Mirrors <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[mirrors]" onChange={(value) => clearDescriptionField(value, 'description_mirrors')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Backup Camera <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[backup_camera]" onChange={(value) => clearDescriptionField(value, 'description_backup_camera')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.mirrors == 'false' || props.formStates?.data?.backup_camera == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.mirrors == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Mirrors <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_mirrors]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.backup_camera == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Backup Camera <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_backup_camera]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Brakes <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[brakes]" onChange={(value) => clearDescriptionField(value, 'description_brakes')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="form_title">Exterior Body <sup className="cst_sup_txt text_danger">*</sup></label>
                                            <Input dataTest="ticket_priority" name="data[exterior_body]" onChange={(value) => clearDescriptionField(value, 'description_exterior_body')} className="custom-select input-modifier" type="select" options={safetyGoodOrBad} />
                                        </div>
                                    </div>

                                    <div className={props.formStates?.data?.brakes == 'false' || props.formStates?.data?.exterior_body == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                        <div className="row">
                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.brakes == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Brakes <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_brakes]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>

                                            <div className={`form-group col-md-6`}>
                                                <div className={props.formStates?.data?.exterior_body == 'false' ? `showSafetyField` : 'hideSafetyField'}>
                                                    <label className="form_title">Describe the repair needed for the Exterior Body <sup className="cst_sup_txt text_danger">*</sup></label>
                                                    <Input type="textarea" name="data[description_exterior_body]" className="form-control textarea_modifier" rows="3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <div className="checkbox_block radius_checkbox">
                                                <div className="custom-control custom-checkbox custom-control-inline mt-3">
                                                    <Input
                                                        type="checkbox"
                                                        name="data[email_preference]"
                                                        className="custom-control-input"
                                                        id="customCheck2"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        for="customCheck2"
                                                    >
                                                        Send me a copy of my responses
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    {props.formStates?.data?.email_preference ? <div className="row">
                                        <div className="form-group col-md-6">
                                            <label for="emailaddress" className="form_title">
                                                Email <sup className="cst_sup_txt text_danger">*</sup>
                                            </label>
                                            <Input
                                                dataTest="email_data"
                                                type="text"
                                                name="data[emailaddress]"
                                                className="form-control input-modifier"
                                                placeholder=""
                                            />
                                        </div>
                                    </div> : null} */}


                                    <div className="file_attachment">
                                        <Input type="file" name="attachments" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                        <a href="javascript:void(0)" onClick={props.choosePhotoClicked} className="file_title"><img src="/images/icons/attachment_icon.png" alt="Icon" /> Attachments</a>
                                        <p className="size_dec">Please provide 4 images of the vehicle. Front, Back, Left and Right sides.</p>

                                        <div class="upload_file">
                                            <div class="row">
                                                {props.savedPortfolioURls.map((url, i) => {
                                                    return (
                                                        <div class="col-2 col-sm-2 cst_gutter" key={i}>
                                                            <div class="file_view" onClick={() => photoUrlClicked(i)}>
                                                                <ImageLoader
                                                                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                    src={url.photo_urls.small}
                                                                    wrapper={React.createFactory('div')}
                                                                    preloader={preloader}>
                                                                    Image load failed!
                                                                </ImageLoader>
                                                            </div>
                                                            <div class="cancel_icon">
                                                                <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, i)} alt="Image" />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {props.portfolioImages.map((url, i) => {
                                                    return (
                                                        <div class="col-2 col-sm-2 cst_gutter" key={i} >
                                                            <div class="file_view">
                                                                <img src={url} alt="Image" onClick={() => base64Clicked(i, props.savedPortfolioURls.length)} />
                                                                <div class="cancel_icon">
                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group btn_block mb-0">
                                        <button data-test="submit_button" type="submit" className={props.isLoading ? "btn cst_btn btn_danger btn-wait" : "btn cst_btn btn_danger"}>{props.isLoading ? "" : "Submit"}</button>
                                        {/* <button data-test="cancel_button" type="button" onClick={() => props.onCancelClicked()} className="btn cst_btn btn-outline-secondary  cancel_btnnew">Cancel</button> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </main>
        </>
    )

    // }
}

const mapStateToProps = (state) => {
    let data = {};
    data = {
        date: "",
        employee_id: "",
        unit_number: "",
        hours_of_operations_mileage: "",
        brake_lights: "",
        turn_signals: "",
        headlight: "",
        extension_cord_quality: "",
        tail_lights: "",
        front_right_psi: "",
        front_left_psi: "",
        rear_right_psi: "",
        rear_left_psi: "",
        charger_plug: "",
        seat_condition: "",
        seat_belt_conditions: "",
        mirrors: "",
        backup_camera: "",
        brakes: "",
        exterior_body: "",
        tools_psi_gage: "",
    }
    return { initialValues: { data }, formStates: getFormValues('golf_cart_inspection')(state) }

}

GolfCartFormComponent = reduxForm({
    form: 'golf_cart_inspection',
    validate,
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
})(GolfCartFormComponent);

GolfCartFormComponent = connect(mapStateToProps)(GolfCartFormComponent)

export const scrollToFirstError = (errors) => {

    const keys = [
        "date",
        "employee_id",
        "unit_number",
        "hours_of_operations_mileage",
        "brake_lights",
        "turn_signals",
        "headlight",
        "extension_cord_quality",
        "tail_lights",
        "front_right_psi",
        "front_left_psi",
        "rear_right_psi",
        "rear_left_psi",
        "charger_plug",
        "seat_condition",
        "seat_belt_conditions",
        "mirrors",
        "backup_camera",
        "brakes",
        "exterior_body",
        "tools_psi_gage",
        "emailaddress",
        "description_brake_lights",
        "description_turn_signals",
        "description_headlight",
        "description_tail_lights",
        "description_charger_plug",
        "description_seat_condition",
        "description_mirrors",
        "description_backup_camera",
        "description_brakes",
        "description_exterior_body"
    ];

    let scroolToError = "";

    keys.forEach(key => {
        if (errors.data[key]) {
            scroolToError = `data[${key}]`;
        }
    });
    //  
    scroller.scrollTo(`${scroolToError}`, {
        duration: 500,
        delay: 1,
        isDynamic: true,
        smooth: true,
        offset: -100,
    })

}

export default GolfCartFormComponent;