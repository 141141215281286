import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../redux/actions/index';
import { animateScroll as scroll } from 'react-scroll'
import PreviewShifSchedule from '../../../../component/TimeTrackerModuleComponent/ShiftScheduleComponent/PreviewShifScheduleComponent/PreviewShifSchedule';
import storage from '../../../../../utility/storage';
import { userRoles } from '../../../../../utility/constants/constants';

const cloneDeep = require('clone-deep');

class PreviewShiftScheduleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewFileQuery: {
                previewOpen: false,
                previewTitle: '',
                previewImage: '',
            },
            isLoad: false,
        }

    }

    componentDidMount() {

        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.props.getSingleShifSchedule(this.props.match.params.id);
            storage.set('shiftScheduleDetailId', this.props.match.params.id)
        }
        this.setState({isLoad: true});
        setTimeout(() => {
            this.setState({isLoad: false});
        }, 1000);
        scroll.scrollToTop();

    }


    updateShift = (values) => {
        const isInspectorEligible = storage.get('isInspectorEligible')
        const user = storage.get('user')
      const exception = values.shiftDetailJobs.exceptions.map((item)=>{
        if(item.description === ''){
            return {
                id:item.id ,
                status:item.status.value
            }
        }else {
            return{
                id:item.id , 
                description:item.description, 
                status:item.status.value
            }
        }
      })

      const account_id=this.props.match && this.props.match.params && this.props.match.params.id
      const body=values.shiftDetailJobs.jobs.map((item)=>({id:item.id ,customer_pay:item.customer_pay,account_rate_id:item.rate.id}))

      if(isInspectorEligible && user.role == userRoles.INSPECTOR){
        this.props.updateSingleShiftScheduleException({data : exception})
      }else{
        this.props.updateSingleShiftSchedule({data:{shiftDetailJobs:body}},account_id, {data : exception})
      }


    }

    onCancelClicked = () => {
        this.props.history.goBack();
    }
    render() {
        return (
            <PreviewShifSchedule
                {...this.props}
                isLoad={this.state.isLoad}
                onSubmit={this.updateShift}
                onCancelClicked={this.onCancelClicked}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        is_facial:state.authReducer.is_facial,
        isLoading: state.TimeTrackerModReducer.isLoading,
        singleShiftScheduleData: state.TimeTrackerModReducer.singleShiftScheduleData,
        isLoadingSubmit:state.TimeTrackerModReducer.isLoadingSubmit,
        isJobOwner:state.adminOrSuperAdminReducer.isJobOwner,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSingleShifSchedule: (id) => dispatch(actions.getSingleShifSchedule(id)),
        updateSingleShiftSchedule:(body,account_id, exception)=> dispatch(actions.updateSingleShiftSchedule(body,account_id, exception)),
        updateSingleShiftScheduleException: (body) => dispatch(actions.updateSingleShiftScheduleException(body)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewShiftScheduleContainer); 