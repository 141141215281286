
export const safetyActionTypes = {

    SAFETY_CLICKED: "SAFETY_CLICKED",

    SAFETY_PRESIGNED_URL: 'SAFETY_PRESIGNED_URL',
    SAFETY_PRESIGNED_URL_PENDING: 'SAFETY_PRESIGNED_URL_PENDING',
    SAFETY_PRESIGNED_URL_FULFILLED: 'SAFETY_PRESIGNED_URL_FULFILLED',

    UPLOAD_IMAGE_TO_S3: 'UPLOAD_IMAGE_TO_S3',
    UPLOAD_IMAGE_TO_S3_PENDING: 'UPLOAD_IMAGE_TO_S3_PENDING',
    UPLOAD_IMAGE_TO_S3_FULFILLED: 'UPLOAD_IMAGE_TO_S3_FULFILLED',

    ADD_VEHICLE_SAFETY: 'ADD_VEHICLE_SAFETY',
    ADD_VEHICLE_SAFETY_PENDING: 'ADD_VEHICLE_SAFETY_PENDING',
    ADD_VEHICLE_SAFETY_FULFILLED: 'ADD_VEHICLE_SAFETY_FULFILLED',

    ADD_GOLF_CART_SAFETY: 'ADD_GOLF_CART_SAFETY',
    ADD_GOLF_CART_SAFETY_PENDING: 'ADD_GOLF_CART_SAFETY_PENDING',
    ADD_GOLF_CART_SAFETY_FULFILLED: 'ADD_GOLF_CART_SAFETY_FULFILLED',


}


