import { JoBAssociateTypes } from "../../actions/JobAssociationActions/JobAssociationTypes";
import storage from "../../../utility/storage";

const token = storage.get("token", null);
const refresh_token = storage.get("refresh_token", null);
const user = storage.get("user", null);
const is_facial = storage.get("is_facial", null);

export const initialState = {
  tickets: [],
  isLoading: false,
  isLoadingForm: false,
  ticket_page: 1,
  ticket_no_of_rows: 10,
  searchTicketValues: {},
  totalTickets: null,
  ticketCurPage: 0,
  ticket_page: 1,
  singleJobAssociation: {},
  accounts: null,
};

const updateObject = (oldState, updatedProps) => {
  return {
    ...oldState,
    ...updatedProps,
  };
};

export const JobAssociateReducer = (state = initialState, action) => {
  switch (action.type) {
    case JoBAssociateTypes.GET_JOBASSOCIATES_LIST_PENDING:
      return updateObject(state, { isLoading: true });
    case JoBAssociateTypes.GET_JOBASSOCIATES_LIST_FULFILLED:
      return updateObject(state, { isLoading: false, ...action.payload });
    case JoBAssociateTypes.GET_JOBASSOCIATES_LIST_REJECTED:
      return updateObject(state, { isLoading: false });

    case JoBAssociateTypes.CREATE_JOBASSOCIATES_LIST_PENDING:
      return updateObject(state, { isLoadingForm: true });
    case JoBAssociateTypes.CREATE_JOBASSOCIATES_LIST_FULFILLED:
      return updateObject(state, { isLoadingForm: false });
    case JoBAssociateTypes.CREATE_JOBASSOCIATES_LIST_REJECTED:
      return updateObject(state, { isLoadingForm: false });

    case JoBAssociateTypes.JOB_ASSOCIATION_TASK_LIST_PAGE_CLICKED:
      return updateObject(state, { ticketCurPage: action.payload });

    case JoBAssociateTypes.GET_SINGLE_JOBASSOCIATES_PENDING:
      return updateObject(state, { isLoading: true });
    case JoBAssociateTypes.GET_SINGLE_JOBASSOCIATES_FULFILLED:
      return updateObject(state, { isLoading: false, ...action.payload });

    case JoBAssociateTypes.GET_JOBS_OF_ASSOCIATION_PENDING:
      return updateObject(state, { isLoading: true });
    case JoBAssociateTypes.GET_JOBS_OF_ASSOCIATION_FULFILLED:
      return updateObject(state, { isLoading: false, ...action.payload });

    // case JoBAssociateTypes.GET_ONE_JOBS_OF_ASSOCIATION_PENDING:
    //   return updateObject(state, { isLoading: true });
    // case JoBAssociateTypes.GET_ONE_JOBS_OF_ASSOCIATION_FULFILLED:
    //   return updateObject(state, { isLoading: false,singleJobAssociation:{...action.payload.data} });
    // case JoBAssociateTypes.GET_ONE_JOBS_OF_ASSOCIATION_REJECTED:
    //   return updateObject(state, { isLoading: false,singleJobAssociation:null });

    case JoBAssociateTypes.UPDATE_JOBASSOCIATES_LIST_PENDING:
      return updateObject(state, { isLoadingForm: true });
    case JoBAssociateTypes.UPDATE_JOBASSOCIATES_LIST_FULFILLED:
      return updateObject(state, { isLoadingForm: false });
    case JoBAssociateTypes.UPDATE_JOBASSOCIATES_LIST_REJECTED:
      return updateObject(state, { isLoadingForm: false });

      case JoBAssociateTypes.DELETE_JOBASSOCIATES_LIST_PENDING:
      return updateObject(state, { isLoadingForm: true });
    case JoBAssociateTypes.DELETE_JOBASSOCIATES_LIST_FULFILLED:
      let list  = [...state.tickets]
      const findIndex = list.findIndex((item) => item.id == action.payload.id)
      list.splice(findIndex, 1)
      return updateObject(state, { isLoadingForm: false, tickets : list });
    case JoBAssociateTypes.DELETE_JOBASSOCIATES_LIST_REJECTED:
      return updateObject(state, { isLoadingForm: false });
      case JoBAssociateTypes.CLEAR_JOB_ASSOCIATION_FORM:
      return updateObject(state, { singleJobAssociation: {} });

    default:
      return state;
  }
};
