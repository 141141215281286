import React, { useEffect, useRef, useState } from 'react';
import { Input } from '../../UI/InputElement/InputElement';
import { previewTicketValidator as validate } from '../../../../utility/validator/validator';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import ImageLoader from 'react-imageloader';
import ModalImageGallery from '../../ModalImageGallery/modalImageGallery';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { TicketStatus, userRoles, TimeZones } from '../../../../utility/constants/constants';
import { convertDate, convertAreaScheduleTime, convertDateTimezone } from '../../../../utility/utility'
import errorHandler from '../../../../utility/errorHandler/errorHandler';
import history from '../../../../router/history';
import '../../../../styles/custom.css';
import { convertDateTimezoneV1 } from '../../../../utility/TimezoneOperations/timezoneUtility';


var moment = require('moment');

function preloader() {
    return <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />;
}

const loadCommentImages = (props, attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, commentId, photoCommentId, editCommentObject) => {

    if (props.ticket.comments_attributes.length - 1 === cindex && props.isLoading && isEmpty(attachments_attributes)) {
        return props.portfolioImages.map((element, i) => (
            <div className="attachments__file_box" key={i}>
                <img style={{ width: '100%', height: '100%' }} src="/images/gif/giphy.gif" />
            </div>
        ));

    } else {
        if (!isEmpty(attachments_attributes)) {

            if (isCommentImageGallery && photoCommentId === commentId) {
                return <ModalImageGallery
                    isImageGallery={isCommentImageGallery}
                    setIsImageGallery={setIsCommentImageGallery}
                    startIndex={startIndex}
                    // base64={props.portfolioImages}
                    imageUrls={attachments_attributes}
                />
            } else {
                return (
                    attachments_attributes.map((url, i) => (
                        <>
                            <div className="attachments__file_box">
                                <div key={i} onClick={() => commentPhotoUrlClicked(i, commentId)}>
                                    <ImageLoader

                                        style={{ width: '100%', height: '82px', cursor: 'pointer' }}
                                        src={url.photo_urls.small}
                                        wrapper={React.createFactory('div')}
                                        preloader={preloader}>
                                        Image load failed!
            </ImageLoader>
                                </div>
                                {editCommentObject ?
                                    <div class="cancel_icon">
                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeUrls(url, cindex)} alt="Image" />
                                    </div> : null
                                }
                            </div >
                        </>
                    )
                    )
                )
            }
        }
    }

}

let getLastUpdatedDaysCount = (updatedAt) => {
    let CurrentDate = moment(new Date()).startOf('day');
    let UpdatedDate = moment(updatedAt).startOf('day');
    let DifferenceRange = CurrentDate.diff(UpdatedDate, 'days');
    if (DifferenceRange === 0) {
        return moment(updatedAt).format('hh:mm A');
    } else if (DifferenceRange === 1) {
        return DifferenceRange + ' day ago'
    } else {
        return DifferenceRange + ' days ago'
    }
}

let PreviewTicket = (props) => {
    const [editCommentObject, setEditCommentObject] = useState(null);
    const [isImageGallery, setIsImageGallery] = useState(false);
    const [isCommentImageGallery, setIsCommentImageGallery] = useState(false);
    const [commentId, setCommentId] = useState(null);
    const [startIndex, setStartIndex] = useState(0);
    const [currentTicket, setCurrentTicket] = useState(0);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [showAttachment, setShowAttachment] = useState(false);
    const [cancelComment, setCancelComment] = useState('')
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.ticketIndex) {
            props.ticketIndex.map((ins, i) => {
                if(props.match.params.ticketId === ins) {
                    setCurrentTicket(i + 1)
                }
            })
        }
    }, [props])

    useEffect(() => {
        if(props.formStates && props.formStates.ticket) {
            if((props.formStates.ticket.comments_attributes.length !== props.ticket.comments_attributes.length) && props.formStates.ticket.comments_attributes[props.formStates.ticket.comments_attributes.length -1].description !== undefined) {
                setShowAttachment(true);
            } else {
                setShowAttachment(false);
            }
        }
    }, [props.formStates])


    const editCommentClicked = (comment, index) => {
        setEditCommentObject(comment);
        dispatch(change('previewTicketForm', `ticket[comments_attributes][${index}][description]`, comment.description))
    }

    if (isEmpty(props.ticket)) {
        return (<></>)
    } else {


        const { handleSubmit, pristine, reset, submitting, error } = props;

        const photoUrlClicked = (urls, i) => {
            setIsImageGallery(true);
            setStartIndex(i);
            setPhotoUrls(urls);
        }


        const commentPhotoUrlClicked = (i, commentId) => {
            setIsCommentImageGallery(true);
            setCommentId(commentId);
            setStartIndex(i);
        }

        if (isImageGallery) {
            return <ModalImageGallery
                isImageGallery={isImageGallery}
                setIsImageGallery={setIsImageGallery}
                startIndex={startIndex}
                // base64={props.portfolioImages}
                imageUrls={photoUrls}
            />
        }
        else {

            const cancelEditClicked = () => {
                props.cancelEditClicked();
                setEditCommentObject(null);
            }

            if (props.user.role === 'client' && props.ticket.private) {
                errorHandler('Invalid Request')
                history.push(`/tickets`);
            }

            // const cancelCommentHandler = () =>{
            //     let ticket = {
            //         id: props.match.params.ticketId,
            //         platform: "web",
            //         status: "canceled",
            //         cancelReason : cancelComment
            //     }
            //     props.cancelTicket(ticket)
            //     props.history.goBack()
            //     setCancelComment('')

            // }
            return (  
            
                (props.user.role === 'client' && props.ticket.private) ? 
                null : 
                <>
                <main className="app-content wraper_content ticket_user">
                    <div className="appcontent_Inner">
                        <div className="tickets_user_profile_body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 col-xl-9 order-2 order-xl-1">
                                        <div className="tickets_user_activity">
                                            <div className="tickets_top_header">
                                                <div className='d-flex viewIns_btn align-items-start justify-content-between'>
                                                <div className="preview_comment">
                                                   <h4>{props.ticket.subject}</h4>
                                                </div>
                                                {props.ticketIndex && props.ticketIndex.length > 0 ? <div className='pn-flex'>
                                                <button className="btn cst_btn custom_hover" disabled={currentTicket === 1} onClick={() => props.previousClicked()}>Previous</button>
                                                <h4 style={{margin: '5px'}}>{currentTicket}/{props.ticketIndex ? props.ticketIndex.length : 0}</h4>
                                                <button className="btn cst_btn custom_hover" disabled={currentTicket === props.ticketIndex.length } onClick={() => props.nextClicked()}>Next</button>
                                                </div> : null}
                                                </div>
                                                <div className="header_icn_block">
                                                    {/* <a href="#" className="mr-3"><img src="/images/icons/icn_file_export.png" alt="Export" /></a>
                                                <a href="#"><img src="/images/icons/icn_edit_md.png" alt="Edit" /></a> */}
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="user_activity_conent">
                                                <div className="misc_section">
                                                    <h6 style={{lineBreak: 'anywhere'}}>{props.ticket.message}</h6>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="attachment_sec">
                                                    <div className="attachment_title">
                                                        <img className="attachment_icn" src="/images/icons/icn_attch.png" alt="" />
                                                        <h5 className="tickets_user_title1x">Attachments<span className="attachment_size"></span></h5>
                                                    </div>
                                                    {props.ticket.pegassure_feedback ? 
                                                        <div className="attachments_files">
                                                        {props.ticket.pegassure_feedback.attachments.map((t, i) => {

                                                            return (
                                                                <div className="attachments__file_box" onClick={() => photoUrlClicked(props.ticket.pegassure_feedback.attachments, i)}>
                                                                    <ImageLoader
                                                                        style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                        src={t.photo_urls.small}
                                                                        wrapper={React.createFactory('div')}
                                                                        preloader={preloader}>
                                                                        Image load failed!
                                                        </ImageLoader>
                                                                    {/* <img src={t.photo_urls.small} alt="Image" /> */}
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    : 
                                                    <div className="attachments_files">
                                                        {props.ticket.attachments_attributes.map((t, i) => {

                                                            return (
                                                                <div className="attachments__file_box" onClick={() => photoUrlClicked(props.ticket.attachments_attributes, i)}>
                                                                    <ImageLoader
                                                                        style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                                                                        src={t.photo_urls.small}
                                                                        wrapper={React.createFactory('div')}
                                                                        preloader={preloader}>
                                                                        Image load failed!
                                                        </ImageLoader>
                                                                    {/* <img src={t.photo_urls.small} alt="Image" /> */}
                                                                </div>
                                                            )
                                                        })}

                                                    </div>}
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="tickets_users_comments">
                                                <h4 className="tickets_user_title2x">{props.ticket.status === 'canceled' ? 'Reason for Cancellation' : props.ticket.comments_attributes.length === 1 ? `${props.ticket.comments_attributes.length} Comment`
                                                        : props.ticket.comments_attributes.length > 1 ? `${props.ticket.comments_attributes.length} Comments` :
                                                        `Comments`}</h4>
                                                    <div className="sent_comments border_bottom">

                                                        {props.ticket.comments_attributes.map((c, cindex) => {

                                                            if (editCommentObject && editCommentObject.id === c.id) {

                                                                return (<form onSubmit={handleSubmit} className="tickets_view_form">
                                                                    <div className="user_comment_area">

                                                                        <div className="form-group">
                                                                            <Input dataTest="preview_comment_box" type="textarea" name={`ticket[comments_attributes][${cindex}][description]`} className="form-control" rows="2" id="comment" />
                                                                        </div>

                                                                        <div className="comment_file_attachment">

                                                                            <div className="upload_file_block text-righ comment_file_attachmentt" >
                                                                                <div id="file-upload-wrapper" onClick={props.choosePhotoClicked} className="file-upload-wrapper" data-text="Up to 10 attachments (10MB each).">
                                                                                    <Input type="file" dataTest="attached_file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                                                    {/* <input name="file-upload-field" type="file" className="file-upload-field" id="file-upload-field" value="" /> */}
                                                                                </div>

                                                                                <div className="attachment_sec">
                                                                                    <div className="attachments_files">

                                                                                        {props.portfolioImages && props.portfolioImages.map((url, i) => (

                                                                                            <div className="attachments__file_box" key={i}>
                                                                                                <img className="attachment_icn" src={url} alt="" />
                                                                                                <div className="cancel_icon">
                                                                                                    <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                        }
                                                                                        {loadCommentImages(props, c.attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, c.id, commentId, true)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="comment_buttons" style={{ marginBottom: '10px' }}>
                                                                        <button data-test="update_comment" onClick={handleSubmit(values =>
                                                                            props.addTicketComment({ ...values }, setEditCommentObject, editCommentObject.id))}
                                                                            disabled={props.isCommentLoading || props.isCommentAndResolveLoading || ((!props.portfolioImages || props.portfolioImages.length === 0) ? pristine : false)} className={props.isCommentLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isCommentLoading ? "" : "UPDATE COMMENT"}</button>
                                                                        <button data-test="submit_button" onClick={handleSubmit(values =>
                                                                            props.addTicketCommentAndResolve({ ...values }, setEditCommentObject, editCommentObject.id))}
                                                                            type="submit" disabled={props.isCommentAndResolveLoading || props.isCommentLoading || ((!props.portfolioImages || props.portfolioImages.length === 0) ? pristine : false)} className={props.isCommentAndResolveLoading ? "btn cst_btn btn_success btn-wait mr-2" : "btn cst_btn btn_success mr-2"} >{props.isCommentAndResolveLoading ? "" : "COMMENT & RESOLVE"}</button>
                                                                        <button data-test="cancel_btn" onClick={() => cancelEditClicked()}
                                                                            type="button" className={"btn cst_btn btn-outline-secondary"} >{"CLOSE"}</button>
                                                                    </div>
                                                                </form>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="media">
                                                                        <div className="user_profile_icn mr-3">
                                                                            <img src={c.user && c.user.photo_urls && c.user.photo_urls.small ? c.user.photo_urls.small : '/customImages/user.png'} alt="Generic placeholder image" />
                                                                        </div>
                                                                        <div className="media-body user_comment_details">
                                                                            <h5 className="mt-0 sm-title">{c.user ? c.user.first_name : ''} <span className="comment_date">{getLastUpdatedDaysCount(c.created_at)}</span></h5>

                                                                            {props.ticket.status !== 'resolved' && props.ticket.status !== 'canceled' && c.user.id === props.user.id ?
                                                                                <a data-test="edit_description" href="javascript:void(0)" className="edit_comment" onClick={() => editCommentClicked(c, cindex)}>Edit</a>
                                                                                : null}
                                                                            <p data-test="description" style={{ wordWrap: "break-word" }}>{c.description}</p>
                                                                            <div className="attachment_sec">
                                                                                <div className="attachments_files">

                                                                                    {loadCommentImages(props, c.attachments_attributes, cindex, commentPhotoUrlClicked, isCommentImageGallery, setIsCommentImageGallery, startIndex, c.id, commentId)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                    {props.ticket.status === TicketStatus.resolved || props.ticket.status === TicketStatus.canceled || (editCommentObject && !isEmpty(editCommentObject)) ? null :
                                                        <div className="sent_comments">
                                                            <div className="media">
                                                                <div className="user_profile_icn mr-3">
                                                                    <img src={props.user.photo_urls ? props.user.photo_urls.small ? props.user.photo_urls.small : '/customImages/user.png' : '/customImages/user.png'} alt="Generic placeholder image" />
                                                                </div>

                                                                <form onSubmit={handleSubmit} className="tickets_view_form">
                                                                    <div className="tickets_user_bottomSec">
                                                                        <div className="user_comment_area">

                                                                            <div className="form-group">
                                                                                <Input dataTest="preview_comment_box" type="textarea"
                                                                                    name={`ticket[comments_attributes][${props.ticket.comments_attributes.length}][description]`} className="form-control" rows="2" id="comment" />
                                                                            </div>

                                                                            <div className="comment_file_attachment">

                                                                                {showAttachment ?  <div className="upload_file_block text-righ comment_file_attachmentt" >

                                                                                    <div id="file-upload-wrapper" onClick={props.choosePhotoClicked} className="file-upload-wrapper" data-text="Up to 10 attachments (10MB each).">
                                                                                        <Input type="file" dataTest="attached_file" name="attachment_paths" hidden={true} multiple={true} onSelect={props.onPortfolioFileChange} fileRef={props.reference} accept="image/*" />
                                                                                    </div>
                                                                                    <div className="attachment_sec">
                                                                                        <div className="attachments_files">

                                                                                            {props.portfolioImages && props.portfolioImages.map((url, i) => (

                                                                                                <div className="attachments__file_box" key={i}>
                                                                                                    <img className="attachment_icn" src={url} alt="" />
                                                                                                    <div className="cancel_icon">
                                                                                                        <img src="/images/icons/icn_delete_white.png" onClick={() => props.removeImage(url, i)} alt="Image" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div> : ''}

                                                                            </div>
                                                                        </div>
                                                                        <div className="people_notify_others">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12 col-lg-8">
                                                                                            {/* <div className="notify_others_div">
                                                                                    <p>Your comment will be emailed to:
                                                                                    <span><a href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="Natalia
                                                                                        Natalia
                                                                                        Natalia
                                                                                        Natalia
                                                                                        Natalia">5 people
                                                                                    </a>
                                                                                    <sub>|</sub><a href="javascript:void(0)" className="notify_others">Notify others</a></span>
                                                                               </p>

                                                                                    </div> */}
                                                                                        </div>

                                                                                        {/* <div className="col-sm-12 col-lg-4">
                                                                                        <div className="use_hashtag">
                                                                                            <p><i className="fas fa-lightbulb"></i> ProTip! Use <a href="#">hashtags</a> to change status.</p>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    </div>

                                                                                    <div className="comment_buttons">
                                                                                        <button data-test="add_comment_btn" onClick={handleSubmit(values =>
                                                                                            props.addTicketComment({ ...values }))}
                                                                                            disabled={props.isCommentLoading || props.isCommentAndResolveLoading || props.isCommentAndCancelLoading ||  pristine } className={props.isCommentLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isCommentLoading ? "" : "ADD COMMENT"}</button>
                                                                                        <button data-test="submit_button" onClick={handleSubmit(values =>
                                                                                            props.addTicketCommentAndResolve({ ...values }))}
                                                                                            type="submit" disabled={props.isCommentAndResolveLoading || props.isCommentLoading || props.isCommentAndCancelLoading || pristine } className={props.isCommentAndResolveLoading ? "btn cst_btn btn_success btn-wait" : "btn cst_btn btn_success mr-2"} >{props.isCommentAndResolveLoading ? "" : "COMMENT & RESOLVE"}</button>
                                                                                            {props.ticket.status === TicketStatus.canceled ? null : <button data-test="submit_button" onClick={handleSubmit(values =>
                                                                                            props.addTicketCommentAndCancel({ ...values }))}
                                                                                            type="submit" disabled={props.isCommentAndResolveLoading || props.isCommentLoading || props.isCommentAndCancelLoading || pristine } className={props.isCommentAndCancelLoading ? "btn cst_btn btn_dark btn-wait" : "btn cst_btn btn_dark mr-2"} >{props.isCommentAndCancelLoading ? "" : "COMMENT & CANCEL"}</button> }
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </form>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-xl-3 order-1 order-xl-2 border_user_profile">
                                        <div className="user_profile_details">
                                            <div className="user_profile_details_sec">
                                                <div className="user_profile_picture">
                                                    <img src={props.ticket.user && props.ticket.user.photo_urls.small ? props.ticket.user.photo_urls.small : "/customImages/user.png"} alt="" />
                                                </div>
                                                <div className="user_profile_title">
                                                    <h4>{props.ticket.user && props.ticket.user.first_name + ' ' + props.ticket.user && props.ticket.user.last_name}</h4>
                                                    <h6>{props.ticket.account.name}</h6>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="user_status mob_view">
                                                {props.ticket.status === TicketStatus.resolved ?
                                                        <a href="javascript:void(0)" className="btn btn_resolved cst_btn">Resolved</a>
                                                        : props.ticket.status === TicketStatus.pending ?
                                                            <a href="javascript:void(0)" className="btn btn_open cst_btn">Pending</a>
                                                            :props.ticket.status === TicketStatus.canceled ?
                                                            <a href="javascript:void(0)" className="btn btn_open cst_btn">Canceled</a>
                                                            :<a href="javascript:void(0)" className="btn btn_open cst_btn">Open</a>
                                                    }

                                                </div>
                                                <div className="user_status sm_d_none justify-content-center">
                                                {props.ticket.status === TicketStatus.resolved ?
                                                        <a href="javascript:void(0)" className="btn btn_resolved cst_btn">Resolved</a>
                                                        : props.ticket.status === TicketStatus.pending ?
                                                            <a href="javascript:void(0)" className="btn btn_open cst_btn">Pending</a>
                                                            :props.ticket.status === TicketStatus.canceled ?
                                                            <a href="javascript:void(0)" className="btn btn_open cst_btn">Canceled</a>
                                                            :<a href="javascript:void(0)" className="btn btn_open cst_btn">Open</a>
                                                    }

                                                    {/*  */}

                                                </div>
                                                
                                                    {props.ticket.due_on ? <div className="d-inline-block w-100 text-center m-auto pl-4">
                                                         <h6 className="d-inline-block duo_date_title">Due Date:
                                                           <span className="d-inline-block duo_date_text">{convertDateTimezoneV1(props.ticket.due_on, props.ticket.account.timezone)}</span>
                                                        </h6> 
                                                    </div> : null}
                                                 
                                                <div className="priority_id">
                                                    <div className="row">
                                                        <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-6">
                                                            <div className="priority_box">
                                                                <h6><b>Priority</b></h6>
                                                                <span>{props.ticket.priority}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-sm-8  col-md-4 col-lg-4 col-xl-6">
                                                            <div className="user_id_box">
                                                                <h6><b>ID</b></h6>
                                                                <span>{props.ticket.id}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="assign_location sm_d_none">
                                                <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6><b>Job</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6>{props.ticket.account.name ? props.ticket.account.name : ''}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        props.ticket?.floor ? <>
                                                        <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                            <h6><b>Floor</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                                <h6>
                                                                    <label className="user_location_box text-muted">{props.ticket?.floor?.name}</label>
                                                                </h6>
                                                        </div>
                                                    </div>
                                                        </>  : null
                                                    }
                                                    {props.ticket && props.ticket.additional_job ? (<div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6><b>Additional Job</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6>{props.ticket.additional_job ? props.ticket.additional_job : ''}</h6>
                                                            </div>
                                                        </div>
                                                    </div>) : null}
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6 ><b>Assigned to</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                                <h6 className="d-flex">{props.ticket.user && props.ticket.user.first_name} {props.ticket.user && props.ticket.user.last_name}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="user_assigned">
                                                        <div className="date_custom_dropdown assign_modal" role="menu">
                                                            <div className="modal_header">
                                                            <h6><b>Assign responsibility</b></h6>
                                                                <a href="#" className="assign_modal_close"><img src="images/icons/icn_cancel.png" alt="" /></a>
                                                            </div>
                                                            <div className="modal_select">
                                                                <div className="form-group">
                                                                    <select id="first-disabled" className="selectpicker custom_select" data-hide-disabled="true" data-live-search="true" title="No One">
                                                                        <option>Aaron Train</option>
                                                                        <option>Aminta Cuellar</option>
                                                                        <option>Aaron McDonals</option>
                                                                        <option>Abraham c3uves</option>
                                                                        <option>Adam Filmore</option>
                                                                        <option>Andrea DeVincenzo</option>
                                                                    </select>
                                                                </div>
                                                                <div className="assign_modal_btn">
                                                                    <button className="assign_btn">Assign</button>
                                                                    <button className="close_btn">Close</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="assign_location mob_view">
                                                <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6>Job</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                            <h6>{props.ticket.account.name ? props.ticket.account.name : ''}</h6>
                                                            </div>
                                                        </div>
                                                        {
                                                        props.ticket?.floor ? <>
                                                        <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Floor</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket?.floor?.name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        </>  : null
                                                    }
                                                        {props.ticket && props.ticket.additional_job ? (<><div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                                <h6>Additional Job</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="user_location_box">
                                                            <h6>{props.ticket.additional_job ? props.ticket.additional_job : ''}</h6>
                                                            </div>
                                                        </div></>) : null}
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                            <h6 >Assigned to</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="user_location_box">
                                                            <h6 className="d-flex">{props.ticket.user && props.ticket.user.first_name} {props.ticket.user && props.ticket.user.last_name}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="created_lastActivity">
                                                    {props.ticket && props.ticket.category && props.ticket.category.name ? <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                                <h6><b>Category</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket.category.name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                                {props.ticket.requestor_user ? <div className="created_name">
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Created By</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6 className="d-inline-block">
                                                                    <label className="created_date d-inline-block">{props.ticket.requestor_user.first_name}</label>
                                                                    <label className="created_date d-inline-block">{props.ticket.requestor_user.last_name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div> : null}

                                                    {
                                                        props.ticket.inspection_id ? 
                                                        <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Inspection Id</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket.inspection_id}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    null
                                                    }
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Created On</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{convertDate(props.ticket.created_at, props.ticket.account.timezone)}</label>
                                                                    <label className="created_time">{convertAreaScheduleTime(props.ticket.created_at, props.ticket.account.timezone)}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {props.ticket && props.ticket.updated_by && props.ticket.updated_on ?
                                                    <div>
                                                        <div className="created_name">
                                                            <div className="row">
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                                    <div className="created">
                                                                    <h6><b>Updated By</b></h6>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                                    <div className="last_activity">
                                                                        <h6 className="d-inline-block">
                                                                            <label className="created_date d-inline-block">{props.ticket.updated_by.first_name}</label>
                                                                            <label className="created_date d-inline-block">{props.ticket.updated_by.last_name}</label>
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                                <div className="created">
                                                                <h6><b>Updated On</b></h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                                <div className="last_activity">
                                                                    <h6>
                                                                        <label className="created_date">{convertDate(props.ticket.updated_on, props.ticket.account.timezone)}</label>
                                                                        <label className="created_time">{convertAreaScheduleTime(props.ticket.updated_on, props.ticket.account.timezone)}</label>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>: null}

                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Last Activity</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{convertDate(props.ticket.updated_at, props.ticket.account.timezone)}</label>
                                                                    <label className="created_time">{convertAreaScheduleTime(props.ticket.updated_at, props.ticket.account.timezone)}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        props.ticket?.pctlogs?.length ? <>
                                                        <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>First Name</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket?.pctlogs[0]?.first_name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Last Name</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket?.pctlogs[0]?.last_name}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>Email</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6>
                                                                    <label className="created_date">{props.ticket?.pctlogs[0]?.email}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        </>  : null
                                                    }
                                                    {props.ticket.status === 'canceled' || props.ticket.status === 'resolved' ? <div className="created_name">
                                                    <div className="row">
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="created">
                                                            <h6><b>{props.ticket.status === 'canceled' ? 'Canceled By' : props.ticket.status === 'resolved' ? 'Resolved By' : null}</b></h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-6 gutter">
                                                            <div className="last_activity">
                                                                <h6 className="d-inline-block">
                                                                    <label className="created_date d-inline-block">{props.ticket.status === 'canceled' ? props.ticket.cancelled_by?.first_name : props.ticket.status === 'resolved' ? props.ticket.resolved_user?.first_name : null}</label>
                                                                    <label className="created_date d-inline-block">{props.ticket.status === 'canceled' ? props.ticket.cancelled_by?.last_name : props.ticket.status === 'resolved' ? props.ticket.resolved_user?.last_name : null}</label>
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            {/* <div className="modal fade request_quote_modal reject_quot associateUser" id="cancel123" tabindex="-1" role="dialog" aria-labelledby="rejectQuoteModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <label for="exampleInputEmail1" className="cst-label md_title">Reason for cancelling</label>
                        <button type="button" className="close close_icn_pic" data-dismiss="modal"><img src="/images/icons/icn_close_gray.png" alt="Close Icon" /></button>
                    </div>
                    <div className="modal-body mt-5">
                        <div className="tab-content">
                            <div id="profile" className="profile_tab cst_tab_content tab-pane fade show active edit_profile_modal">
                                    <div className="form-group">
                                        <textarea type="textarea"  name="cancelReason" value={cancelComment} onChange={(e) => setCancelComment(e.target.value)} className="form-control textarea_modifier" rows="6" placeholder="Detailed Message" id="cancel" />
                                    </div>
                                    <div className="modal-footer">
                                        <button data-test="submit_button"  data-dismiss="modal" onClick={cancelCommentHandler} disabled={props.isLoading || cancelComment.trim() === ''} className={props.isLoading ? "btn cst_btn btn_danger mr-2 btn-wait" : "btn cst_btn btn_danger mr-2"} >{props.isLoading ? "" : "Cancel Ticket"}</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div > */}
            </>
            )
        }
    }
}


const mapStateToProps = (state) => {
    let ticket = {};
    if (!isEmpty(state.adminOrSuperAdminReducer.ticket)) {
        ticket = state.adminOrSuperAdminReducer.ticket;
        return { initialValues: { ticket },
        formStates: getFormValues('previewTicketForm')(state)
    }
}

}

PreviewTicket = reduxForm({
    form: 'previewTicketForm',
    validate,
    enableReinitialize: true
})(PreviewTicket);

PreviewTicket = connect(mapStateToProps)(PreviewTicket)

export default PreviewTicket;