import { safetyActionTypes } from "../../actions/SafetyActions/SafetyActionType";


export const initialState = {
    safetyToggle: false,
    isLoading: false,
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const safetyReducer = (state = initialState, action) => {
    switch (action.type) {

        case safetyActionTypes.SAFETY_CLICKED:
            return updateObject(state, { safetyToggle: !state.safetyToggle });

        case safetyActionTypes.ADD_VEHICLE_SAFETY_PENDING:
            return updateObject(state, { isLoading: true })
        case safetyActionTypes.ADD_VEHICLE_SAFETY_FULFILLED:
            return updateObject(state, { isLoading: false })

        case safetyActionTypes.SAFETY_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })

        case safetyActionTypes.UPLOAD_IMAGE_TO_S3_PENDING:
            return updateObject(state, { isLoading: true })
        case safetyActionTypes.UPLOAD_IMAGE_TO_S3_FULFILLED:
            return updateObject(state, { isLoading: true })

        case safetyActionTypes.ADD_GOLF_CART_SAFETY_PENDING:
            return updateObject(state, { isLoading: true })
        case safetyActionTypes.ADD_GOLF_CART_SAFETY_FULFILLED:
            return updateObject(state, { isLoading: false })

        default:
            return state;
    }
};