export const TimeTrackerModTypes = {

    UPLOAD_IMAGE_TO_S3: 'UPLOAD_IMAGE_TO_S3',
    UPLOAD_IMAGE_TO_S3_PENDING: 'UPLOAD_IMAGE_TO_S3_PENDING',
    UPLOAD_IMAGE_TO_S3_FULFILLED: 'UPLOAD_IMAGE_TO_S3_FULFILLED',

    // FOR FACE RECOGNITION
    GET_SCANNED_FACE_IMAGE_PRESIGNED_URL: 'GET_SCANNED_FACE_IMAGE_PRESIGNED_URL',
    GET_SCANNED_FACE_IMAGE_PRESIGNED_URL_PENDING: 'GET_SCANNED_FACE_IMAGE_PRESIGNED_URL_PENDING',
    GET_SCANNED_FACE_IMAGE_PRESIGNED_URL_FULFILLED: 'GET_SCANNED_FACE_IMAGE_PRESIGNED_URL_FULFILLED',

    // FOR FACE RECOGNITION
    GET_RECOGNIZED_FACE_ACCOUNT_CREDS: 'GET_RECOGNIZED_FACE_ACCOUNT_CREDS',
    GET_RECOGNIZED_FACE_ACCOUNT_CREDS_PENDING: 'GET_RECOGNIZED_FACE_ACCOUNT_CREDS_PENDING',
    GET_RECOGNIZED_FACE_ACCOUNT_CREDS_FULFILLED: 'GET_RECOGNIZED_FACE_ACCOUNT_CREDS_FULFILLED',

    RESET_TIME_TRACKER_OBJECTS: 'RESET_TIME_TRACKER_OBJECTS',

    RESET_SEARCH_OR_RESET: 'RESET_SEARCH_OR_RESET',

    HANDLE_FREQUENT_TIME_TRACKER_OPEN_DELETE_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_OPEN_DELETE_MODAL',
    HANDLE_FREQUENT_TIME_TRACKER_CLOSE_DELETE_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_CLOSE_DELETE_MODAL',

    HANDLE_FREQUENT_TIME_TRACKER_OPEN_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_OPEN_MODAL',
    HANDLE_FREQUENT_TIME_TRACKER_CLOSE_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_CLOSE_MODAL',

    HANDLE_CANCEL_CLICK_ACTION: 'HANDLE_CANCEL_CLICK_ACTION',

    HANDLE_STATIC_TRIGGER_CLICK: 'HANDLE_STATIC_TRIGGER_CLICK',

    HANDLE_SHIFT_PRESENCE_PANEL_OPEN_MODAL: 'HANDLE_SHIFT_PRESENCE_PANEL_OPEN_MODAL',
    HANDLE_SHIFT_PRESENCE_PANEL_CLOSE_MODAL: 'HANDLE_SHIFT_PRESENCE_PANEL_CLOSE_MODAL',

    GET_USERS_LIST_BY_THEIR_ROLES: 'GET_USERS_LIST_BY_THEIR_ROLES',
    GET_USERS_LIST_BY_THEIR_ROLES_PENDING: 'GET_USERS_LIST_BY_THEIR_ROLES_PENDING',
    GET_USERS_LIST_BY_THEIR_ROLES_FULFILLED: 'GET_USERS_LIST_BY_THEIR_ROLES_FULFILLED',

    GET_JOB_ACCOUNT_SITES_LIST: 'GET_JOB_ACCOUNT_SITES_LIST',
    GET_JOB_ACCOUNT_SITES_LIST_PENDING: 'GET_JOB_ACCOUNT_SITES_LIST_PENDING',
    GET_JOB_ACCOUNT_SITES_LIST_FULFILLED: 'GET_JOB_ACCOUNT_SITES_LIST_FULFILLED',

    GET_LEAVEUPDATE_MANAGERS_LIST: 'GET_LEAVEUPDATE_MANAGERS_LIST',
    GET_LEAVEUPDATE_MANAGERS_LIST_PENDING: 'GET_LEAVEUPDATE_MANAGERS_LIST_PENDING',
    GET_LEAVEUPDATE_MANAGERS_LIST_FULFILLED: 'GET_LEAVEUPDATE_MANAGERS_LIST_FULFILLED',

    GET_SKILLSET_LIST: "GET_SKILLSET_LIST",
    GET_SKILLSET_LIST_PENDING: 'GET_SKILLSET_LIST_PENDING',
    GET_SKILLSET_LIST_FULFILLED: 'GET_SKILLSET_LIST_FULFILLED',

    CREATE_SKILLSET: 'CREATE_SKILLSET',
    CREATE_SKILLSET_PENDING: 'CREATE_SKILLSET_PENDING',
    CREATE_SKILLSET_FULFILLED: 'CREATE_SKILLSET_FULFILLED',

    EDIT_SKILLSET_CLICKED: 'EDIT_SKILLSET_CLICKED',

    EDIT_SKILLSET: 'EDIT_SKILLSET',
    EDIT_SKILLSET_PENDING: 'EDIT_SKILLSET_PENDING',
    EDIT_SKILLSET_FULFILLED: 'EDIT_SKILLSET_FULFILLED',

    DELETE_SKILLSET: 'DELETE_SKILLSET',
    DELETE_SKILLSET_PENDING: 'DELETE_SKILLSET_PENDING',
    DELETE_SKILLSET_FULFILLED: 'DELETE_SKILLSET_FULFILLED',

    GET_DEPARTMENT_LIST: 'GET_DEPARTMENT_LIST',
    GET_DEPARTMENT_LIST_PENDING: 'GET_DEPARTMENT_LIST_PENDING',
    GET_DEPARTMENT_LIST_FULFILLED: 'GET_DEPARTMENT_LIST_FULFILLED',

    CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
    CREATE_DEPARTMENT_PENDING: 'CREATE_DEPARTMENT_PENDING',
    CREATE_DEPARTMENT_FULFILLED: 'CREATE_DEPARTMENT_FULFILLED',

    EDIT_DEPARTMENT_CLICKED: 'EDIT_DEPARTMENT_CLICKED',

    EDIT_DEPARTMENT: 'EDIT_DEPARTMENT',
    EDIT_DEPARTMENT_PENDING: 'EDIT_DEPARTMENT_PENDING',
    EDIT_DEPARTMENT_FULFILLED: 'EDIT_DEPARTMENT_FULFILLED',

    DELETE_DEPARTMENT: 'DELETE_DEPARTMENT',
    DELETE_DEPARTMENT_PENDING: 'DELETE_DEPARTMENT_PENDING',
    DELETE_DEPARTMENT_FULFILLED: 'DELETE_DEPARTMENT_FULFILLED',

    GET_ALL_LEAVE_LIST: 'GET_ALL_LEAVE_LIST',
    GET_ALL_LEAVE_LIST_PENDING: 'GET_ALL_LEAVE_LIST_PENDING',
    GET_ALL_LEAVE_LIST_FULFILLED: 'GET_ALL_LEAVE_LIST_FULFILLED',

    GET_SINGLE_LEAVE_REQUEST: 'GET_SINGLE_LEAVE_REQUEST',
    GET_SINGLE_LEAVE_REQUEST_PENDING: 'GET_SINGLE_LEAVE_REQUEST_PENDING',
    GET_SINGLE_LEAVE_REQUEST_FULFILLED: 'GET_SINGLE_LEAVE_REQUEST_FULFILLED',

    LEAVE_LIST_PAGE_CLICKED: 'LEAVE_LIST_PAGE_CLICKED',

    DELETE_LEAVE: 'DELETE_LEAVE',
    DELETE_LEAVE_PENDING: 'DELETE_LEAVE_PENDING',
    DELETE_LEAVE_FULFILLED: 'DELETE_LEAVE_FULFILLED',

    GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL: 'GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL',
    GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL_PENDING: 'GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL_PENDING',
    GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL_FULFILLED: 'GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL_FULFILLED',

    CREATE_FRONTLINER_LEAVE_REQUEST: 'CREATE_FRONTLINER_LEAVE_REQUEST',
    CREATE_FRONTLINER_LEAVE_REQUEST_PENDING: 'CREATE_FRONTLINER_LEAVE_REQUEST_PENDING',
    CREATE_FRONTLINER_LEAVE_REQUEST_FULFILLED: 'CREATE_FRONTLINER_LEAVE_REQUEST_FULFILLED',

    GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL: 'GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL',
    GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL_PENDING: 'GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL_PENDING',
    GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL_FULFILLED: 'GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL_FULFILLED',

    EDIT_FRONTLINER_LEAVE_REQUEST: 'EDIT_FRONTLINER_LEAVE_REQUEST',
    EDIT_FRONTLINER_LEAVE_REQUEST_PENDING: 'EDIT_FRONTLINER_LEAVE_REQUEST_PENDING',
    EDIT_FRONTLINER_LEAVE_REQUEST_FULFILLED: 'EDIT_FRONTLINER_LEAVE_REQUEST_FULFILLED',

    GET_CLOSE_LEAVE_REQUEST: 'GET_CLOSE_LEAVE_REQUEST',
    GET_CLOSE_LEAVE_REQUEST_PENDING: 'GET_CLOSE_LEAVE_REQUEST_PENDING',
    GET_CLOSE_LEAVE_REQUEST_FULFILLED: 'GET_CLOSE_LEAVE_REQUEST_FULFILLED',

    PREVIEW_SINGLE_SCHEDULED_TASK: 'PREVIEW_SINGLE_SCHEDULED_TASK',

    GET_ALL_SCHEDULED_TASK: 'GET_ALL_SCHEDULED_TASK',
    GET_ALL_SCHEDULED_TASK_PENDING: 'GET_ALL_SCHEDULED_TASK_PENDING',
    GET_ALL_SCHEDULED_TASK_FULFILLED: 'GET_ALL_SCHEDULED_TASK_FULFILLED',

    SCHEDULED_TASK_LIST_PAGE_CLICKED: 'SCHEDULED_TASK_LIST_PAGE_CLICKED',

    GET_ALL_SUB_SCHEDULED_TASK: 'GET_ALL_SUB_SCHEDULED_TASK',
    GET_ALL_SUB_SCHEDULED_TASK_PENDING: 'GET_ALL_SUB_SCHEDULED_TASK_PENDING',
    GET_ALL_SUB_SCHEDULED_TASK_FULFILLED: 'GET_ALL_SUB_SCHEDULED_TASK_FULFILLED',

    SUB_SCHEDULED_TASK_LIST_PAGE_CLICKED: 'SUB_SCHEDULED_TASK_LIST_PAGE_CLICKED',

    GET_PREVIEW_SUB_SCHEDULED_TASK: 'GET_PREVIEW_SUB_SCHEDULED_TASK',
    GET_PREVIEW_SUB_SCHEDULED_TASK_PENDING: 'GET_PREVIEW_SUB_SCHEDULED_TASK_PENDING',
    GET_PREVIEW_SUB_SCHEDULED_TASK_FULFILLED: 'GET_PREVIEW_SUB_SCHEDULED_TASK_FULFILLED',

    GET_COMPANY_LIST: "GET_COMPANY_LIST",
    GET_COMPANY_LIST_PENDING: 'GET_COMPANY_LIST_PENDING',
    GET_COMPANY_LIST_FULFILLED: 'GET_COMPANY_LIST_FULFILLED',

    NEW_SCHEDULED_TASK_TICKET: 'NEW_SCHEDULED_TASK_TICKET',
    NEW_SCHEDULED_TASK_TICKET_PENDING: 'NEW_SCHEDULED_TASK_TICKET_PENDING',
    NEW_SCHEDULED_TASK_TICKET_REJECTED: 'NEW_SCHEDULED_TASK_TICKET_REJECTED',
    NEW_SCHEDULED_TASK_TICKET_FULFILLED: 'NEW_SCHEDULED_TASK_TICKET_FULFILLED',

    GET_JOB_LIST: "GET_JOB_LIST",
    GET_JOB_LIST_PENDING: 'GET_JOB_LIST_PENDING',
    GET_JOB_LIST_FULFILLED: 'GET_JOB_LIST_FULFILLED',

    GET_SCHEDULED_TASK_EVENTS: 'GET_SCHEDULED_TASK_EVENTS',
    GET_SCHEDULED_TASK_EVENTS_PENDING: 'GET_SCHEDULED_TASK_EVENTS_PENDING',
    GET_SCHEDULED_TASK_EVENTS_FULFILLED: 'GET_SCHEDULED_TASK_EVENTS_FULFILLED',

    ADD_COMMENT: 'ADD_COMMENT',
    ADD_COMMENT_PENDING: 'ADD_COMMENT_PENDING',
    ADD_COMMENT_FULFILLED: 'ADD_COMMENT_FULFILLED',

    COMMENT_PRESIGN_URL: 'COMMENT_PRESIGN_URL',
    COMMENT_PRESIGN_URL_PENDING: 'COMMENT_PRESIGN_URL_PENDING',
    COMMENT_PRESIGN_URL_FULFILLED: 'COMMENT_PRESIGN_URL_FULFILLED',

    UPDATE_COMMENT: 'UPDATE_COMMENT',
    UPDATE_COMMENT_PENDING: 'UPDATE_COMMENT_PENDING',
    UPDATE_COMMENT_FULFILLED: 'UPDATE_COMMENT_FULFILLED',

    GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST: 'GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST',
    GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST_PENDING: 'GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST_PENDING',
    GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST_FULFILLED: 'GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST_FULFILLED',

    ADD_AVAILABILITY: 'ADD_AVAILABILITY',
    ADD_AVAILABILITY_PENDING: 'ADD_AVAILABILITY_PENDING',
    ADD_AVAILABILITY_REJECTED: 'ADD_AVAILABILITY_REJECTED',
    ADD_AVAILABILITY_FULFILLED: 'ADD_AVAILABILITY_FULFILLED',

    OVERRIDE_AVAILABILITY: 'OVERRIDE_AVAILABILITY',
    OVERRIDE_AVAILABILITY_PENDING: 'OVERRIDE_AVAILABILITY_PENDING',
    OVERRIDE_AVAILABILITY_REJECTED: 'OVERRIDE_AVAILABILITY_REJECTED',
    OVERRIDE_AVAILABILITY_FULFILLED: 'OVERRIDE_AVAILABILITY_FULFILLED',

    GET_ALL_SHIFT_SCHEDULE: 'GET_ALL_SHIFT_SCHEDULE',
    GET_ALL_SHIFT_SCHEDULE_PENDING: 'GET_ALL_SHIFT_SCHEDULE_PENDING',
    GET_ALL_SHIFT_SCHEDULE_FULFILLED: 'GET_ALL_SHIFT_SCHEDULE_FULFILLED',

    SHIFT_SCHEDULE_PAGE_CLICKED: 'SHIFT_SCHEDULE_PAGE_CLICKED',

    PREVIEW_SINGLE_SHIFT_SCHEDULE: 'PREVIEW_SINGLE_SHIFT_SCHEDULE',

    GET_ALL_SHIFT_SCHEDULE_DETAIL: 'GET_ALL_SHIFT_SCHEDULE_DETAIL',
    GET_ALL_SHIFT_SCHEDULE_DETAIL_PENDING: 'GET_ALL_SHIFT_SCHEDULE_DETAIL_PENDING',
    GET_ALL_SHIFT_SCHEDULE_DETAIL_FULFILLED: 'GET_ALL_SHIFT_SCHEDULE_DETAIL_FULFILLED',

    SHIFT_SCHEDULE_DETAIL_PAGE_CLICKED: 'SHIFT_SCHEDULE_DETAIL_PAGE_CLICKED',

    GET_SHIFT_CALENDAR_SCHEDULED_LIST: 'GET_SHIFT_CALENDAR_SCHEDULED_LIST',
    GET_SHIFT_CALENDAR_SCHEDULED_LIST_PENDING: 'GET_SHIFT_CALENDAR_SCHEDULED_LIST_PENDING',
    GET_SHIFT_CALENDAR_SCHEDULED_LIST_FULFILLED: 'GET_SHIFT_CALENDAR_SCHEDULED_LIST_FULFILLED',

    GET_SINGLE_SHIFT_SCHEDULE: 'GET_SINGLE_SHIFT_SCHEDULE',
    GET_SINGLE_SHIFT_SCHEDULE_PENDING: 'GET_SINGLE_SHIFT_SCHEDULE_PENDING',
    GET_SINGLE_SHIFT_SCHEDULE_FULFILLED: 'GET_SINGLE_SHIFT_SCHEDULE_FULFILLED',

    GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER: 'GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER',
    GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER_PENDING: 'GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER_PENDING',
    GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER_FULFILLED: 'GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER_FULFILLED',

    CHECK_FRONTLINER_SHIFT: 'CHECK_FRONTLINER_SHIFT',
    CHECK_FRONTLINER_SHIFT_PENDING: 'CHECK_FRONTLINER_SHIFT_PENDING',
    CHECK_FRONTLINER_SHIFT_FULFILLED: 'CHECK_FRONTLINER_SHIFT_FULFILLED',

    CREATE_FRONTLINER_SHIFT: 'CREATE_FRONTLINER_SHIFT',
    CREATE_FRONTLINER_SHIFT_PENDING: 'CREATE_FRONTLINER_SHIFT_PENDING',
    CREATE_FRONTLINER_SHIFT_FULFILLED: 'CREATE_FRONTLINER_SHIFT_FULFILLED',

    EDIT_FRONTLINER_SHIFT: 'EDIT_FRONTLINER_SHIFT',
    EDIT_FRONTLINER_SHIFT_PENDING: 'EDIT_FRONTLINER_SHIFT_PENDING',
    EDIT_FRONTLINER_SHIFT_FULFILLED: 'EDIT_FRONTLINER_SHIFT_FULFILLED',

    EDIT_PERDAY_FLUSER_SHIFT_DETAIL: 'EDIT_PERDAY_FLUSER_SHIFT_DETAIL',
    EDIT_PERDAY_FLUSER_SHIFT_DETAIL_PENDING: 'EDIT_PERDAY_FLUSER_SHIFT_DETAIL_PENDING',
    EDIT_PERDAY_FLUSER_SHIFT_DETAIL_FULFILLED: 'EDIT_PERDAY_FLUSER_SHIFT_DETAIL_FULFILLED',

    DELETE_SHIFT: 'DELETE_SHIFT',
    DELETE_SHIFT_PENDING: 'DELETE_SHIFT_PENDING',
    DELETE_SHIFT_FULFILLED: 'DELETE_SHIFT_FULFILLED',

    GET_ALL_SHIFT: 'GET_ALL_SHIFT',
    GET_ALL_SHIFT_PENDING: 'GET_ALL_SHIFT_PENDING',
    GET_ALL_SHIFT_FULFILLED: 'GET_ALL_SHIFT_FULFILLED',

    GRANT_CANCEL_SHIFT_REQUEST: 'GRANT_CANCEL_SHIFT_REQUEST',
    GRANT_CANCEL_SHIFT_REQUEST_PENDING: 'GRANT_CANCEL_SHIFT_REQUEST_PENDING',
    GRANT_CANCEL_SHIFT_REQUEST_FULFILLED: 'GRANT_CANCEL_SHIFT_REQUEST_FULFILLED',

    GET_ALL_SWAP_LIST: 'GET_ALL_SWAP_LIST',
    GET_ALL_SWAP_LIST_PENDING: 'GET_ALL_SWAP_LIST_PENDING',
    GET_ALL_SWAP_LIST_FULFILLED: 'GET_ALL_SWAP_LIST_FULFILLED',

    GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO: 'GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO',
    GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO_PENDING: 'GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO_PENDING',
    GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO_FULFILLED: 'GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO_FULFILLED',

    GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO: 'GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO',
    GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO_PENDING: 'GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO_PENDING',
    GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO_FULFILLED: 'GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO_FULFILLED',

    CREATE_FLUSER_SHIFT_SWAP: 'CREATE_FLUSER_SHIFT_SWAP',
    CREATE_FLUSER_SHIFT_SWAP_PENDING: 'CREATE_FLUSER_SHIFT_SWAP_PENDING',
    CREATE_FLUSER_SHIFT_SWAP_FULFILLED: 'CREATE_FLUSER_SHIFT_SWAP_FULFILLED',

    GET_SPECIFIC_SHIFT_SWAP_INFO: 'GET_SPECIFIC_SHIFT_SWAP_INFO',
    GET_SPECIFIC_SHIFT_SWAP_INFO_PENDING: 'GET_SPECIFIC_SHIFT_SWAP_INFO_PENDING',
    GET_SPECIFIC_SHIFT_SWAP_INFO_FULFILLED: 'GET_SPECIFIC_SHIFT_SWAP_INFO_FULFILLED',

    GRANT_SWAP_REQUEST_REVIEW: 'GRANT_SWAP_REQUEST_REVIEW',
    GRANT_SWAP_REQUEST_REVIEW_PENDING: 'GRANT_SWAP_REQUEST_REVIEW_PENDING',
    GRANT_SWAP_REQUEST_REVIEW_FULFILLED: 'GRANT_SWAP_REQUEST_REVIEW_FULFILLED',

    SWAP_LIST_PAGE_CLICKED: 'SWAP_LIST_PAGE_CLICKED',

    GET_OVERRIDE_AVAILABILITY_REQUEST_LIST: 'GET_OVERRIDE_AVAILABILITY_REQUEST_LIST',
    GET_OVERRIDE_AVAILABILITY_REQUEST_LIST_PENDING: 'GET_OVERRIDE_AVAILABILITY_REQUEST_LIST_PENDING',
    GET_OVERRIDE_AVAILABILITY_REQUEST_LIST_FULFILLED: 'GET_OVERRIDE_AVAILABILITY_REQUEST_LIST_FULFILLED',

    OVERRIDE_AVAILABILITY_REQUEST_LIST_PAGE_CLICKED: 'OVERRIDE_AVAILABILITY_REQUEST_LIST_PAGE_CLICKED',

    GRANT_OVERRIDE_AVAILABILITY_REQUEST: 'GRANT_OVERRIDE_AVAILABILITY_REQUEST',
    GRANT_OVERRIDE_AVAILABILITY_REQUEST_PENDING: 'GRANT_OVERRIDE_AVAILABILITY_REQUEST_PENDING',
    GRANT_OVERRIDE_AVAILABILITY_REQUEST_FULFILLED: 'GRANT_OVERRIDE_AVAILABILITY_REQUEST_FULFILLED',

    SINGLE_AVAILABILITY_LIST_CLICKED: 'SINGLE_AVAILABILITY_LIST_CLICKED',

    GET_ALL_AVAILABILITY_LIST: 'GET_ALL_AVAILABILITY_LIST',
    GET_ALL_AVAILABILITY_LIST_PENDING: 'GET_ALL_AVAILABILITY_LIST_PENDING',
    GET_ALL_AVAILABILITY_LIST_FULFILLED: 'GET_ALL_AVAILABILITY_LIST_FULFILLED',

    ALL_AVAILABILITY_LIST_CLICKED: 'ALL_AVAILABILITY_LIST_CLICKED',

    /* FL User Shift Clock In Shift Identify CHECK CALL */
    FLUSER_SHIFT_CLOCK_CHECKER: 'FLUSER_SHIFT_CLOCK_CHECKER',
    FLUSER_SHIFT_CLOCK_CHECKER_PENDING: 'FLUSER_SHIFT_CLOCK_CHECKER_PENDING',
    FLUSER_SHIFT_CLOCK_CHECKER_FULFILLED: 'FLUSER_SHIFT_CLOCK_CHECKER_FULFILLED',

    /* FL User Shift Clock In - Check In POST Call */
    FLUSER_SHIFT_CLOCK_CHECKIN: 'FLUSER_SHIFT_CLOCK_CHECKIN',
    FLUSER_SHIFT_CLOCK_CHECKIN_PENDING: 'FLUSER_SHIFT_CLOCK_CHECKIN_PENDING',
    FLUSER_SHIFT_CLOCK_CHECKIN_FULFILLED: 'FLUSER_SHIFT_CLOCK_CHECKIN_FULFILLED',

    /* FL User Shift Meal Break Session PUT Call */
    FLUSER_MEAL_BREAK_SESSION: 'FLUSER_MEAL_BREAK_SESSION',
    FLUSER_MEAL_BREAK_SESSION_PENDING: 'FLUSER_MEAL_BREAK_SESSION_PENDING',
    FLUSER_MEAL_BREAK_SESSION_FULFILLED: 'FLUSER_MEAL_BREAK_SESSION_FULFILLED',

    /* FL User Scheduled Tasks Tickets retrieve GET Call */
    GET_PER_FLUSER_SCHEDULED_TASK_TICKETS: 'GET_PER_FLUSER_SCHEDULED_TASK_TICKETS',
    GET_PER_FLUSER_SCHEDULED_TASK_TICKETS_PENDING: 'GET_PER_FLUSER_SCHEDULED_TASK_TICKETS_PENDING',
    GET_PER_FLUSER_SCHEDULED_TASK_TICKETS_FULFILLED: 'GET_PER_FLUSER_SCHEDULED_TASK_TICKETS_FULFILLED',

    /* FL User Signature Attachment PATCH Call */
    GET_FLUSER_SIGN_ATTACH_PRESIGN_URL: 'GET_FLUSER_SIGN_ATTACH_PRESIGN_URL',
    GET_FLUSER_SIGN_ATTACH_PRESIGN_URL_PENDING: 'GET_FLUSER_SIGN_ATTACH_PRESIGN_URL_PENDING',
    GET_FLUSER_SIGN_ATTACH_PRESIGN_URL_FULFILLED: 'GET_FLUSER_SIGN_ATTACH_PRESIGN_URL_FULFILLED',

    /* FL User Shift Clock Out - Check Out POST Call */
    FLUSER_SHIFT_CLOCK_CHECKOUT: 'FLUSER_SHIFT_CLOCK_CHECKOUT',
    FLUSER_SHIFT_CLOCK_CHECKOUT_PENDING: 'FLUSER_SHIFT_CLOCK_CHECKOUT_PENDING',
    FLUSER_SHIFT_CLOCK_CHECKOUT_FULFILLED: 'FLUSER_SHIFT_CLOCK_CHECKOUT_FULFILLED',

    /* FL User Scheduled Task My Tickets GET call */
    GET_FLUSER_SCHEDULED_TASK_TICKETS: 'GET_FLUSER_SCHEDULED_TASK_TICKETS',
    GET_FLUSER_SCHEDULED_TASK_TICKETS_PENDING: 'GET_FLUSER_SCHEDULED_TASK_TICKETS_PENDING',
    GET_FLUSER_SCHEDULED_TASK_TICKETS_FULFILLED: 'GET_FLUSER_SCHEDULED_TASK_TICKETS_FULFILLED',

    FLUSER_ST_MY_TICKETS_PAGE_CLICKED: 'FLUSER_ST_MY_TICKETS_PAGE_CLICKED',

    /* Timecard Correction Signature Attachment PATCH Call */
    ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL: 'ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL',
    ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL_PENDING: 'ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL_PENDING',
    ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL_FULFILLED: 'ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL_FULFILLED',

    /* Time Card Correction POST Call */
    CREATE_TIMECARD_CORRECTION_QUERY: 'CREATE_TIMECARD_CORRECTION_QUERY',
    CREATE_TIMECARD_CORRECTION_QUERY_PENDING: 'CREATE_TIMECARD_CORRECTION_QUERY_PENDING',
    CREATE_TIMECARD_CORRECTION_QUERY_FULFILLED: 'CREATE_TIMECARD_CORRECTION_QUERY_FULFILLED',

    /* Time Card Correction Reviewed List GET call */
    GET_TIMECARD_REVIEWED_LIST: 'GET_TIMECARD_REVIEWED_LIST',
    GET_TIMECARD_REVIEWED_LIST_PENDING: 'GET_TIMECARD_REVIEWED_LIST_PENDING',
    GET_TIMECARD_REVIEWED_LIST_FULFILLED: 'GET_TIMECARD_REVIEWED_LIST_FULFILLED',

    TIMECARD_REVIEWED_LIST_PAGE_CLICKED: 'TIMECARD_REVIEWED_LIST_PAGE_CLICKED',

    /* Missed Meal Penalty Break Check API GET call */
    GET_MISSED_MEAL_PENALTY_BREAKS: 'GET_MISSED_MEAL_PENALTY_BREAKS',
    GET_MISSED_MEAL_PENALTY_BREAKS_PENDING: 'GET_MISSED_MEAL_PENALTY_BREAKS_PENDING',
    GET_MISSED_MEAL_PENALTY_BREAKS_FULFILLED: 'GET_MISSED_MEAL_PENALTY_BREAKS_FULFILLED',

    /* Time card correction single card API GET call */
    GET_SINGLE_TIMECARD_LIST_DATA: 'GET_SINGLE_TIMECARD_LIST_DATA',
    GET_SINGLE_TIMECARD_LIST_DATA_PENDING: 'GET_SINGLE_TIMECARD_LIST_DATA_PENDING',
    GET_SINGLE_TIMECARD_LIST_DATA_FULFILLED: 'GET_SINGLE_TIMECARD_LIST_DATA_FULFILLED',

    /* Time Card Correction PUT Call */
    UPDATE_TIMECARD_CORRECTION_QUERY: 'UPDATE_TIMECARD_CORRECTION_QUERY',
    UPDATE_TIMECARD_CORRECTION_QUERY_PENDING: 'UPDATE_TIMECARD_CORRECTION_QUERY_PENDING',
    UPDATE_TIMECARD_CORRECTION_QUERY_FULFILLED: 'UPDATE_TIMECARD_CORRECTION_QUERY_FULFILLED',

    /* Time Sheet List GET call */
    GET_TIME_SHEET_LIST_INFO: 'GET_TIME_SHEET_LIST_INFO',
    GET_TIME_SHEET_LIST_INFO_PENDING: 'GET_TIME_SHEET_LIST_INFO_PENDING',
    GET_TIME_SHEET_LIST_INFO_FULFILLED: 'GET_TIME_SHEET_LIST_INFO_FULFILLED',

    TIME_SHEET_LIST_INFO_PAGE_CLICKED: 'TIME_SHEET_LIST_INFO_PAGE_CLICKED',

    /* Download Payroll CSV GET call */
    TRIGGER_EXPORT_PAYROLL_CSV: 'TRIGGER_EXPORT_PAYROLL_CSV',
    TRIGGER_EXPORT_PAYROLL_CSV_PENDING: 'TRIGGER_EXPORT_PAYROLL_CSV_PENDING',
    TRIGGER_EXPORT_PAYROLL_CSV_FULFILLED: 'TRIGGER_EXPORT_PAYROLL_CSV_FULFILLED',

    /* Get shifts by manipulating params - GET call */
    GET_SHIFT_LIST_BY_PARAMS: 'GET_SHIFT_LIST_BY_PARAMS',
    GET_SHIFT_LIST_BY_PARAMS_PENDING: 'GET_SHIFT_LIST_BY_PARAMS_PENDING',
    GET_SHIFT_LIST_BY_PARAMS_FULFILLED: 'GET_SHIFT_LIST_BY_PARAMS_FULFILLED',

    /* Get shifts by Attendance Dashboard Calendar List - GET call */
    GET_SHIFT_ATTENDANCE_CALENDAR_LIST: 'GET_SHIFT_ATTENDANCE_CALENDAR_LIST',
    GET_SHIFT_ATTENDANCE_CALENDAR_LIST_PENDING: 'GET_SHIFT_ATTENDANCE_CALENDAR_LIST_PENDING',
    GET_SHIFT_ATTENDANCE_CALENDAR_LIST_FULFILLED: 'GET_SHIFT_ATTENDANCE_CALENDAR_LIST_FULFILLED',

    /* resolve scheduled task ticket - PUT call */
    RESOLVE_SCHEDULE_TICKET: 'RESOLVE_SCHEDULE_TICKET',
    RESOLVE_SCHEDULE_TICKET_PENDING: 'RESOLVE_SCHEDULE_TICKET_PENDING',
    RESOLVE_SCHEDULE_TICKET_FULFILLED: 'RESOLVE_SCHEDULE_TICKET_FULFILLED',
    RESOLVE_SCHEDULE_TICKET_REJECTED: 'RESOLVE_SCHEDULE_TICKET_REJECTED',

    DELETE_TIMECARD_CORRECTION_CARD: 'DELETE_TIMECARD_CORRECTION_CARD',
    DELETE_TIMECARD_CORRECTION_CARD_PENDING: 'DELETE_TIMECARD_CORRECTION_CARD_PENDING',
    DELETE_TIMECARD_CORRECTION_CARD_FULFILLED: 'DELETE_TIMECARD_CORRECTION_CARD_FULFILLED',

    /* FL User Move on to Next Job - PUT Call */
    FLUSER_SHIFT_NEXT_JOB: 'FLUSER_SHIFT_NEXT_JOB',
    FLUSER_SHIFT_NEXT_JOB_PENDING: 'FLUSER_SHIFT_NEXT_JOB_PENDING',
    FLUSER_SHIFT_NEXT_JOB_FULFILLED: 'FLUSER_SHIFT_NEXT_JOB_FULFILLED',
    FLUSER_SHIFT_NEXT_JOB_REJECTED: 'FLUSER_SHIFT_NEXT_JOB_REJECTED',

    CLEAR_SINGLE_SHIFT_DETAIL : "CLEAR_SINGLE_SHIFT_DETAIL",

    // FOR CLOCKIN AND CLOCKOUT FACE RECOGNITION
    GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL: 'GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL',
    GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL_PENDING: 'GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL_PENDING',
    GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL_FULFILLED: 'GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL_FULFILLED',

    // FOR CLOCKIN AND CLOCKOUT FACE RECOGNITION
    FRONTLINE_FACE_RECOGNITION: 'FRONTLINE_FACE_RECOGNITION',
    FRONTLINE_FACE_RECOGNITION_PENDING: 'FRONTLINE_FACE_RECOGNITION_PENDING',
    FRONTLINE_FACE_RECOGNITION_FULFILLED: 'FRONTLINE_FACE_RECOGNITION_FULFILLED',


    //Hours Types 
    HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_OPEN_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_OPEN_MODAL',
    HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_CLOSE_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_CLOSE_MODAL',   

    HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_OPEN_DELETE_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_OPEN_DELETE_MODAL',
    HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_CLOSE_DELETE_MODAL: 'HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_CLOSE_DELETE_MODAL',

    CREATE_HOURSTYPE: 'CREATE_HOURSTYPE',
    CREATE_HOURSTYPE_PENDING: 'CREATE_HOURSTYPE_PENDING',
    CREATE_HOURSTYPE_FULFILLED: 'CREATE_HOURSTYPE_FULFILLED',

    GET_HOURSTYPE_LIST: 'GET_HOURSTYPE_LIST',
    GET_HOURSTYPE_LIST_PENDING: 'GET_HOURSTYPE_LIST_PENDING',
    GET_HOURSTYPE_LIST_FULFILLED: "GET_HOURSTYPE_LIST_FULFILLED",


    DELETE_HOURSTYPE: 'DELETE_HOURSTYPE',
    DELETE_HOURSTYPE_PENDING: 'DELETE_HOURSTYPE_PENDING',
    DELETE_HOURSTYPE_FULFILLED: 'DELETE_HOURSTYPE_FULFILLED',


    EDIT_HOURSTYPE_CLICKED: 'EDIT_HOURSTYPE_CLICKED',

    EDIT_HOURSTYPE: 'EDIT_HOURSTYPE',
    EDIT_HOURSTYPE_PENDING: 'EDIT_HOURSTYPE_PENDING',
    EDIT_HOURSTYPE_FULFILLED: 'EDIT_HOURSTYPE_FULFILLED',


    FETCH_PAYROLL_HOLIDAY_LIST : "FETCH_PAYROLL_HOLIDAY_LIST",
    FETCH_PAYROLL_HOLIDAY_LIST_PENDING : "FETCH_PAYROLL_HOLIDAY_LIST_PENDING",
    FETCH_PAYROLL_HOLIDAY_LIST_FULFILLED : "FETCH_PAYROLL_HOLIDAY_LIST_FULFILLED",
    FETCH_PAYROLL_HOLIDAY_LIST_REJECTED : "FETCH_PAYROLL_HOLIDAY_LIST_REJECTED",

    CREATE_PAYROLL_HOLIDAY_LIST : "CREATE_PAYROLL_HOLIDAY_LIST",
    CREATE_PAYROLL_HOLIDAY_LIST_PENDING : "CREATE_PAYROLL_HOLIDAY_LIST_PENDING",
    CREATE_PAYROLL_HOLIDAY_LIST_FULFILLED : "CREATE_PAYROLL_HOLIDAY_LIST_FULFILLED",
    CREATE_PAYROLL_HOLIDAY_LIST_REJECTED : "CREATE_PAYROLL_HOLIDAY_LIST_REJECTED",

    DELETE_PAYROLL_HOLIDAY_LIST : "DELETE_PAYROLL_HOLIDAY_LIST",
    DELETE_PAYROLL_HOLIDAY_LIST_PENDING : "DELETE_PAYROLL_HOLIDAY_LIST_PENDING",
    DELETE_PAYROLL_HOLIDAY_LIST_FULFILLED : "DELETE_PAYROLL_HOLIDAY_LIST_FULFILLED",
    DELETE_PAYROLL_HOLIDAY_LIST_REJECTED : "DELETE_PAYROLL_HOLIDAY_LIST_REJECTED",

    IMPORT_CHECK_HOURS_TYPE: 'IMPORT_CHECK_HOURS_TYPE',
    IMPORT_CHECK_HOURS_TYPE_PENDING: 'IMPORT_CHECK_HOURS_TYPE_PENDING',
    IMPORT_CHECK_HOURS_TYPE_REJECTED: 'IMPORT_CHECK_HOURS_TYPE_REJECTED',
    IMPORT_CHECK_HOURS_TYPE_FULFILLED: 'IMPORT_CHECK_HOURS_TYPE_FULFILLED',

    IMPORT_HOURS_TYPE: 'IMPORT_HOURS_TYPE',
    IMPORT_HOURS_TYPE_PENDING: 'IMPORT_HOURS_TYPE_PENDING',
    IMPORT_HOURS_TYPE_FULFILLED: 'IMPORT_HOURS_TYPE_FULFILLED',
    IMPORT_HOURS_TYPE_REJECTED: 'IMPORT_HOURS_TYPE_REJECTED',
    UPDATE_SHIFT_SCHEDULE : "UPDATE_SHIFT_SCHEDULE",
    UPDATE_SHIFT_SCHEDULE_PENDING : "UPDATE_SHIFT_SCHEDULE_PENDING",
    UPDATE_SHIFT_SCHEDULE_FULFILLED : "UPDATE_SHIFT_SCHEDULE_FULFILLED",
    UPDATE_SHIFT_SCHEDULE_REJECTED : "UPDATE_SHIFT_SCHEDULE_REJECTED",

    FETCH_ACCOUNT_HOURSTYPE_LIST : "FETCH_ACCOUNT_HOURSTYPE_LIST",
    FETCH_ACCOUNT_HOURSTYPE_LIST_PENDING : "FETCH_ACCOUNT_HOURSTYPE_LIST_PENDING",
    FETCH_ACCOUNT_HOURSTYPE_LIST_FULFILLED : "FETCH_ACCOUNT_HOURSTYPE_LIST_FULFILLED",
    FETCH_ACCOUNT_HOURSTYPE_LIST_REJECTED : "FETCH_ACCOUNT_HOURSTYPE_LIST_REJECTED",


    DELETE_ACCOUNT_HOURSTYPE_LIST : "DELETE_ACCOUNT_HOURSTYPE_LIST",
    DELETE_ACCOUNT_HOURSTYPE_LIST_PENDING : "DELETE_ACCOUNT_HOURSTYPE_LIST_PENDING",
    DELETE_ACCOUNT_HOURSTYPE_LIST_FULFILLED : "DELETE_ACCOUNT_HOURSTYPE_LIST_FULFILLED",
    DELETE_ACCOUNT_HOURSTYPE_LIST_REJECTED : "DELETE_ACCOUNT_HOURSTYPE_LIST_REJECTED",

    GET_PCT_JOBS_LIST : "GET_PCT_JOBS_LIST",
    GET_PCT_JOBS_LIST_PENDING : "GET_PCT_JOBS_LIST_PENDING",
    GET_PCT_JOBS_LIST_FULFILLED : "GET_PCT_JOBS_LIST_FULFILLED",

    GET_PCT_FLOOR_LIST : "GET_PCT_FLOOR_LIST",
    GET_PCT_FLOOR_LIST_PENDING : "GET_PCT_FLOOR_LIST_PENDING",
    GET_PCT_FLOOR_LIST_FULFILLED : "GET_PCT_FLOOR_LIST_FULFILLED",

    GET_PCT_DEPARTMENT_LIST : "GET_PCT_DEPARTMENT_LIST",
    GET_PCT_DEPARTMENT_LIST_PENDING : "GET_PCT_DEPARTMENT_LIST_PENDING",
    GET_PCT_DEPARTMENT_LIST_FULFILLED : "GET_PCT_DEPARTMENT_LIST_FULFILLED",

    GET_PCT_PHONE_LIST : "GET_PCT_PHONE_LIST",
    GET_PCT_PHONE_LIST_PENDING : "GET_PCT_PHONE_LIST_PENDING",
    GET_PCT_PHONE_LIST_FULFILLED : "GET_PCT_PHONE_LIST_FULFILLED",

    RESET_FLOOR  : 'RESET_FLOOR',

    UPDATE_SHIFT_SCHEDULE_EXCEPTION: "UPDATE_SHIFT_SCHEDULE_EXCEPTION",
    UPDATE_SHIFT_SCHEDULE_EXCEPTION_PENDING : "UPDATE_SHIFT_SCHEDULE_EXCEPTION_PENDING",
    UPDATE_SHIFT_SCHEDULE_EXCEPTION_FULFILLED : "UPDATE_SHIFT_SCHEDULE_EXCEPTION_FULFILLED",
    UPDATE_SHIFT_SCHEDULE_EXCEPTION_REJECTED : "UPDATE_SHIFT_SCHEDULE_EXCEPTION_REJECTED",

    RESET_SHIFT_SCHEDULE_DETAIL_FILTERS : 'RESET_SHIFT_SCHEDULE_DETAIL_FILTERS',

    RESET_SHIFT_FILTERS : 'RESET_SHIFT_FILTERS',

};


