export const JoBAssociateTypes = {
GET_JOBASSOCIATES_LIST:"GET_JOBASSOCIATES_LIST",
GET_JOBASSOCIATES_LIST_PENDING:"GET_JOBASSOCIATES_LIST_PENDING",
GET_JOBASSOCIATES_LIST_FULFILLED:"GET_JOBASSOCIATES_LIST_FULFILLED",
GET_JOBASSOCIATES_LIST_REJECTED:"GET_JOBASSOCIATES_LIST_REJECTED",

CREATE_JOBASSOCIATES_LIST:"CREATE_JOBASSOCIATES_LIST",
CREATE_JOBASSOCIATES_LIST_PENDING:"CREATE_JOBASSOCIATES_LIST_PENDING",
CREATE_JOBASSOCIATES_LIST_FULFILLED:"CREATE_JOBASSOCIATES_LIST_FULFILLED",
CREATE_JOBASSOCIATES_LIST_REJECTED:"CREATE_JOBASSOCIATES_LIST_REJECTED",

JOB_ASSOCIATION_TASK_LIST_PAGE_CLICKED:"JOB_ASSOCIATION_TASK_LIST_PAGE_CLICKED",

GET_SINGLE_JOBASSOCIATES:"GET_SINGLE_JOBASSOCIATES",
GET_SINGLE_JOBASSOCIATES_PENDING:"GET_SINGLE_JOBASSOCIATES_PENDING",
GET_SINGLE_JOBASSOCIATES_FULFILLED:"GET_SINGLE_JOBASSOCIATES_FULFILLED",

GET_JOBS_OF_ASSOCIATION:"GET_JOBS_OF_ASSOCIATION",
GET_JOBS_OF_ASSOCIATION_PENDING:"GET_JOBS_OF_ASSOCIATION_PENDING",
GET_JOBS_OF_ASSOCIATION_FULFILLED:"GET_JOBS_OF_ASSOCIATION_FULFILLED",

// GET_ONE_JOBS_OF_ASSOCIATION:"GET_ONE_JOBS_OF_ASSOCIATION",
// GET_ONE_JOBS_OF_ASSOCIATION_PENDING:"GET_ONE_JOBS_OF_ASSOCIATION_PENDING",
// GET_ONE_JOBS_OF_ASSOCIATION_FULFILLED:"GET_ONE_JOBS_OF_ASSOCIATION_FULFILLED",
// GET_ONE_JOBS_OF_ASSOCIATION_REJECTED:"GET_ONE_JOBS_OF_ASSOCIATION_REJECTED",

UPDATE_JOBASSOCIATES_LIST:"UPDATE_JOBASSOCIATES_LIST",
UPDATE_JOBASSOCIATES_LIST_PENDING:"UPDATE_JOBASSOCIATES_LIST_PENDING",
UPDATE_JOBASSOCIATES_LIST_FULFILLED:"UPDATE_JOBASSOCIATES_LIST_FULFILLED",
UPDATE_JOBASSOCIATES_LIST_REJECTED:"UPDATE_JOBASSOCIATES_LIST_REJECTED",

DELETE_JOBASSOCIATES_LIST:"DELETE_JOBASSOCIATES_LIST",
DELETE_JOBASSOCIATES_LIST_PENDING:"DELETE_JOBASSOCIATES_LIST_PENDING",
DELETE_JOBASSOCIATES_LIST_FULFILLED:"DELETE_JOBASSOCIATES_LIST_FULFILLED",
DELETE_JOBASSOCIATES_LIST_REJECTED:"DELETE_JOBASSOCIATES_LIST_REJECTED",

CLEAR_JOB_ASSOCIATION_FORM: 'CLEAR_JOB_ASSOCIATION_FORM',
}