
import React, { Fragment, useEffect, useState } from 'react';
import { calculateHoursFromMinutes_V1, getOrdinalNumber, makeFirstLetterCapital } from '../../../../../utility/utility';
import PreviewShifScheduleSkeleton from './PreviewShifScheduleSkeleton';

import { tzDateConverterTool_V1, tzDateConverterTool_V7, tzTimeConversionTool_V1 } from '../../../../../utility/TimezoneOperations/timezoneUtility';

import { getFormValues, reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import HoursTypeComponent from './HoursTypeComponent';
import { fetchRateListDropDown } from '../../../../../API/TimeTrackerModApi';
import { newPreviewShiftValidator  } from '../../../../../utility/validator/validator';
import { status, userRoles } from '../../../../../utility/constants/constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { FacialImageClockInFlowConstants } from '../../../../../utility/constants/constants';
import ExceptionComponent from './ExceptionComponent';
import storage from '../../../../../utility/storage';
var feedGap = null;

let PreviewShifSchedule = (props) => {
    const { handleSubmit, pristine, reset, submitting, error, submitFailed } = props;  
    const { singleShiftScheduleData } = props

    const filterdFaceLogs=singleShiftScheduleData?.faceLogs && singleShiftScheduleData?.faceLogs.length ? singleShiftScheduleData?.faceLogs.filter((item)=> item.shift_action != null) :[]
    const [rateList, setRateList] = useState([])
    const {user}=useSelector((state)=> state.authReducer)
   const isEditAccess=Boolean(user?.role == userRoles.SUPER_ADMIN || user?.role == userRoles.ADMIN)
   const isFrontline = Boolean( user?.role == userRoles.FRONTLINE)
   const isSuperAdmin = Boolean( user?.role == userRoles.SUPER_ADMIN)
    
   console.log("singleShiftScheduleData",singleShiftScheduleData);
   
   

    const helperAsync = async (params) => {
        const res = await fetchRateListDropDown(params)
        return res.data.data

    }

    const compareIds = (arr1, arr2) => {
        const arr1Ids = arr1?.map(item => item.id);
        const arr2Ids = arr2?.map(item => item.id);
    
        const isJobOwner =  arr1Ids?.some(id => arr2Ids?.includes(id));
        storage.set('isInspectorEligible', isJobOwner)
        return isJobOwner
    };

    if (props.isLoad) {
        return (
            <>
                <PreviewShifScheduleSkeleton />
            </>
        );
    } else {

        /* This functionality for meal breaks Ascending order */
        if (singleShiftScheduleData && singleShiftScheduleData.breaks) {
            feedGap = singleShiftScheduleData?.breaks?.sort((a, b) => {
                return new Date(a.expected_start) - new Date(b.expected_start);
            })
        };

        const noAccountOptionsMessage = (a, b) => {
            return 'Job not found';
        }

        const accounts = [
            { value: 'test', label: 'Test' }
        ]

        return (
            <div>

                <main className="app-content add_account_modal add_frontline_pop">
                    <div className="appcontent_Inner user_newQuote pt-0">

                        <div className="py-5" style={{ maxWidth: '100%' }}>

                            <div className="row mb-4">
                                <div className="col-lg-8 mx-auto text-center" >
                                    <h1 className="mb-0" style={{ color: '#4a4a4a', fontWeight: '500', fontSize: '35px' }}> Shift Details </h1>
                                </div>
                                <div className="col-lg-4 mx-auto">
                                    
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="addQuote_form">

                                <div className="row">
                                    <div className="col-lg-8 mx-auto">
                                        <ul className="list-group    rounded" >

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Date </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.date ? tzDateConverterTool_V1(singleShiftScheduleData.date) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Shift Timings </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.shift && singleShiftScheduleData?.accounts ? `${tzTimeConversionTool_V1(singleShiftScheduleData.shift?.start_time, singleShiftScheduleData.date.split('T')[0])} - ${tzTimeConversionTool_V1(singleShiftScheduleData.shift?.end_time, singleShiftScheduleData.date.split('T')[0])}` : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center p-3">
                                                        <div className="media-body">
                                                            {singleShiftScheduleData?.shiftDetailJobs ? singleShiftScheduleData?.shiftDetailJobs.map((job, index, array) => {

                                                                let trade = singleShiftScheduleData?.accounts?.find((item) => item.id == job.account.id)?.address //job?.address;
                                                                return (
                                                                    <HoursTypeComponent job={job} trade={trade} index={index} array={array} initialValues={props?.initialValues} {...props} isFrontline={isFrontline}/>
                                                                );
                                                            }) : (
                                                                <h5 className="ml-lg-0 font-italic text-color-info mb-0 text-center" style={{ fontSize: '15px' }}>
                                                                    No jobs are available for this assignee.
                                                                </h5>
                                                            )}
                                                        </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center p-3">
                                                        <div className="media-body">
                                                            {singleShiftScheduleData?.complaints && singleShiftScheduleData?.complaints?.length ? singleShiftScheduleData?.complaints.map((exceptions, index, array) => {
                                                                return (
                                                                    <ExceptionComponent exceptions={exceptions} index={index} array={array} initialValues={props?.initialValues} {...props} isSuperAdmin={isSuperAdmin}/>
                                                                );
                                                            }) : (
                                                                <h5 className="ml-lg-0 font-italic text-color-info mb-0 text-center" style={{ fontSize: '15px' }}>
                                                                    None of the exceptions are available for this assignee.
                                                                </h5>
                                                            )}
                                                        </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Status </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.status ? makeFirstLetterCapital(singleShiftScheduleData.status) : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Notes </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.shift?.notes ? singleShiftScheduleData.shift.notes : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Assignee </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.assignedTo?.id ? `${singleShiftScheduleData.assignedTo?.first_name} ${singleShiftScheduleData.assignedTo?.last_name}` : '−'} </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div className="media-body order-2 order-lg-1">
                                                        <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked In Time </h5>
                                                    </div>
                                                    <h5 className="ml-lg-5 order-1 order-lg-2 text-muted">
                                                        {singleShiftScheduleData?.check_int_at ? tzDateConverterTool_V7(singleShiftScheduleData.check_int_at) : "−"}
                                                    </h5>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Meal Break Time </h5>
                                                        </div>
                                                        {
                                                            feedGap?.length ? 
                                                            <div className="media-body order-2 order-lg-1" style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {feedGap?.length > 0 ? feedGap.map((feed, index, array) => {
                                                                return (
                                                                    <>
                                                                        {feed.start && feed.end ? (
                                                                            <>
                                                                                <div style={{ borderLeft: '5px solid #cfcfcf', backgroundColor: '#f0f0f0' }}>
                                                                                    <h5 className="ml-lg-3 order-1 order-lg-2 text-muted mt-2 mb-1"> {getOrdinalNumber(index + 1)} meal </h5>
                                                                                    <h5 className="ml-lg-3 font-italic text-muted mb-2" style={{ fontSize: '15px' }}>
                                                                                        {`${feed?.start ? tzDateConverterTool_V7(feed.start) : null} − ${feed?.end ? tzDateConverterTool_V7(feed.end) : null}`}
                                                                                    </h5>
                                                                                </div>
                                                                                {index === array.length - 1 ? null : <br />}
                                                                            </>
                                                                        ) : <h5 className="ml-lg-5 order-1 order-lg-2 text-muted " style={{textAlign:"right"}}>–</h5> }
                                                                    </>
                                                                );

                                                            }) : (<h5 className="ml-lg-0 font-italic text-color-info mb-0" style={{ fontSize: '15px' }}> No Meal Break </h5>)}
                                                        </div> :(<h5 className="ml-lg-0 font-italic text-color-info mb-0" style={{ fontSize: '15px' }}> No Meal Break </h5>)

                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body order-2 order-lg-1" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Clocked Out Time </h5>
                                                        </div>
                                                        <h5 className="ml-lg-5 order-1 order-lg-2 text-muted">
                                                            {singleShiftScheduleData?.check_out_at ? tzDateConverterTool_V7(singleShiftScheduleData.check_out_at) : "−"}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </li>

                                            {/* each Job Worked Hours details */}

                                            {
                                                singleShiftScheduleData?.shiftDetailJobs?.map((item) => (
                                                    <li className="list-group-item">
                                                        <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                                <div className="media-body  order-2 order-lg-1">
                                                                    <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Worked Hours in {item?.account?.name} </h5>
                                                                </div>
                                                                <h5 className="ml-lg-5 order-1 order-lg-2 text-muted">
                                                                    {item?.total_minutes ?  calculateHoursFromMinutes_V1(item.total_minutes) :  '–'}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }

                                            {/* end each Job Worked Hours details */}

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Total Worked Hours </h5>
                                                        </div>
                                                        <h5 className="ml-lg-5 order-1 order-lg-2 text-muted">
                                                            {singleShiftScheduleData?.total_time ? calculateHoursFromMinutes_V1(singleShiftScheduleData.total_time): '−'}

                                                        </h5>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="list-group-item">
                                                <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                        <div className="media-body order-2 order-lg-1">
                                                            <h5 className="mt-0 font-weight-bold mb-2 text-dark"> Worked Tickets </h5>
                                                        </div>
                                                        {
                                                            singleShiftScheduleData?.tickets?.length ? <div  style={{display:"flex",flexDirection:"row",flexWrap:"wrap",width:"12rem",lineHeight:"2rem", alignContent:"flex-end",maxWidth:"12rem", gap:".5rem",justifyContent:"flex-end"}} className="order-2 order-lg-1">
                                                            {
                                                                singleShiftScheduleData && singleShiftScheduleData?.tickets && singleShiftScheduleData?.tickets?.length ? singleShiftScheduleData.tickets.map((ticket, index) => (
                                                                   <Fragment>
                                                                    <a  href="#" onClick={() => props.history.push(`/schedules/tickets/preview-ticket/${ticket?.id}`)} key={index} className="ml-lg-0 order-1 order-lg-2  " style={{ color: "blue" }}>
                                                                        {ticket?.id ? ticket.id : "−"}
                                                                    </a>
                                                                    </Fragment>
                                                                   
                                                                    )) : null
                                                            }
                                                        
                                                          </div>   :                                                     
                                                                <h5 className="ml-lg-5 order-1 order-lg-2 text-muted">
                                                                –
                                                            </h5>
                                                        }
                                                    </div>
                                                  {/* <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> − </h5> */}
                                                </div>
                                            </li>

                                        </ul>
                                        <div className="modal-footer">

                                   {
                                      isEditAccess || (user?.role == userRoles.INSPECTOR && compareIds(singleShiftScheduleData.accounts, props.isJobOwner)) ? <button  data-test="" type="submit" className={props.isLoadingSubmit ? "btn cst_btn pegdesk_button w-25 btn-wait-adjust" : "btn cst_btn pegdesk_button w-25"}  >
                                      {
                                          props.isLoadingSubmit ? "" : "Update"
                                      }

                                  </button> : null
                                   } 

                                    <button data-test="" type="button" className="btn cst_btn bnt-danger w-25" style={{ fontWeight: '700' }} onClick={() => { props.onCancelClicked() }} >
                                        Cancel
                                    </button>

                                </div>
                                    </div>
                                    
                                    {
                                        filterdFaceLogs && filterdFaceLogs.length ? 
                                        <div className='col-lg-4' >
                                            <ul className="list-group " >

                                                {/* {
                                                    props.is_facial ? 
                                                    <Fragment>
                                                    <li className="list-group-item" >
                                                    <div className="media align-items-lg-center flex-column flex-lg-row ">
                                                        <div className="media-body ">
                                                            <p className="mt-0 font-weight-bold mb-2 text-dark"> {} </p>
                                                        </div>
                                                        <img style={{
                                                            width:"50px",
                                                            height:"50px",
                                                            borderRadius: "50%",
                                                            overflow:"hidden",
                                                            objectFit:"cover",
                                                            display:"block"

                                                        }} src={""} alt='user Image ' />
                                                        
                                                    </div>
                                                    <div>
                                                        <p><span style={{fontWeight:"bold"}}>Flag:</span> {true == "Success" ? "Successful facial recognition" : "Failed facial recognition"} </p>
                                                    </div>
                                                </li>
                                                </Fragment> : null
                                                } */}

                                                {
                                                    filterdFaceLogs && filterdFaceLogs.length ?  filterdFaceLogs.map((faceDetails,index)=>{
                                                        return(
                                                <li className="list-group-item" >
                                                    <div className="media align-items-lg-center flex-column flex-lg-row ">
                                                        <div className="media-body ">
                                                            <p className="mt-0 font-weight-bold mb-2 text-dark"> {FacialImageClockInFlowConstants[`${faceDetails.shift_action}`]} </p>
                                                        </div>
                                                        <img style={{
                                                            width:"50px",
                                                            height:"50px",
                                                            borderRadius: "50%",
                                                            overflow:"hidden",
                                                            objectFit:"cover",
                                                            display:"block"

                                                        }} src={faceDetails?.photo_urls?.small} alt='user Image ' />
                                                        {/* <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.date ? tzDateConverterTool_V1(singleShiftScheduleData.date) : '−'} </h5> */}
                                                    </div>
                                                    <div>
                                                        <p><span style={{fontWeight:"bold"}}>Flag:</span> {faceDetails?.status == "Success" ? "Successful facial recognition" : "Failed facial recognition"} </p>
                                                    </div>
                                                </li>
                                                        )
                                                    }) : null
                                                }

                        
                                            </ul>
                                    </div> : 
                                            <div className='col-lg-4' >

                                            <li className="list-group-item" >
                                            <div className="media align-items-lg-center flex-column flex-lg-row " style={{padding:"1rem 0rem",alignItems:"center"}}>
                                                <div className="media-body text-center">
                                                    <p className="mt-0 font-weight-bold mb-2 text-dark"> NO FACIAL LOGS </p>
                                                </div>

                                                {/* <h5 className="ml-lg-5 order-1 order-lg-2 text-muted"> {singleShiftScheduleData?.date ? tzDateConverterTool_V1(singleShiftScheduleData.date) : '−'} </h5> */}
                                            </div>

                                            </li>
                                            </div >
                                    }
                                    
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </main>
      
            </div>
        );
    }

};

const mapStateToProps = (state) => {
    const findStatus = (reason) => {
       const value =  status.find((item) => item.value === reason)
       if(value){
        return value
       }else{
        return ''
       }
       
    }
    return {
        initialValues: {
            shiftDetailJobs: {
                jobs : state.TimeTrackerModReducer.singleShiftScheduleData?.shiftDetailJobs && state.TimeTrackerModReducer.singleShiftScheduleData?.shiftDetailJobs.map((item) => ({
                    ...item, customer_pay: state?.TimeTrackerModReducer?.singleShiftScheduleData?.check_out_at ? item.customer_pay?.toString() : null, "rate": {
                        value: item?.rate?.description,
                        label: item?.rate?.description && item?.rate?.type_id ? `${item.rate.type_id} - ${item?.rate?.description}` :"",
                        id: "",
                    }
                })),
                exceptions : state.TimeTrackerModReducer.singleShiftScheduleData &&  state.TimeTrackerModReducer.singleShiftScheduleData.complaints ?  state.TimeTrackerModReducer.singleShiftScheduleData?.complaints.map((item) => ({
                    description: item.description ? item.description : '', 
                     "status": item.status ? findStatus(item.status) : '',
                    id : item.id
                })) : [],
            } 
        }, formStates: getFormValues('newShiftDetailForm')(state)
    }
}

PreviewShifSchedule = reduxForm({
    form: 'newShiftDetailForm',
    validate:newPreviewShiftValidator,
    enableReinitialize: true,
    
})(PreviewShifSchedule);

PreviewShifSchedule = connect(mapStateToProps)(PreviewShifSchedule)

export default PreviewShifSchedule;