import { TimeTrackerModTypes } from "../../actions/TimeTrackerModAction/TimeTrackerModTypes";
import storage from "../../../utility/storage";
import SuccessAlert from "../../../utility/successAlert/successAlert";
import { FaSadCry } from "react-icons/fa";
const cloneDeep = require("clone-deep");

export const initialState = {

    isLoading: false,
    dataGenerated: false,
    setStaticTrigger: false,

    SearchOrResetLeavesListClicked: false,

    SearchOrResetScheduledTaskListClicked: false,
    SearchOrResetSubScheduledTaskListClicked: false,
    SearchOrResetShiftScheduleListClicked: false,
    SearchOrResetShiftScheduleDetailListClicked: false,
    SearchOrResetShiftListClicked: false,
    SearchOrResetOverrideAvailabilityRequestListClicked: false,
    SearchOrResetAvailabilityListClicked: false,
    SearchOrResetSTMyticketsListClicked: false,
    SearchOrResetTimecardReviewedListClicked: false,
    SearchOrResetTimeSheetListClicked: false,

    openFrequentTimeTracker_deleteModal: false,
    deleteFrequentTimeTracker_object: {},

    isTimeTrackerModalOpen: false,
    attendancePanelType: null,
    attendancePanelObj: {},

    usersListBySelectedRole: null,
    jobAccountSitesList: null,
    leaveUpdate_managersList: null,

    isSkillSet: false,
    isSkillSetEdit: false,
    total_skillSet_list: '',
    skillSet: [],
    skillSet_list: [],

    isDepartment: false,
    isDepartmentEdit: false,
    total_department_list: '',
    department: [],
    department_list: [],

    isLeavesList: false,
    leavesList: [],
    leavesList_total_pages: 1,
    leavesList_no_of_rows: 10,
    total_leavesList_count: '',
    leavesList_curPage: 0,
    leavesList_searchValues: {},
    singleLeaveRequestData: {},
    isEditLeaveRequestForm: false,
    isUpdateLeaveRequestForm: false,

    scheduleTaskTitle: '',
    scheduleTaskId: '',

    isScheduledTaskList: false,
    scheduledTaskList: [],
    scheduledTaskListCurPage: 0,
    scheduledTaskList_total_pages: 1,
    scheduledTaskList_no_of_rows: 10,
    total_scheduledTaskList_count: '',
    scheduledTask_searchValues: {},

    issubScheduledTaskList: false,
    subScheduledTaskList: [],
    subScheduledTaskListCurPage: 0,
    subScheduledTaskList_total_pages: 1,
    subScheduledTaskList_no_of_rows: 10,
    total_subScheduledTaskList_count: '',
    subScheduledTask_searchValues: {},
    ticket: {},
    company_list: [],
    job_list: [],

    getScheduledTaskEventsList: [],

    getSingleUserAvailabilityPlannerList: [],
    getSingleUserOverrideAvailabilityPlannerList: [],
    getSingleUserLeavesAvailabilityPlannerList: [],

    isShiftScheduleList: false,
    shiftScheduleList: [],
    shiftScheduleListCurPage: 0,
    shiftScheduleList_total_pages: 1,
    shiftScheduleList_no_of_rows: 10,
    total_shiftScheduleList_count: '',
    shiftSchedule_searchValues: {},

    isShiftScheduleDetailList: false,
    shiftScheduleDetailList: [],
    shiftScheduleDetailListCurPage: 0,
    shiftScheduleDetailList_total_pages: 1,
    shiftScheduleDetailList_no_of_rows: 10,
    total_shiftScheduleDetailList_count: '',
    shiftScheduleDetail_searchValues: {},

    getSingleUserShiftPlannerList: [],
    getShiftPlannerScheduledData: [],
    getShiftDetailOfSingleFLUser: {},

    isEditFLShiftForm: false,
    isUpdateFLShiftForm: false,

    singleShiftScheduleData: {},

    isShiftList: false,
    shiftList: [],
    shiftListCurPage: 0,
    shiftList_total_pages: 1,
    shiftList_no_of_rows: 10,
    total_shiftList_count: '',
    shift_searchValues: {},

    isSwapList: false,
    swapList: [],
    swapListCurPage: 0,
    swapList_total_pages: 1,
    swapList_no_of_rows: 10,
    total_swapList_count: '',
    swapList_searchValues: {},

    singleDayGeneralShiftInfo: [],
    singleDayElaboratedShiftInfo: {},

    isCreateSwap: false,

    specificShiftSwapInfo: {},

    isOverrideAvailabilityRequestList: false,
    overrideAvailabilityRequestList: [],
    total_overrideAvailabilityRequestList_count: '',
    overrideAvailabilityRequestList_total_pages: 1,
    overrideAvailabilityRequestList_no_of_rows: 10,
    overrideAvailabilityRequestList_curPage: 0,
    overrideAvailabilityRequestList_searchValues: {},

    isAvailabilityList: false,
    availabilityList: [],
    availabilityListCurPage: 0,
    availabilityList_total_pages: 1,
    availabilityList_no_of_rows: 10,
    total_availabilityList_count: '',
    availabilityList_searchValues: {},

    isClockInShiftAvailable: false,
    isClockInShiftExcuse: false,
    clockedInShiftData: null,
    clockInCheckedInTime: null,

    isShiftMealBreakTriggered: false,
    shiftMealBreakInfo: null,
    scheduledTask_assignedTicketInfo: [],

    isSTMyTicketsList: false,
    stMyTicketsList: [],
    stMyTicketsList_total_pages: 1,
    stMyTicketsList_no_of_rows: 10,
    stMyTicketsList_curPage: 0,
    stMyTicketsList_total_tickets: 0,
    stMyTicketsList_searchValues: {},

    isTimecardReviewedList: false,
    timecardReviewedList: [],
    timecardReviewedList_total_pages: 1,
    timecardReviewedList_no_of_rows: 10,
    timecardReviewedList_curPage: 0,
    timecardReviewedList_total_tickets: 0,
    timecardReviewedList_searchValues: {},

    isMealPenaltyLoader: false,
    singleTimecardCorrectData: {},

    isTimeSheetList: false,
    timeSheetList: [],
    timeSheetList_total_pages: 1,
    timeSheetList_no_of_rows: 10,
    timeSheetList_curPage: 0,
    timeSheetList_total_tickets: 0,
    timeSheetList_searchValues: {},
    timeSheetList_timecard: [],

    getShiftListByparams: [],

    shiftAttendance_presents: [],
    shiftAttendance_absents: [],
    shiftAttendance_complaints: [],

    mealPenaltyBreaksInfo: [],
    isNextJobLoading: false,
    triggerClockInTimer : false,
    payrollHollidayList:[],
    isLoadingHollyday:false,
    isLoadingList:false,
    total_no_of_pages:1,
    total_count:0,
    //Hours Types intial state .
    isTimeTrackerHoursTypeModalOpen: false,
    isLoadingHours: false,
    isLoadingHoursLits:false,
    openHoursTimeTracker_deleteModal: false,
    deleteFrequentHoursType:{},
    isHoursTypeLoad: false,
    isHoursType_List: [],
    isHoursType_Edit: [],
    total_hoursTypetotal_list: '',
    isHourTypeSetEdit: false,
    isHoursType: false,
    total_isHourType_list:'',

    hoursTypeImportErrorLogs : [],

    isLoadingSubmit:false,
    accountHoursType:[],

    isPCTLoading : false,
    PCTjobsList : [],
    PCTFloorList : [],
    PCTDepartmentList : [],
    PCTPhoneList : [],
};

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps,
    };
};

export const TimeTrackerModReducer = (state = initialState, action) => {
    switch (action.type) {

        case TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.UPLOAD_IMAGE_TO_S3_FULFILLED:
            return updateObject(state, { isLoading: true });

        // FOR FACE RECOGNITION
        case TimeTrackerModTypes.GET_SCANNED_FACE_IMAGE_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.GET_SCANNED_FACE_IMAGE_PRESIGNED_URL_FULFILLED:
            return updateObject(state, { isLoading: true })

        // FOR FACE RECOGNITION
        case TimeTrackerModTypes.GET_RECOGNIZED_FACE_ACCOUNT_CREDS_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.GET_RECOGNIZED_FACE_ACCOUNT_CREDS_FULFILLED:
            return updateObject(state, { isLoading: false })

        case TimeTrackerModTypes.RESET_TIME_TRACKER_OBJECTS:
            return updateObject(state, {
                singleLeaveRequestData: {},
                isEditLeaveRequestForm: false,
                getShiftDetailOfSingleFLUser: {},
                isEditFLShiftForm: false,
                singleShiftScheduleData: {},
                usersListBySelectedRole: null,
                singleDayGeneralShiftInfo: [],
                singleDayElaboratedShiftInfo: {},
                specificShiftSwapInfo: {},
                singleShiftScheduleData: {},
                singleTimecardCorrectData: {},
            });

        case TimeTrackerModTypes.RESET_SEARCH_OR_RESET:
            return updateObject(state, {
                SearchOrResetLeavesListClicked: false,
                SearchOrResetOverrideAvailabilityRequestListClicked: false,
            });

        case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_OPEN_DELETE_MODAL:
            return updateObject(state, {
                openFrequentTimeTracker_deleteModal: true,
                deleteFrequentTimeTracker_object: action.payload,
            });
        case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_CLOSE_DELETE_MODAL:
            return updateObject(state, { openFrequentTimeTracker_deleteModal: false });

        case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_OPEN_MODAL:
            return updateObject(state, { isTimeTrackerModalOpen: true })
        case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_CLOSE_MODAL:
            return updateObject(state, {
                isTimeTrackerModalOpen: false,
                skillSet: [],
                isSkillSetEdit: false,
                department: [],
                isDepartmentEdit: false,
            })

        case TimeTrackerModTypes.HANDLE_CANCEL_CLICK_ACTION:
            return updateObject(state, {
                singleLeaveRequestData: {},
                getShiftDetailOfSingleFLUser: {},
                isEditFLShiftForm: false,
                isUpdateFLShiftForm: false,
                singleShiftScheduleData: {},
                usersListBySelectedRole: null,
                singleDayGeneralShiftInfo: [],
                singleDayElaboratedShiftInfo: {},
                specificShiftSwapInfo: {},
            });

        case TimeTrackerModTypes.HANDLE_STATIC_TRIGGER_CLICK:
            return updateObject(state, {
                setStaticTrigger: true
            });

        case TimeTrackerModTypes.HANDLE_SHIFT_PRESENCE_PANEL_OPEN_MODAL:
            return updateObject(state, {
                isTimeTrackerModalOpen: true,
                attendancePanelType: action?.payload?.type,
                attendancePanelObj: action?.payload?.attendanceItem,
            })
        case TimeTrackerModTypes.HANDLE_SHIFT_PRESENCE_PANEL_CLOSE_MODAL:
            return updateObject(state, {
                isTimeTrackerModalOpen: false,
                singleShiftScheduleData: {},
                attendancePanelType: null,
                attendancePanelObj: {},
            })

        case TimeTrackerModTypes.GET_USERS_LIST_BY_THEIR_ROLES_PENDING:
            return updateObject(state, { jobAccountSitesList: null });
        case TimeTrackerModTypes.GET_USERS_LIST_BY_THEIR_ROLES_FULFILLED:
            return updateObject(state, { usersListBySelectedRole: action.payload.usersListBySelectedRole });

        case TimeTrackerModTypes.GET_JOB_ACCOUNT_SITES_LIST_PENDING:
            return updateObject(state, { jobAccountSitesList: null });
        case TimeTrackerModTypes.GET_JOB_ACCOUNT_SITES_LIST_FULFILLED:
            return updateObject(state, { jobAccountSitesList: action.payload.jobAccountSitesList });

        case TimeTrackerModTypes.GET_LEAVEUPDATE_MANAGERS_LIST_PENDING:
            return updateObject(state, { leaveUpdate_managersList: null });
        case TimeTrackerModTypes.GET_LEAVEUPDATE_MANAGERS_LIST_FULFILLED:
            return updateObject(state, { leaveUpdate_managersList: action.payload.leaveUpdate_managersList });

        case TimeTrackerModTypes.GET_SKILLSET_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isSkillSet: true
            })
        case TimeTrackerModTypes.GET_SKILLSET_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isSkillSet: false,
                skillSet_list: action.payload ? action.payload.data : [],
                total_skillSet_list: action.payload && action.payload.data ? action.payload.data.length : '',
            })

        case TimeTrackerModTypes.CREATE_SKILLSET_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.CREATE_SKILLSET_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTimeTrackerModalOpen: false,
            })

        case TimeTrackerModTypes.EDIT_SKILLSET_CLICKED:
            return updateObject(state, {
                skillSet: action.payload,
                isTimeTrackerModalOpen: true,
                isSkillSetEdit: true,
            })

        case TimeTrackerModTypes.EDIT_SKILLSET_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.EDIT_SKILLSET_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                skillSet: [],
                isTimeTrackerModalOpen: false,
            })

        case TimeTrackerModTypes.DELETE_SKILLSET_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.DELETE_SKILLSET_FULFILLED:
            if (action && action.payload && state && state.skillSet_list) {
                let findDeletedSkillset = state && state.skillSet_list && state.skillSet_list.filter((data) => {
                    return data.id == action.payload.data
                })
                SuccessAlert(`${findDeletedSkillset[0].name} skillset deleted successfully`);
            };
            return updateObject(state, {
                isLoading: false,
                openFrequentTimeTracker_deleteModal: false
            })

        case TimeTrackerModTypes.GET_DEPARTMENT_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isDepartment: true
            })
        case TimeTrackerModTypes.GET_DEPARTMENT_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isDepartment: false,
                department_list: action.payload ? action.payload.data : [],
                total_department_list: action.payload && action.payload.data ? action.payload.data.length : '',
            })

        case TimeTrackerModTypes.CREATE_DEPARTMENT_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.CREATE_DEPARTMENT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTimeTrackerModalOpen: false,
            })

        case TimeTrackerModTypes.EDIT_DEPARTMENT_CLICKED:
            return updateObject(state, {
                department: action.payload,
                isTimeTrackerModalOpen: true,
                isDepartmentEdit: true,
            })

        case TimeTrackerModTypes.EDIT_DEPARTMENT_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.EDIT_DEPARTMENT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                department: [],
                isTimeTrackerModalOpen: false,
            })

        case TimeTrackerModTypes.DELETE_DEPARTMENT_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.DELETE_DEPARTMENT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                openFrequentTimeTracker_deleteModal: false
            })

        case TimeTrackerModTypes.GET_ALL_LEAVE_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isLeavesList: true,
                leavesList: [],
            });
        case TimeTrackerModTypes.GET_ALL_LEAVE_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isLeavesList: false,
                leavesList: action && action.payload && action.payload.leavesList,
                leavesList_total_pages: action && action.payload && action.payload.leavesList_total_pages,
                leavesList_no_of_rows: action && action.payload && action.payload.leavesList_no_of_rows,
                total_leavesList_count: action && action.payload && action.payload.total_leavesList_count,
                leavesList_curPage: action && action.payload && action.payload.leavesList_curPage,
                leavesList_searchValues: action && action.payload && action.payload.searchValues,
            });

        case TimeTrackerModTypes.GET_SINGLE_LEAVE_REQUEST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditLeaveRequestForm: false,
                isUpdateLeaveRequestForm: true,
            });
        case TimeTrackerModTypes.GET_SINGLE_LEAVE_REQUEST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditLeaveRequestForm: true,
                isUpdateLeaveRequestForm: false,
                singleLeaveRequestData: action.payload ? action.payload.data : {}
            });

        case TimeTrackerModTypes.LEAVE_LIST_PAGE_CLICKED:
            return updateObject(state, { leavesList_curPage: action.payload });

        case TimeTrackerModTypes.DELETE_LEAVE_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.DELETE_LEAVE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                openFrequentTimeTracker_deleteModal: false
            })

        case TimeTrackerModTypes.GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GET_CREATE_LEAVE_ATTACHMENT_PRESIGN_URL_FULFILLED:
            return updateObject(state, { isLoading: true });

        case TimeTrackerModTypes.CREATE_FRONTLINER_LEAVE_REQUEST_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.CREATE_FRONTLINER_LEAVE_REQUEST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                setStaticTrigger: false,
                isEditLeaveRequestForm: false,
            });

        case TimeTrackerModTypes.GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GET_EDIT_LEAVE_ATTACHMENT_PRESIGN_URL_FULFILLED:
            return updateObject(state, { isLoading: true });

        case TimeTrackerModTypes.EDIT_FRONTLINER_LEAVE_REQUEST_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.EDIT_FRONTLINER_LEAVE_REQUEST_FULFILLED:
            if(action.payload.data.success){
                return updateObject(state, {
                    isLoading: false,
                    setStaticTrigger: false,
                    isEditLeaveRequestForm: false,
                    singleLeaveRequestData: {},
                });
            }else {
                return updateObject(state, {
                    isLoading: false,
                });
            }

        case TimeTrackerModTypes.GET_CLOSE_LEAVE_REQUEST_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GET_CLOSE_LEAVE_REQUEST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                singleLeaveRequestData: {},
            });

        case TimeTrackerModTypes.PREVIEW_SINGLE_SCHEDULED_TASK:
            return updateObject(state, { scheduleTaskTitle: action.payload.title, scheduleTaskId: action.payload.id })

        case TimeTrackerModTypes.GET_ALL_SCHEDULED_TASK_PENDING:
            return updateObject(state, {
                isLoading: true,
                isScheduledTaskList: true,
                scheduledTaskList: [],
            });
        case TimeTrackerModTypes.GET_ALL_SCHEDULED_TASK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isScheduledTaskList: false,
                scheduledTaskList: action.payload.scheduledTaskList,
                scheduledTaskList_total_pages: action.payload.scheduledTaskList_total_pages,
                scheduledTaskList_no_of_rows: action.payload.scheduledTaskList_no_of_rows,
                scheduledTaskListCurPage: action.payload.scheduledTaskList_curPage,
                total_scheduledTaskList_count: action.payload.total_scheduledTaskList_count,
                scheduledTask_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.SCHEDULED_TASK_LIST_PAGE_CLICKED:
            return updateObject(state, { scheduledTaskListCurPage: action.payload });

        case TimeTrackerModTypes.GET_ALL_SUB_SCHEDULED_TASK_PENDING:
            return updateObject(state, {
                isLoading: true,
                isSubScheduledTaskList: true,
                subScheduledTaskList: [],
            });
        case TimeTrackerModTypes.GET_ALL_SUB_SCHEDULED_TASK_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isSubScheduledTaskList: false,
                subScheduledTaskList: action.payload.subScheduledTaskList,
                subScheduledTaskList_total_pages: action.payload.subScheduledTaskList_total_pages,
                subScheduledTaskList_no_of_rows: action.payload.subScheduledTaskList_no_of_rows,
                subScheduledTaskListCurPage: action.payload.subScheduledTaskList_curPage,
                total_subScheduledTaskList_count: action.payload.total_subScheduledTaskList_count,
                subScheduledTask_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.SUB_SCHEDULED_TASK_LIST_PAGE_CLICKED:
            return updateObject(state, { subScheduledTaskListCurPage: action.payload });

        case TimeTrackerModTypes.GET_PREVIEW_SUB_SCHEDULED_TASK_PENDING:
            return updateObject(state, { isLoading: true, ticket: {} })
        case TimeTrackerModTypes.GET_PREVIEW_SUB_SCHEDULED_TASK_FULFILLED:

            return updateObject(state, { isLoading: false, ticket: action.payload })

        case TimeTrackerModTypes.GET_COMPANY_LIST_PENDING:
            return updateObject(state, {
                company_list: []
            })
        case TimeTrackerModTypes.GET_COMPANY_LIST_FULFILLED:
            return updateObject(state, {
                company_list: action.payload
            })

        case TimeTrackerModTypes.NEW_SCHEDULED_TASK_TICKET_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.NEW_SCHEDULED_TASK_TICKET_REJECTED:
            return updateObject(state, { isLoading: false });
        case TimeTrackerModTypes.NEW_SCHEDULED_TASK_TICKET_FULFILLED:
            return updateObject(state, { isLoading: false });

        case TimeTrackerModTypes.GET_JOB_LIST_PENDING:
            return updateObject(state, {
                job_list: []
            })
        case TimeTrackerModTypes.GET_JOB_LIST_FULFILLED:
            return updateObject(state, {
                job_list: action.payload
            })

        case TimeTrackerModTypes.GET_SCHEDULED_TASK_EVENTS_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.GET_SCHEDULED_TASK_EVENTS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                getScheduledTaskEventsList: action.payload.data
            })

        case TimeTrackerModTypes.ADD_COMMENT_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.ADD_COMMENT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                ticket: action.payload.updatedTicket
            });

        case TimeTrackerModTypes.UPDATE_COMMENT_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.UPDATE_COMMENT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                ticket: action.payload.updatedTicket
            });

        case TimeTrackerModTypes.COMMENT_PRESIGN_URL_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.COMMENT_PRESIGN_URL_FULFILLED:
            return updateObject(state, { isLoading: true });

        case TimeTrackerModTypes.GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.GET_AVAILABILITY_CALENDAR_SCHEDULED_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                getSingleUserAvailabilityPlannerList: action && action.payload && action.payload.data && action.payload.data.availability && action.payload.data.availability.length > 0 ? action.payload.data.availability : [],
                getSingleUserLeavesAvailabilityPlannerList: action && action.payload && action.payload.data && action.payload.data.leaves && action.payload.data.leaves.length > 0 ? action.payload.data.leaves : [],
                getSingleUserOverrideAvailabilityPlannerList: action && action.payload && action.payload.data && action.payload.data.overwriteAvailability && action.payload.data.overwriteAvailability.length > 0 ? action.payload.data.overwriteAvailability : [],
            })

        case TimeTrackerModTypes.ADD_AVAILABILITY_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.ADD_AVAILABILITY_REJECTED:
            return updateObject(state, { isLoading: false });
        case TimeTrackerModTypes.ADD_AVAILABILITY_FULFILLED:
            return updateObject(state, { isLoading: false });

        case TimeTrackerModTypes.OVERRIDE_AVAILABILITY_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.OVERRIDE_AVAILABILITY_REJECTED:
            return updateObject(state, { isLoading: false });
        case TimeTrackerModTypes.OVERRIDE_AVAILABILITY_FULFILLED:
            return updateObject(state, { isLoading: false });

        case TimeTrackerModTypes.GET_ALL_SHIFT_SCHEDULE_PENDING:
            return updateObject(state, {
                isLoading: true,
                isShiftScheduleList: true,
                shiftScheduleList: [],
            });
        case TimeTrackerModTypes.GET_ALL_SHIFT_SCHEDULE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isShiftScheduleList: false,
                shiftScheduleList: action.payload.shiftSchedule,
                shiftScheduleList_total_pages: action.payload.shiftSchedule_total_pages,
                shiftScheduleList_no_of_rows: action.payload.shiftSchedule_no_of_rows,
                shiftScheduleListCurPage: action.payload.shiftSchedule_curPage,
                total_shiftScheduleList_count: action.payload.total_shiftSchedule_count,
                shiftSchedule_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.SHIFT_SCHEDULE_PAGE_CLICKED:
            return updateObject(state, { shiftScheduleListCurPage: action.payload });

        case TimeTrackerModTypes.PREVIEW_SINGLE_SHIFT_SCHEDULE:
            return updateObject(state, {})

        case TimeTrackerModTypes.GET_ALL_SHIFT_SCHEDULE_DETAIL_PENDING:
            return updateObject(state, {
                isLoading: true,
                isShiftScheduleDetailList: true,
                shiftScheduleDetailList: [],
            });
        case TimeTrackerModTypes.GET_ALL_SHIFT_SCHEDULE_DETAIL_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isShiftScheduleDetailList: false,
                shiftScheduleDetailList: action.payload.shiftSchedule,
                shiftScheduleDetailList_total_pages: action.payload.shiftSchedule_total_pages,
                shiftScheduleDetailList_no_of_rows: action.payload.shiftSchedule_no_of_rows,
                shiftScheduleDetailListCurPage: action.payload.shiftSchedule_curPage,
                total_shiftScheduleDetailList_count: action.payload.total_shiftSchedule_count,
                shiftScheduleDetail_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.SHIFT_SCHEDULE_DETAIL_PAGE_CLICKED:
            return updateObject(state, { shiftScheduleDetailListCurPage: action.payload });

        case TimeTrackerModTypes.GET_SHIFT_CALENDAR_SCHEDULED_LIST_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.GET_SHIFT_CALENDAR_SCHEDULED_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                getSingleUserShiftPlannerList: action && action.payload && action.payload.data ? action.payload.data : [],
                // getShiftPlannerScheduledData: action.payload.data,
            })

        case TimeTrackerModTypes.GET_SINGLE_SHIFT_SCHEDULE_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditFLShiftForm: false,
                isUpdateFLShiftForm: true,
            });
        case TimeTrackerModTypes.GET_SINGLE_SHIFT_SCHEDULE_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditFLShiftForm: true,
                isUpdateFLShiftForm: false,
                singleShiftScheduleData: action.payload ? action.payload.data : {}
            });

        case TimeTrackerModTypes.GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER_PENDING:
            return updateObject(state, {
                isLoading: true,
                isEditFLShiftForm: false,
                isUpdateFLShiftForm: true,
            })
        case TimeTrackerModTypes.GET_SHIFT_DETAIL_FOR_SINGLE_FLUSER_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditFLShiftForm: true,
                isUpdateFLShiftForm: false,
                getShiftDetailOfSingleFLUser: action && action.payload && action.payload.data,
            })

        case TimeTrackerModTypes.CHECK_FRONTLINER_SHIFT_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.CHECK_FRONTLINER_SHIFT_FULFILLED:
            return updateObject(state, { isLoading: false });

        case TimeTrackerModTypes.CREATE_FRONTLINER_SHIFT_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.CREATE_FRONTLINER_SHIFT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditFLShiftForm: false,
            });

        case TimeTrackerModTypes.EDIT_FRONTLINER_SHIFT_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.EDIT_FRONTLINER_SHIFT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditFLShiftForm: false,
                getShiftDetailOfSingleFLUser: {},
            });

        case TimeTrackerModTypes.EDIT_PERDAY_FLUSER_SHIFT_DETAIL_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.EDIT_PERDAY_FLUSER_SHIFT_DETAIL_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isEditFLShiftForm: false,
                singleShiftScheduleData: {},
            });

        case TimeTrackerModTypes.DELETE_SHIFT_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.DELETE_SHIFT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                openFrequentTimeTracker_deleteModal: false
            })

        case TimeTrackerModTypes.GET_ALL_SHIFT_PENDING:
            return updateObject(state, {
                isLoading: true,
                isShiftList: true,
                shiftList: [],
            });
        case TimeTrackerModTypes.GET_ALL_SHIFT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isShiftList: false,
                shiftList: action.payload.shift,
                shiftList_total_pages: action.payload.shift_total_pages,
                shiftList_no_of_rows: action.payload.shift_no_of_rows,
                shiftListCurPage: action.payload.shift_curPage,
                total_shiftList_count: action.payload.total_shift_count,
                shift_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.GRANT_CANCEL_SHIFT_REQUEST_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GRANT_CANCEL_SHIFT_REQUEST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTimeTrackerModalOpen: false,
            });

        case TimeTrackerModTypes.GET_ALL_SWAP_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isSwapList: true,
                swapList: [],
            });
        case TimeTrackerModTypes.GET_ALL_SWAP_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isSwapList: false,
                swapList: action.payload.swapList,
                swapList_total_pages: action.payload.swapList_total_pages,
                swapList_no_of_rows: action.payload.swapList_no_of_rows,
                swapListCurPage: action.payload.swapList_curPage,
                total_swapList_count: action.payload.total_swapList_count,
                swapList_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO_PENDING:
            return updateObject(state, {
                isLoading: true,
            })
        case TimeTrackerModTypes.GET_PERDAY_FLUSER_GENERAL_SHIFT_INFO_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                singleDayGeneralShiftInfo: action && action.payload && action.payload.data,
            })

        case TimeTrackerModTypes.GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.GET_PERDAY_FLUSER_ELABORATED_SHIFT_INFO_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                singleDayElaboratedShiftInfo: action.payload.singleDayElaboratedShiftInfo,
            });

        case TimeTrackerModTypes.CREATE_FLUSER_SHIFT_SWAP_PENDING:
            return updateObject(state, {
                isLoading: true,
                isCreateSwap: true,
            });
        case TimeTrackerModTypes.CREATE_FLUSER_SHIFT_SWAP_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isCreateSwap: false,
            });

        case TimeTrackerModTypes.GET_SPECIFIC_SHIFT_SWAP_INFO_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.GET_SPECIFIC_SHIFT_SWAP_INFO_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                specificShiftSwapInfo: action.payload.specificShiftSwapInfo,
            });

        case TimeTrackerModTypes.GRANT_SWAP_REQUEST_REVIEW_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GRANT_SWAP_REQUEST_REVIEW_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                specificShiftSwapInfo: {},
                isTimeTrackerModalOpen: false,
            });

        case TimeTrackerModTypes.SWAP_LIST_PAGE_CLICKED:
            return updateObject(state, { swapListCurPage: action.payload });

        case TimeTrackerModTypes.GET_OVERRIDE_AVAILABILITY_REQUEST_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isOverrideAvailabilityRequestList: true,
                overrideAvailabilityRequestList: [],
            });
        case TimeTrackerModTypes.GET_OVERRIDE_AVAILABILITY_REQUEST_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isOverrideAvailabilityRequestList: false,
                overrideAvailabilityRequestList: action.payload.overrideAvailabilityRequestList,
                total_overrideAvailabilityRequestList_count: action.payload.total_overrideAvailabilityRequestList_count,
                overrideAvailabilityRequestList_total_pages: action.payload.overrideAvailabilityRequestList_total_pages,
                overrideAvailabilityRequestList_no_of_rows: action.payload.overrideAvailabilityRequestList_no_of_rows,
                overrideAvailabilityRequestList_curPage: action.payload.overrideAvailabilityRequestList_curPage,
                overrideAvailabilityRequestList_searchValues: action.payload.overrideAvailabilityRequestList_searchValues,
            });

        case TimeTrackerModTypes.OVERRIDE_AVAILABILITY_REQUEST_LIST_PAGE_CLICKED:
            return updateObject(state, { overrideAvailabilityRequestList_curPage: action.payload });

        case TimeTrackerModTypes.GRANT_OVERRIDE_AVAILABILITY_REQUEST_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GRANT_OVERRIDE_AVAILABILITY_REQUEST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isTimeTrackerModalOpen: false,
            });

        case TimeTrackerModTypes.SINGLE_AVAILABILITY_LIST_CLICKED:
            return updateObject(state, {})

        case TimeTrackerModTypes.GET_ALL_AVAILABILITY_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isAvailabilityList: true,
                availabilityList: [],
            });
        case TimeTrackerModTypes.GET_ALL_AVAILABILITY_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isAvailabilityList: false,
                availabilityList: action.payload.availabilityList,
                availabilityList_total_pages: action.payload.availabilityList_total_pages,
                availabilityList_no_of_rows: action.payload.availabilityList_no_of_rows,
                availabilityListCurPage: action.payload.availabilityList_curPage,
                total_availabilityList_count: action.payload.total_availabilityList_count,
                availabilityList_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.ALL_AVAILABILITY_LIST_CLICKED:
            return updateObject(state, { availabilityListCurPage: action.payload });

        /* FL User Shift Clock In Shift Identify CHECK CALL */
        case TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKER_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKER_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isClockInShiftAvailable: action.payload.isShiftAvailable,
                isClockInShiftExcuse: action.payload.isShiftExcuse,
                clockedInShiftData: action.payload.shiftData,
                clockInCheckedInTime: action?.payload?.shiftData?.check_int_at,
            });

        /* FL User Shift Clock In - Check In POST Call */
        case TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKIN_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKIN_FULFILLED:
        if(action.payload.isShiftAvailable){
            return updateObject(state, {
                isLoading: false,
                isClockInShiftAvailable: action.payload.isShiftAvailable,
                clockedInShiftData: action.payload.shiftData,
                triggerClockInTimer : true
            });
        }else{
            return updateObject(state, {
                isLoading: false,
                isClockInShiftAvailable: action.payload.isShiftAvailable,
            });
        }
           

        /* FL User Shift Meal Break Session PUT Call */
        case TimeTrackerModTypes.FLUSER_MEAL_BREAK_SESSION_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.FLUSER_MEAL_BREAK_SESSION_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isShiftMealBreakTriggered: action.payload.isShiftMealBreakTriggered,
                shiftMealBreakInfo: action.payload.shiftMealBreakData
            });

        /* FL User Scheduled Tasks Tickets retrieve GET Call */
        case TimeTrackerModTypes.GET_PER_FLUSER_SCHEDULED_TASK_TICKETS_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.GET_PER_FLUSER_SCHEDULED_TASK_TICKETS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                scheduledTask_assignedTicketInfo: action.payload?.data?.data ?? [],
            });

        /* FL User Signature Attachment PATCH Call */
        case TimeTrackerModTypes.GET_FLUSER_SIGN_ATTACH_PRESIGN_URL_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GET_FLUSER_SIGN_ATTACH_PRESIGN_URL_FULFILLED:
            return updateObject(state, { isLoading: true });


        /* FL User Shift Clock Out - Check Out POST Call */
        case TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKOUT_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.FLUSER_SHIFT_CLOCK_CHECKOUT_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isClockInShiftAvailable: false,
            });

        /* FL User Scheduled Task My Tickets GET call */
        case TimeTrackerModTypes.GET_FLUSER_SCHEDULED_TASK_TICKETS_PENDING:
            return updateObject(state, {
                isLoading: true,
                isSTMyTicketsList: true,
                stMyTicketsList: [],
            });
        case TimeTrackerModTypes.GET_FLUSER_SCHEDULED_TASK_TICKETS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isSTMyTicketsList: false,
                stMyTicketsList: action.payload.stMyTicketsList,
                stMyTicketsList_total_pages: action.payload.stMyTicketsList_total_pages,
                stMyTicketsList_no_of_rows: action.payload.stMyTicketsList_no_of_rows,
                stMyTicketsList_curPage: action.payload.stMyTicketsList_curPage,
                stMyTicketsList_total_tickets: action.payload.stMyTicketsList_total_tickets,
                stMyTicketsList_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.FLUSER_ST_MY_TICKETS_PAGE_CLICKED:
            return updateObject(state, { stMyTicketsList_curPage: action.payload });

        /* Timecard Correction Signature Attachment PATCH Call */
        case TimeTrackerModTypes.ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.ACQUIRE_TIMECARD_CORRECTION_SIGN_PRESIGNURL_FULFILLED:
            return updateObject(state, { isLoading: true });

        /* Time Card Correction POST Call */
        case TimeTrackerModTypes.CREATE_TIMECARD_CORRECTION_QUERY_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.CREATE_TIMECARD_CORRECTION_QUERY_FULFILLED:
            return updateObject(state, { isLoading: false });

        /* Time Card Correction Reviewed List GET call */
        case TimeTrackerModTypes.GET_TIMECARD_REVIEWED_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
                isTimecardReviewedList: true,
                timecardReviewedList: [],
            });
        case TimeTrackerModTypes.GET_TIMECARD_REVIEWED_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isTimecardReviewedList: false,
                timecardReviewedList: action.payload.timecardReviewedList,
                timecardReviewedList_total_pages: action.payload.timecardReviewedList_total_pages,
                timecardReviewedList_no_of_rows: action.payload.timecardReviewedList_no_of_rows,
                timecardReviewedList_curPage: action.payload.timecardReviewedList_curPage,
                timecardReviewedList_total_tickets: action.payload.timecardReviewedList_total_tickets,
                timecardReviewedList_searchValues: action.payload.searchValues,
            });

        case TimeTrackerModTypes.TIMECARD_REVIEWED_LIST_PAGE_CLICKED:
            return updateObject(state, { stMyTicketsList_curPage: action.payload });

        /* Missed Meal Penalty Break Check API GET call */
        case TimeTrackerModTypes.GET_MISSED_MEAL_PENALTY_BREAKS_PENDING:
            return updateObject(state, { isLoading: true, isMealPenaltyLoader: true });
        case TimeTrackerModTypes.GET_MISSED_MEAL_PENALTY_BREAKS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                isMealPenaltyLoader: false,
                mealPenaltyBreaksInfo: action?.payload?.data,
            });

        /* Time card correction single card API GET call */
        case TimeTrackerModTypes.GET_SINGLE_TIMECARD_LIST_DATA_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.GET_SINGLE_TIMECARD_LIST_DATA_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                singleTimecardCorrectData: action?.payload?.data?.data ?? {},
            });

        /* Time Card Correction PUT Call */
        case TimeTrackerModTypes.UPDATE_TIMECARD_CORRECTION_QUERY_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.UPDATE_TIMECARD_CORRECTION_QUERY_FULFILLED:
            return updateObject(state, { isLoading: false });

        /* Time Sheet List GET call */
        case TimeTrackerModTypes.GET_TIME_SHEET_LIST_INFO_PENDING:
            return updateObject(state, {
                isLoading: true,
                isTimeSheetList: true,
                timeSheetList: [],
            });
        case TimeTrackerModTypes.GET_TIME_SHEET_LIST_INFO_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                dataGenerated: false,
                isTimeSheetList: false,
                timeSheetList: action.payload.timeSheetList,
                timeSheetList_total_pages: action.payload.timeSheetList_total_pages,
                timeSheetList_no_of_rows: action.payload.timeSheetList_no_of_rows,
                timeSheetList_curPage: action.payload.timeSheetList_curPage,
                timeSheetList_total_tickets: action.payload.timeSheetList_total_tickets,
                timeSheetList_searchValues: action.payload.searchValues,
                timeSheetList_timecard: action.payload.timeSheetList_timecard,
                timesheet_default_currentPage: action.payload.timesheet_default_currentPage,
            });

        /* Download Payroll CSV GET call */
        case TimeTrackerModTypes.TRIGGER_EXPORT_PAYROLL_CSV_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.TRIGGER_EXPORT_PAYROLL_CSV_FULFILLED:
            return updateObject(state, {
                isLoading: false,
            });

        /* Time Sheet List GET call */
        case TimeTrackerModTypes.GET_SHIFT_LIST_BY_PARAMS_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.GET_SHIFT_LIST_BY_PARAMS_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                getShiftListByparams: action.payload.shiftList,
            });

        /* Get shifts by Attendance Dashboard Calendar List - GET call */
        case TimeTrackerModTypes.GET_SHIFT_ATTENDANCE_CALENDAR_LIST_PENDING:
            return updateObject(state, {
                isLoading: true,
            });
        case TimeTrackerModTypes.GET_SHIFT_ATTENDANCE_CALENDAR_LIST_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                shiftAttendance_presents: action.payload.presents,
                shiftAttendance_absents: action.payload.absents,
                shiftAttendance_complaints: action.payload.complaints,
            });

        case TimeTrackerModTypes.RESOLVE_SCHEDULE_TICKET_PENDING:
            return updateObject(state, { isLoading: true });
        case TimeTrackerModTypes.RESOLVE_SCHEDULE_TICKET_FULFILLED:
            return updateObject(state, {
                isLoading: false,
            });
        case TimeTrackerModTypes.RESOLVE_SCHEDULE_TICKET_REJECTED:
            return updateObject(state, {
                isLoading: false,
            });

        case TimeTrackerModTypes.DELETE_TIMECARD_CORRECTION_CARD_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.DELETE_TIMECARD_CORRECTION_CARD_FULFILLED:
            return updateObject(state, {
                isLoading: false,
                openFrequentTimeTracker_deleteModal: false
            })

           /* FL User Move on to Next Job - PUT Call */
        case TimeTrackerModTypes.FLUSER_SHIFT_NEXT_JOB_PENDING:
            return updateObject(state, {
                isNextJobLoading: true,
            });
        case TimeTrackerModTypes.FLUSER_SHIFT_NEXT_JOB_FULFILLED:
            return updateObject(state, {
                isNextJobLoading: false,
                isClockInShiftAvailable: action.payload.isShiftAvailable,
                clockedInShiftData: action.payload.shiftData
            });
        case TimeTrackerModTypes.FLUSER_SHIFT_NEXT_JOB_REJECTED:
            return updateObject(state, {
                isNextJobLoading: false,
            });

            case TimeTrackerModTypes.CLEAR_SINGLE_SHIFT_DETAIL:
            return updateObject(state, {
                singleShiftScheduleData: {},
            });

            //Hours Type TimeTracker
            case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_OPEN_MODAL:
                return updateObject(state, { 
                    isTimeTrackerHoursTypeModalOpen: true,
                    isHoursType: false,

                });

            case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_CLOSE_MODAL:
                return updateObject(state, {
                    isTimeTrackerHoursTypeModalOpen: false,
                    isHoursType_Edit:[]
                });
                //DELETE MODEL
            case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_OPEN_DELETE_MODAL:
                return updateObject(state, {
                    openHoursTimeTracker_deleteModal: true,
                    deleteFrequentHoursType:action?.payload,
                });
            case TimeTrackerModTypes.HANDLE_FREQUENT_TIME_TRACKER_HOURSTYPE_CLOSE_DELETE_MODAL:
                    return updateObject(state, { openHoursTimeTracker_deleteModal: false });
            //CREATE HOURS TYPE
            case TimeTrackerModTypes.CREATE_HOURSTYPE_PENDING:
                return updateObject(state, { isLoadingHours: true })
            case TimeTrackerModTypes.CREATE_HOURSTYPE_FULFILLED:
                return updateObject(state, {
                    isLoadingHours: false,
                    isTimeTrackerHoursTypeModalOpen: false,
                })
            //GET  HOURSETYPE
            case TimeTrackerModTypes.GET_HOURSTYPE_LIST_PENDING:
                return updateObject(state, {
                    isLoadingHoursLits: true,
                    isHoursTypeLoad: true
                })
            case TimeTrackerModTypes.GET_HOURSTYPE_LIST_FULFILLED:
                return updateObject(state, {
                    isLoadingHoursLits: false,
                    isHoursTypeLoad: false,
                    isHoursType_List: action.payload ? action.payload.data : [],
                    total_isHourType_list: action.payload && action.payload.data ? action.payload.data.length : '',
                    })

            //DELETE
            case TimeTrackerModTypes.DELETE_HOURSTYPE_PENDING:
                return updateObject(state, { isLoadingHours: true })
            case TimeTrackerModTypes.DELETE_HOURSTYPE_FULFILLED: 
            return updateObject(state, {
                openHoursTimeTracker_deleteModal: false,
                isLoadingHours: false
            })
            //Edit
            case TimeTrackerModTypes.EDIT_HOURSTYPE_CLICKED:
                return updateObject(state, {
                    isHoursType_Edit: action.payload,
                    isTimeTrackerHoursTypeModalOpen: true,
                    isHoursType: true
                })
            
            //UPDATE

            case TimeTrackerModTypes.EDIT_HOURSTYPE_PENDING:
                return updateObject(state, { isLoadingHours: true })

            case TimeTrackerModTypes.EDIT_HOURSTYPE_FULFILLED: 
            return updateObject(state, {
                isLoadingHours: false,
                isHoursType: false
            })



            case TimeTrackerModTypes.FETCH_PAYROLL_HOLIDAY_LIST_PENDING:
            return updateObject(state, {
                payrollHollidayList: [],
                isLoadingList:true
            });

            case TimeTrackerModTypes.FETCH_PAYROLL_HOLIDAY_LIST_FULFILLED:
                
            return updateObject(state, {
                //
                total_no_of_pages:action.payload.total_no_of_pages,
                total_count:action.payload.total_count,
                payrollHollidayList: action.payload.data,
                isLoadingList:false
            });

            case TimeTrackerModTypes.FETCH_PAYROLL_HOLIDAY_LIST_REJECTED:
            return updateObject(state, {
                payrollHollidayList: [],
                isLoadingList:false
            });


            case TimeTrackerModTypes.DELETE_PAYROLL_HOLIDAY_LIST_PENDING:
            return updateObject(state, {
                isLoadingHollyday:true
            });

            case TimeTrackerModTypes.DELETE_PAYROLL_HOLIDAY_LIST_FULFILLED:
            const payrollHollidayList = [...state.payrollHollidayList];
            payrollHollidayList.splice(payrollHollidayList.findIndex(e => e.id === action.payload.id), 1);
            
            return updateObject(state, {
                payrollHollidayList: payrollHollidayList,
                isLoadingHollyday:false
            });

            case TimeTrackerModTypes.DELETE_PAYROLL_HOLIDAY_LIST_REJECTED:
            return updateObject(state, {
                isLoadingHollyday:false
            });



            case TimeTrackerModTypes.CREATE_PAYROLL_HOLIDAY_LIST_PENDING:
            return updateObject(state, {
                isLoadingHollyday:true
            });

            case TimeTrackerModTypes.CREATE_PAYROLL_HOLIDAY_LIST_FULFILLED:
            return updateObject(state, {
                isLoadingHollyday:false
            });

            case TimeTrackerModTypes.CREATE_PAYROLL_HOLIDAY_LIST_REJECTED:
            return updateObject(state, {
                isLoadingHollyday:false
            });

            case TimeTrackerModTypes.IMPORT_CHECK_HOURS_TYPE_PENDING:
                return updateObject(state, { isLoading: true })
            case TimeTrackerModTypes.IMPORT_CHECK_HOURS_TYPE_REJECTED:
                return updateObject(state, { isLoading: false })
            case TimeTrackerModTypes.IMPORT_CHECK_HOURS_TYPE_FULFILLED:
                return updateObject(state, {
                    isLoading: false,
                    hoursTypeImportErrorLogs: action && action.payload && action.payload.error ? action.payload.error : null,
                });
    
                case TimeTrackerModTypes.IMPORT_HOURS_TYPE_PENDING:
                return updateObject(state, { isLoading: true })
            case TimeTrackerModTypes.IMPORT_HOURS_TYPE_REJECTED:
                return updateObject(state, { isLoading: false })
            case TimeTrackerModTypes.IMPORT_HOURS_TYPE_FULFILLED:
                return updateObject(state, { isLoading: false, hoursTypeImportErrorLogs: action && action.payload && action.payload.error ? action.payload.error : null,});


            case TimeTrackerModTypes.UPDATE_SHIFT_SCHEDULE_PENDING:
            return updateObject(state, {
                isLoadingSubmit:true
            });

            case TimeTrackerModTypes.UPDATE_SHIFT_SCHEDULE_FULFILLED:
            return updateObject(state, {
                isLoadingSubmit:true
            });

            case TimeTrackerModTypes.UPDATE_SHIFT_SCHEDULE_REJECTED:
            return updateObject(state, {
                isLoadingSubmit:false
            });


            case TimeTrackerModTypes.FETCH_ACCOUNT_HOURSTYPE_LIST_PENDING:
            return updateObject(state, {
                accountHoursType: [],
                isLoadingList:true
            });

            case TimeTrackerModTypes.FETCH_ACCOUNT_HOURSTYPE_LIST_FULFILLED:
                
            return updateObject(state, {
                //
                total_no_of_pages:action.payload.total_no_of_pages,
                total_count:action.payload.total_count,
                accountHoursType: action.payload.data,
                isLoadingList:false
            });

            case TimeTrackerModTypes.FETCH_ACCOUNT_HOURSTYPE_LIST_REJECTED:
            return updateObject(state, {
                accountHoursType: [],
                isLoadingList:false
            });


            ///
            case TimeTrackerModTypes.DELETE_ACCOUNT_HOURSTYPE_LIST_PENDING:
            return updateObject(state, {
                isLoadingHollyday:true
            });

            case TimeTrackerModTypes.DELETE_ACCOUNT_HOURSTYPE_LIST_FULFILLED:
             if(action.payload){
                const accountHoursType = [...state.accountHoursType];
                accountHoursType.splice(accountHoursType.findIndex(e => e.id === action.payload.id), 1);
                return updateObject(state, {
                    accountHoursType: accountHoursType,
                    isLoadingHollyday:false
                });
             }
             else{
                return updateObject(state, {
                    isLoadingHollyday:false
                });
             }
            

            case TimeTrackerModTypes.DELETE_ACCOUNT_HOURSTYPE_LIST_REJECTED:
            return updateObject(state, {
                isLoadingHollyday:false
            });

            // FOR CLOCKIN AND CLOCKOUT FACE RECOGNITION
         case TimeTrackerModTypes.GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.GET_SCANNED_FRONTLINE_FACE_IMAGE_PRESIGNED_URL_FULFILLED:
            return updateObject(state, { isLoading: true })

       // FOR CLOCKIN AND CLOCKOUT FACE RECOGNITION
        case TimeTrackerModTypes.FRONTLINE_FACE_RECOGNITION_PENDING:
            return updateObject(state, { isLoading: true })
        case TimeTrackerModTypes.FRONTLINE_FACE_RECOGNITION_FULFILLED:
            return updateObject(state, { isLoading: false })

            // FOR GET PCT JOBS LIST
            case TimeTrackerModTypes.GET_PCT_JOBS_LIST_PENDING:
                return updateObject(state, { isPCTLoading: true })
            case TimeTrackerModTypes.GET_PCT_JOBS_LIST_FULFILLED:
                return updateObject(state, { isPCTLoading: false, PCTjobsList : action.payload.PCTjobsList })

                 // FOR GET PCT JOBS LIST
            case TimeTrackerModTypes.GET_PCT_FLOOR_LIST_PENDING:
                return updateObject(state, { isPCTLoading: true })
            case TimeTrackerModTypes.GET_PCT_FLOOR_LIST_FULFILLED:
                return updateObject(state, { isPCTLoading: false, PCTFloorList : action.payload.PCTFloorList })

            case TimeTrackerModTypes.RESET_FLOOR:
                return updateObject(state, { PCTFloorList: [] })

                case TimeTrackerModTypes.GET_PCT_DEPARTMENT_LIST_PENDING:
                return updateObject(state, { isPCTLoading: true })
            case TimeTrackerModTypes.GET_PCT_DEPARTMENT_LIST_FULFILLED:
                return updateObject(state, { isPCTLoading: false, PCTDepartmentList : action.payload.PCTDepartmentList })

                case TimeTrackerModTypes.GET_PCT_PHONE_LIST_PENDING:
                return updateObject(state, { isPCTLoading: true })
            case TimeTrackerModTypes.GET_PCT_PHONE_LIST_FULFILLED:
                return updateObject(state, { isPCTLoading: false, PCTPhoneList : action.payload.PCTPhoneList })

            case TimeTrackerModTypes.UPDATE_SHIFT_SCHEDULE_EXCEPTION_PENDING:
                return updateObject(state, {
                    isLoadingSubmit:true
                });
    
                case TimeTrackerModTypes.UPDATE_SHIFT_SCHEDULE_EXCEPTION_FULFILLED:
                return updateObject(state, {
                    isLoadingSubmit:false
                });
    
                case TimeTrackerModTypes.UPDATE_SHIFT_SCHEDULE_EXCEPTION_REJECTED:
                return updateObject(state, {
                    isLoadingSubmit:false
                });

                case TimeTrackerModTypes.RESET_SHIFT_SCHEDULE_DETAIL_FILTERS:
                return updateObject(state, {
                    shiftScheduleDetail_searchValues : {}
                });

                case TimeTrackerModTypes.RESET_SHIFT_FILTERS:
                    return updateObject(state, {
                        shift_searchValues : {}
                    });



        default: return state;
    }
}