import React, { Component } from "react";
import { routes, userRoles } from "../../../../utility/constants/constants";
import JobAssociationTicket from "../../../component/PCTComponent/JobAssociationComponent/JobAssociationList";
import * as actions from "../../../../redux/actions/index";
import { connect } from "react-redux";
import {  getUser } from "../../../../utility/utility";
import {reset} from 'redux-form'
var moment = require("moment");
const cloneDeep = require("clone-deep");

class JobAssociationTicketContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDashboard: false,
      pathname: "",
    };
  }

  // newTicketClicked = () => {
  //     this.props.resetObjects();
  //     this.props.history.push(routes.NEW_TICKET);
  // }

  // resetClicked = () => {
  //   const page = 1;
  //   const limit = this.props.performanceAuditList_no_of_rows;
  //   this.props.reset("searchSelfAudit");
  //   this.props.selfAuditListPageClicked(0);
  //   this.props.getAllSelfAuditForm({ page, limit }, {});
  // };

  componentDidMount() {
    if (this.props.history) {
      this.setState({
        fromDashboard: this.props.history.location.pathname.includes(
          "dashboard"
        ),
        pathname: this.props.history.location.pathname,
      });
    }
    // if (!this.props.categoriesList) {
    // this.props.getCategories({ hide: false });
    // }
    // scroll.scrollToTop();
  }

  componentDidUpdate(prevProps, prevState) {
    const user = null;
    if (user) {
      if (prevProps.user.selected_mode !== user.selected_mode) {
        this.props.ticketPageClicked(0);
      }
    }
    if (
      prevProps.history.location.pathname !== prevState.pathname &&
      prevProps.history.location.pathname !== this.state.pathname
    ) {
      this.setState({
        fromDashboard: this.props.history.location.pathname.includes(
          "dashboard"
        ),
        pathname: this.props.history.location.pathname,
      });
    }
  }

  searchTicket = (value) => {
      const values = cloneDeep(value);
      const page = 1;
      const limit = this.props.ticket_no_of_rows;
      let id =  null;
      let jobGroupName =  null;
      let createdby =  null;
      let from =  null;
      if(value.id ){
        id=value.id
      }
      if(value.jobGroupName) jobGroupName=value.jobGroupName
      if(value.createdby) createdby=value.createdby
      if(value.from) from = moment(values.from).format('YYYY-MM-DD');
      this.props.getTickets({page,limit,id,from,createdby,jobGroupName}, values);
    }

    handleClickDeleteModel = (ticket) => {

      this.props.handleClickDeleteModel(ticket);
  }

  deleteAssociatedJobs = (id) => {
      this.props.deleteAssociatedJobs(id);
  }

  handleDeleteClose = () => {
      this.props.handleDeleteClose();
  }
  

  resetClicked = () => {

    this.props.reset("searchJobAssociation");
    let SearchValue = {};
    
    //this.props.ticketPageClicked(0);
    let page=1,
    limit=this.props.ticket_no_of_rows
      this.props.getTickets({page,limit});
    
  };
  onTicketExportClicked = (body) => {
    const user = getUser();
    let data = {};
    data["mode"] = user.mode;
    Object.keys(body).map((key, index) => {
      if (body[key] !== "") {
        data[key] = body[key];
      }
    });
    this.props.onTicketExportClicked(data);
  };

  clearJobAssociation = () =>{
     this.props.clearJobAssociation()
  }

  

  render() {
    return (
      <>
        <JobAssociationTicket
          {...this.props}
          onSubmit={this.searchTicket}
          newTicketClicked={this.newTicketClicked}
          resetClicked={this.resetClicked}
          previewTicketClicked={this.previewTicketClicked}
          ticketsList={this.props.ticketsList ? this.props.ticketsList : []}
          isTicketPending={this.props.isTicketPending}
          fromDashboard={this.state.fromDashboard}
          handleClickDeleteModel={this.props.handleClickDeleteModel}
          handleDeleteClose={this.handleDeleteClose}
          deleteAssociatedJobs={this.deleteAssociatedJobs}
          openDeleteModel={this.props.openDeleteModel}
          deleteObject={this.props.deleteObject}
          isLoadingForm={this.props.isLoadingForm}
          clearJobAssociation={this.clearJobAssociation}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    ticketsList: state.JobAssociateReducer.tickets,
    isTicketPending: state.JobAssociateReducer.isLoading,
    ticket_no_of_rows: state.adminOrSuperAdminReducer.ticket_no_of_rows,
    deleteObject: state.adminOrSuperAdminReducer.deleteObject,
    openDeleteModel: state.adminOrSuperAdminReducer.openDeleteModel,
    isLoadingForm: state.JobAssociateReducer.isLoadingForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // resetObjects: () => dispatch(actions.resetObjects()),
    handleDeleteClose: () => dispatch(actions.handleDeleteClose()),
    deleteAssociatedJobs: (id) => dispatch(actions.deleteAssociatedJobs(id)),
    reset: (searchInventoryItemsList) => dispatch(reset(searchInventoryItemsList)),
    getTickets: (params,value) =>dispatch(actions.getJobAssociateList(params,value)),
    previewTicketClicked: (ticket) =>
      dispatch(actions.previewTicketClicked(ticket)),
    // openPopup: () => dispatch(actions.openPopup()),
    searchTicket: (values) => dispatch(actions.searchTicket(values)),
    ticketPageClicked:  (params) => dispatch(actions.ticketPageClickedJob(params)),
    handleClickDeleteModel: (ticket) => dispatch(actions.handleClickDeleteModel(ticket)),
    clearJobAssociation: () => dispatch(actions.clearJobAssociation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobAssociationTicketContainer);
