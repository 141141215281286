import React, { useEffect, useState } from "react";
import TicketTable from "../JobAssociationComponent/JobAssociationTicketTable";
// import PerformInspectionModal from './performInspectionModal';
import { formValueSelector, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import {
  convertDateToPickerFormat,
  transformStringToSelectOptionObject,
} from "../../../../utility/utility";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import { Input } from "../../UI/InputElement/InputElement";

const getCategoriesDropDown = (categories) => {
  return (
    categories &&
    categories.map((a) => {
      return {
        value: a.category.id,
        label: a.category.name,
      };
    })
  );
};

let JobAssociationTicket = (props) => {
  let ticketFormValues = props.formStates;
  const { handleSubmit, pristine, reset, submitting, error } = props;
  const [categoriesList, setCategoriesListList] = useState(
    getCategoriesDropDown(props.categoriesList)
  );

  useEffect(() => {
    setCategoriesListList(getCategoriesDropDown(props.categoriesList));
  }, [props.categoriesList]);

  const statusOptions = [
    {
      id: "Open",
      name: "Open",
    },
    {
      id: "resolved",
      name: "Resolved",
    },
    {
      id: "canceled",
      name: "Canceled",
    },
  ];

  const noCategoriesOptionsMessage = (a, b) => {
    return "Category not found";
  };

  return (
    <>
      <main className="app-content wraper_content inspection_wraper">
        <div className="tab_header search_filter_cont inspection_tab_content ticket_filter">
          <form onSubmit={handleSubmit}>
            <input type="hidden" value="something" />
            <div className="row" style={{ marginTop: "10px" }}>
              <div
                className="col-xl-2 col-lg-4 col-md-6 col-sm-6"
                style={{ maxWidth: "17%" }}
              >
                <div className="app-search">
                  <Input
                    dataTest="search_by_description"
                    name="id"
                    className="fil_search_input"
                    type="number"
                    placeholder="ID"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    name="jobGroupName"
                    className="fil_search_input"
                    type="search"
                    placeholder="Job Group Name"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="app-search">
                  <Input
                    name="createdby"
                    className="fil_search_input"
                    type="search"
                    placeholder="Created By"
                  />
                  <button className="app-search__button">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>

              <div
                className="col-xl-2 col-lg-4 col-md-6 col-sm-6"
                style={{ maxWidth: "17%" }}
              >
                <Input
                  name="from"
                  dateFormat="MM-dd-yyyy"
                  placeholder="Created Date"
                  datePickerType={"react-datepicker"}
                  type="date"
                  className="form-control input-modifier"
                />
                <div class="input-group-append cst_group_append">
                  <span class="input-group-text" id="basic-addon2">
                    <i class="fa fa-calendar"></i>
                  </span>
                </div>
              </div>

              <div className="col-xl-2 col-lg-6 col-sm-3 col-12 text-left">
                <button
                  data-test="filter_go_btn"
                  style={{ minWidth: "90px" }}
                  className={"btn cst_btn btn_danger mr-2  account_btn"}
                  id="submit"
                  type="submit"
                >
                  {"Go"}
                </button>
                <button
                  data-test="filter_reset_btn"
                  style={{ minWidth: "90px" }}
                  onClick={props.resetClicked}
                  className={"btn cst_btn btn-outline-secondary account_btn"}
                  id="reset"
                  type="reset"
                >
                  {"Reset"}
                </button>
              </div>

              <div className="col-xl-2 col-lg-6 col-sm-3 col-12 text-right quotes_btn_wrap">
                <Link
                  to="/job-association-form"
                  className="btn btn-secondary cst_btn"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-keyboard="false"
                  data-target="#addAccountModal"
                  data-test="add_new_ticket"
                  onClick={props.clearJobAssociation}
                >
                  <i className="fa fa-plus icn_plus"></i>Create New
                </Link>
              </div>
            </div>
          </form>
        </div>

        <section className="account_sec inspection_tab_content appcontent_Inner">
          {/* <div>
                        <button style={{ float: 'right' }}>Enable Filter</button>
                    </div> */}
          {/* <CSVLink filename="Accounts.csv" className="csv_btn btn_danger " data={convertAccountsDataToCsvType(props.accountsList)} >Export Csv</CSVLink><br /><br /><br /> */}
          <div className="quotes_table_content accounts_table_contnet table-responsive">
            <div className="table quotes_table user_react_table">
              <TicketTable
                // deleteTicket={props.deleteTicket}
                {...props}
                onAccountsPageSizeChange={props.onAccountsPageSizeChange}
                ticketsList={props.ticketsList}
                isLoading={props.isLoading}
                previewTicketClicked={props.previewTicketClicked}
                editTicketClicked={props.editTicketClicked}
                openDeleteModel={props.openDeleteModel}
                handleClickDeleteModel={props.handleClickDeleteModel}
                accounts_rows={props.accounts_rows}
                onTicketExportClicked={props.onTicketExportClicked}
                priorEscPreviewTicketClicked={
                  props.priorEscPreviewTicketClicked
                }
                viewPriorTicketStatusClicked={
                  props.viewPriorTicketStatusClicked
                }
              />
            </div>
          </div>
          {/* <div className="clearfix"></div>
                <div className="modal-footer">
                    <button type="button" className="btn btn_danger cst_btn">Save</button>
                    <button type="button" className="btn btn-outline-secondary cst_btn" data-dismiss="modal">Cancel</button>
                </div> */}
        </section>
      </main>
      <Dialog
        open={props.openDeleteModel}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ fontSize: "13", color: "#06080f" }}
        >
          <h3 style={{ color: "#ab385e" }} data-test="modal_delete_title">
            Are you sure?
          </h3>
        </DialogTitle>

        <DialogActions>
          <button
            data-test="modal_delete"
            style={{ minHeight: "36px", minWidth: "80px" }}
            onClick={() => props.deleteAssociatedJobs(props.deleteObject.id)}
            disabled={props.isLoadingForm}
            className={
              props.isLoadingForm
                ? "btn cst_btn btn_danger mr-2 btn-wait"
                : "btn cst_btn btn_danger mr-2"
            }
            id="delete"
            type="delete"
          >
            {props.isLoadingForm ? "" : "Delete"}
          </button>
          <button
            data-test="modal_cancel"
            style={{ minHeight: "36px", minWidth: "80px" }}
            onClick={props.handleDeleteClose}
            className={"btn cst_btn btn-outline-secondary"}
            id="cancel"
            type="cancel"
          >
            Cancel
          </button>
          {/* <Button onClick={props.handleClose} variant="contained" color="primary">
                        Cancel
                        </Button>


                    <Button onClick={() => props.deleteAccount(props.deleteObject.id)} disabled={props.isLoading} variant="contained" color="secondary">
                        {props.isLoading ? <CircularProgress size={24} /> : 'Delete'}
                    </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};



const mapStateToProps = (state) => {
  let id =
    state.JobAssociateReducer.searchTicketValues &&
    state.JobAssociateReducer.searchTicketValues.id
      ? state.JobAssociateReducer.searchTicketValues.id
      : "";
 
  
  let jobGroupName =
    state.JobAssociateReducer.searchTicketValues &&
    state.JobAssociateReducer.searchTicketValues.jobGroupName
      ? state.JobAssociateReducer.searchTicketValues.jobGroupName
      : "";
  
  let createdby =
    state.JobAssociateReducer.searchTicketValues &&
    state.JobAssociateReducer.searchTicketValues.createdby
      ? state.JobAssociateReducer.searchTicketValues.createdby
      : "";

  let from =
    state.JobAssociateReducer.searchTicketValues &&
    state.JobAssociateReducer.searchTicketValues.from
      ? convertDateToPickerFormat(
          state.JobAssociateReducer.searchTicketValues.from
        )
      : "";
  

  return {
    initialValues: {
      
      id,
      from,
      createdby,
      jobGroupName
    },
    formStates: getFormValues("searchJobAssociation")(state),
  };
};

JobAssociationTicket = reduxForm({
  form: "searchJobAssociation",
  // validate: fd,
  enableReinitialize: true,
})(JobAssociationTicket);
const selector = formValueSelector("searchJobAssociation"); // <-- same as form name
JobAssociationTicket = connect((state) => {
  const { fromDate, toDate } = selector(state, "fromDate", "toDate");
  const selectedValues = { fromDate, toDate };
  return {
    selectedValues,
  };
})(JobAssociationTicket);
JobAssociationTicket = connect(mapStateToProps)(JobAssociationTicket);
export default JobAssociationTicket;
