import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { userRoles } from '../../../../utility/constants/constants'
import { routes } from '../../../../utility/constants/constants';
import { extractExtention, getUser } from '../../../../utility/utility';
import { connect } from 'react-redux';
import isEmpty from '../../../../utility/IsEmptyValidator';
import { decode, encode } from 'base64-arraybuffer';
import storage from '../../../../utility/storage';
import moment from "moment";
import * as commonService from "../../../../utility/utility";
import { reset } from 'redux-form';
import NewPCTComponent from '../../../component/PCTComponent/NewPCTComponent/NewPCTComponent';

const cloneDeep = require('clone-deep');

class NewPCTContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {

            dueDate: this.props.match.params.ticketId ? new Date(this.props.ticket.due_on) : null,
            // portfolio properties
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            savedPortfolioURls: this.props.ticket.attachments_attributes ? this.props.ticket.attachments_attributes : [],
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    onCancelClicked = () => {
        this.props.history.push(routes.TICKETS);
    }

    onCancelTicketModalClicked = () => {
        this.props.closeModal();
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        // for (let i = 0; i < files.length; i++) {
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)

                if (file.type.match('image')) {

                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function () {
                        // setImageData({ ...imageData, base64: reader.result, file: file });
                        base64.push(reader.result)
                        if (index === files.length - 1) {
                            //   
                            that.setState(prevState => ({
                                base64: [...prevState.base64, ...base64]
                            }))
                        }
                    };
                    reader.onerror = function (error) {
                        console.log("Error: ", error);
                    };
                    if (index === files.length - 1) {
                        //   
                        that.setState(prevState => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                            // arrayBuffer: [...prevState.arrayBuffer, decode(reader.result)],
                            // base64: [...prevState.base64, ...base64]
                        }))
                    }
                    readFile(index + 1);

                }
                // reader.readAsArrayBuffer(file)
                // reader.readAsDataURL(file)
            }
        }

        readFile(0);
    }


    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

    }

    removeUrls = (url) => {
        let updatedUrls = [...this.state.savedPortfolioURls];

        if (url.photo_urls) {
            this.setState(prevState => ({
                idsToDelete: [...prevState.idsToDelete, url.id]
            }))
        }

        let urlIndex = this.state.savedPortfolioURls.findIndex(function (u) {
            return u === url
        })

        updatedUrls.splice(urlIndex, 1);

        this.setState({
            savedPortfolioURls: updatedUrls,
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.accountUsersList !== this.props.accountUsersList) {
            this.setState({
                userDropDownLoader: false
            })
        }

        if (prevProps.ticket !== this.props.ticket) {
            this.setState({
                dueDate: new Date(this.props.ticket.due_on)
            })
            //  
            if (this.state.idsToDelete.length === 0) {
                this.setState({
                    base64: [],
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.ticket.attachments_attributes
                })
            } else {
                this.setState({
                    // base64: this.state.base64,
                    imageName: [],
                    arrayBuffer: [],
                    idsToDelete: [],
                    portfolioImages: [],
                    savedPortfolioURls: this.props.ticket.attachments_attributes
                })
            }
        }

    }

    componentDidMount() {

            if (!this.props.PCTjobsList.length) {
                this.props.getPCTJobsList({company_id : this.props?.match?.params?.id});
            }
            if (!this.props.PCTDepartmentList.length) {
                this.props.getPCTDepartmentList();
            }

            // if (!this.props.PCTPhoneList.length) {
            //     this.props.getPCTPhoneList();
            // }
    }

    componentWillUnmount() {
        // this.props.resetObjects();
    }

    setDueDate = (date) => {

        this.setState({
            dueDate: date
        })
    }

    addNewTicket = (value) => {debugger
        
        let values = cloneDeep(value);
            values.ticket.account_id = values.ticket.account_id.value;
            values.ticket.floor_id = values.ticket.floor_id.value;
            values.ticket.department_id = values.ticket.department_id.value;
            values.ticket.shareable_id = this.props.match?.params?.id;

            const data = values.ticket

            if(this.state.base64.length){
                const arrayBuffer = [];
                this.state.base64.map((url, i) => {
                    if (!url.photo_urls) {
                        let base_photo = null;
                        const image = url.split(',');
                        base_photo = image[1];
                        arrayBuffer.push(decode(base_photo))
                    }
    
                })
                const extentions = extractExtention(this.state.imageName);
                this.props.getPCTPresignedUrl({data}, extentions, arrayBuffer, 'newPCTForm');
            }
            else{
               this.props.addPCT({data}, 'newPCTForm');  
            }
            
        
    }

     getFloors = (values) =>{
        this.props.getPCTFloorList({account_id : values.value});
    }


    render() {

        return (
            <>
            <div id='resetPCTImage' onClick={() => this.setState({base64: []})}></div>
                <NewPCTComponent
                    user={this.props.user}
                    categoriesList={this.props.categoriesList}
                    dropDownUsersList={this.props.dropDownUsersList}
                    accountsList={this.props.accountsList}
                    dueDate={this.state.dueDate}
                    setDueDate={this.setDueDate}
                    onSubmit={this.addNewTicket}
                    ticket={this.props.ticket}
                    userDropDownLoader={this.state.userDropDownLoader}
                    isEdit={this.props.match.params.ticketId ? true : false}
                    reference={this.inputOpenFileRef}
                    choosePhotoClicked={this.choosePhotoClicked}
                    portfolioImages={this.state.base64}
                    idsToDelete={this.state.idsToDelete}
                    removeUrls={this.removeUrls}
                    accountUsersList={this.props.accountUsersList ? this.props.accountUsersList : []}
                    userAccountLists={this.props.userAccountLists ? this.props.userAccountLists : []}
                    onCancelClicked={this.onCancelClicked}
                    isLoading={this.props.isLoading}
                    onPortfolioFileChange={this.onPortfolioFileChange}
                    savedPortfolioURls={this.state.savedPortfolioURls}
                    removeImage={this.removeImage}
                    isPCTLoading={this.props.isPCTLoading}
                    PCTjobsList={this.props.PCTjobsList}
                    PCTFloorList={this.props.PCTFloorList}
                    getFloors={this.getFloors}
                    PCTDepartmentList={this.props.PCTDepartmentList}
                    PCTPhoneList={this.props.PCTPhoneList}
                />
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.adminOrSuperAdminReducer.isLoading,
        dropDownUsersList: state.adminOrSuperAdminReducer.dropDownUsersList,
        userAccountLists: state.adminOrSuperAdminReducer.userAccountLists,
        ticket: state.adminOrSuperAdminReducer.ticket,
        isPCTLoading: state.TimeTrackerModReducer.isPCTLoading,
        PCTjobsList: state.TimeTrackerModReducer.PCTjobsList,
        PCTFloorList: state.TimeTrackerModReducer.PCTFloorList,
        PCTDepartmentList: state.TimeTrackerModReducer.PCTDepartmentList,
        PCTPhoneList: state.TimeTrackerModReducer.PCTPhoneList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addPCT: (ticket, resetForm) => dispatch(actions.addPCT(ticket, resetForm)),
        updateInspectionForm: (inspectionForm) => dispatch(actions.updateInspectionForm(inspectionForm)),
        reset: (newPCTForm) => dispatch(reset(newPCTForm)),  // requires form name
        getUserAccounts: (id) => dispatch(actions.getUserAccounts(id)),
        getPCTJobsList: (id) => dispatch(actions.getPCTJobsList(id)),
        getPCTFloorList: (params) => dispatch(actions.getPCTFloorList(params)),
        getPCTDepartmentList: () => dispatch(actions.getPCTDepartmentList()),
        getPCTPhoneList: () => dispatch(actions.getPCTPhoneList()),
        getPCTPresignedUrl: (ticket, extentions, arrayBuffer, resetForm) => dispatch(actions.getPCTPresignedUrl(ticket, extentions, arrayBuffer, resetForm)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPCTContainer);