import { reset } from "redux-form";
import * as API from "../../../API/SafetyAPI/SafetyAPI";
import SuccessAlert from "../../../utility/successAlert/successAlert";
import { safetyActionTypes } from "./SafetyActionType";


export const safetyClicked = () => {
    return {
        type: safetyActionTypes.SAFETY_CLICKED,
    };
};

export const getVehicleSafetyPresignedUrl = (ticket, extentions, arrayBuffer, resetForm) => {
    return (dispatch, getState) => {
        return dispatch({
            type: safetyActionTypes.SAFETY_PRESIGNED_URL,
            payload: API.getSafetyPresignedUrl(extentions, resetForm)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    ticket.data['attachments'] = [];

                    response.data.presigned_urls.forEach((p, i) => {
                        ticket.data.attachments.push(p.attachment_path)

                        dispatch({
                            type: safetyActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(addVehicleSafety(ticket, resetForm));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addVehicleSafety = (ticket, resetForm) => async (dispatch) => {
    return await dispatch({
        type: safetyActionTypes.ADD_VEHICLE_SAFETY,
        payload: API.addVehicleSafety(ticket).then((response) => {
            if (response.data.success) {
                let ele = document.getElementById('resetVehicleSafetyImage')
                if (ele) {
                    ele.click()
                }
                document.getElementById('vehicleEmail').value = ''
                dispatch(reset(resetForm))
                SuccessAlert('Vehicle Inspection Report Created Successfully')
                return response.data
            }
        }).catch((error) => {
            console.error(error)
        })
    });
};

export const getGolfCartSafetyPresignedUrl = (ticket, extentions, arrayBuffer, resetForm) => {
    return (dispatch, getState) => {
        return dispatch({
            type: safetyActionTypes.SAFETY_PRESIGNED_URL,
            payload: API.getSafetyPresignedUrl(extentions, resetForm)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    ticket.data['attachments'] = [];

                    response.data.presigned_urls.forEach((p, i) => {
                        ticket.data.attachments.push(p.attachment_path)

                        dispatch({
                            type: safetyActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(addGolfCartSafety(ticket, resetForm));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addGolfCartSafety = (ticket, resetForm) => async (dispatch) => {
    return await dispatch({
        type: safetyActionTypes.ADD_GOLF_CART_SAFETY,
        payload: API.addGolfCartSafety(ticket).then((response) => {
            if (response.data.success) {
                let ele = document.getElementById('resetGolfCartSafetyImage')
                if (ele) {
                    ele.click()
                }
                document.getElementById('golfCartEmail').value = ''
                dispatch(reset(resetForm))
                SuccessAlert('Golf Cart Inspection Report Created Successfully')
                return response.data
            }
        }).catch((error) => {
            console.error(error)
        })
    });
};

export const getTrailerSafetyPresignedUrl = (ticket, extentions, arrayBuffer, resetForm) => {
    return (dispatch, getState) => {
        return dispatch({
            type: safetyActionTypes.SAFETY_PRESIGNED_URL,
            payload: API.getSafetyPresignedUrl(extentions, resetForm)
                .then((response) => {
                    let totalAttachments = [];
                    const presigned_urls = response.data.presigned_urls;
                    ticket.data['attachments'] = [];

                    response.data.presigned_urls.forEach((p, i) => {
                        ticket.data.attachments.push(p.attachment_path)

                        dispatch({
                            type: safetyActionTypes.UPLOAD_IMAGE_TO_S3,
                            payload: API.uploadImageToS3(p.presigned_url, arrayBuffer[i])
                                .then((response) => {
                                    totalAttachments++;
                                    if (presigned_urls.length === totalAttachments) {
                                        dispatch(addTrailerSafety(ticket, resetForm));
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                }),
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                }),
        });
    };
};


export const addTrailerSafety = (ticket, resetForm) => async (dispatch) => {
    return await dispatch({
        type: safetyActionTypes.ADD_GOLF_CART_SAFETY,
        payload: API.addTrailerSafety(ticket).then((response) => {
            if (response.data.success) {
                let ele = document.getElementById('resetTrailerSafetyImage')
                if (ele) {
                    ele.click()
                }
                document.getElementById('TrailerEmail').value = ''
                dispatch(reset(resetForm))
                SuccessAlert('Trailer Inspection Report Created Successfully')
                return response.data
            }
        }).catch((error) => {
            console.error(error)
        })
    });
};
