import React, { useState } from 'react';
import ReactTable from 'react-table-v6';
import TimesheetCardsScreen from './TimesheetCardsScreen';
import '../../../../styles/custom.css';
import { CustomNoDataComponent, calculateHoursFromMinutes_V1, calculateHoursFromMinutes_V1R } from '../../../../utility/utility';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import ReactTooltip from 'react-tooltip';
import * as actions from '../../../../redux/actions/index';
import { useSelector, useDispatch } from 'react-redux';
import { ClapSpinner } from 'react-spinners-kit';
import 'react-table-v6/react-table.css';
import { tzDateConverterTool_V1, tzDateConverterTool_V6R } from '../../../../utility/TimezoneOperations/timezoneUtility';
import { userRoles } from '../../../../utility/constants/constants';
var moment = require('moment');

let TimesheetListTableScreen = (props) => {
    const dispatch = useDispatch();
    const SearchValue = useSelector(state => state.form.searchTimesheetListForm && state.form.searchTimesheetListForm.values ? state.form.searchTimesheetListForm.values : {});
    const DateGenerate = useSelector(state => state.TimeTrackerModReducer.dataGenerated);
    const values = useSelector(state => state.TimeTrackerModReducer.timeSheetList_searchValues);
    const No_of_pages = useSelector(state => state.TimeTrackerModReducer.timeSheetList_total_pages);
    const No_of_rows = useSelector(state => state.TimeTrackerModReducer.timeSheetList_no_of_rows);
    const isTimeSheetList = useSelector(state => state.TimeTrackerModReducer.isTimeSheetList);
    const myTicketListCurPage = useSelector(state => state.TimeTrackerModReducer.timeSheetList_curPage);
    const SearchOrResetTimeSheetListClicked = useSelector(state => state.TimeTrackerModReducer.SearchOrResetTimeSheetListClicked);
    const data = props.timeSheetList && props.timeSheetList.map((value,index) => {
        return {

            id: value?.id ? value.id : '−',
            date: value?.date ? tzDateConverterTool_V1(value.date) : '−',
            clockin: value?.check_int_at ? tzDateConverterTool_V6R(value.check_int_at) : '−',
            mealBreak: value?.breaks?.length > 0 ? (
                <>
                    <ReactTooltip place="left" type="dark" effect="solid" />
                    <span data-tip="View Meal Breaks" className="mr_20 account_edit_icn" onClick={() => [props.setMealBreakModalOpen(true), props.setCurrentMealBreak(value.breaks)]}><i class="fas fa-clock" aria-hidden="true" ></i></span>
                </>
            ) : '−',
            clockout: value?.check_out_at ? tzDateConverterTool_V6R(value.check_out_at) : '−',
            penalty: value?.total_penality_minutes ? calculateHoursFromMinutes_V1(value.total_penality_minutes) : '−',
            hourstype: <span  className="mr_20 account_edit_icn" onClick={()=>props?.handleShowHoursType(index)}><i class="fas fa-clock" aria-hidden="true" ></i></span>,
            holidaytype:<span  className="mr_20 account_edit_icn" onClick={()=>props?.handleShowHoildayType(index)}><i class="fa fa-calendar-plus-o" aria-hidden="true" ></i></span> ,
            actual: value?.shift?.id ? calculateHoursFromMinutes_V1R(value?.shift?.actual_minutes) : '−',
            total_working: value?.working_minutes ? calculateHoursFromMinutes_V1R(value.working_minutes) : '−',

        }

    });

    const columns = [
        {
            Header: <><span> Shift ID </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'id',
            sortable: false, // Disable sorting for this column
            show: props.timeSheetList.length !== 0,
        },
        {
            Header: <><span> Date </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'date',
            show: props.timeSheetList.length !== 0,
        },
        {
            Header: <><span> Clocked In </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'clockin',
            show: props.timeSheetList.length !== 0,
        },
        {
            Header: <><span> Meal Break </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'mealBreak',
            sortable: false, // Disable sorting for this column
            show: props.timeSheetList.length !== 0,
        },
        {
            Header: <><span> Clocked Out </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'clockout',
            sortable: false, // Disable sorting for this column
            show: props.timeSheetList.length !== 0,
        },
        {
            Header: <><span> Penalty Hours </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'penalty',
            show: props.timeSheetList.length !== 0,
        },
        {
            Header: <><span> Hours Type </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'hourstype',
            show: props.timeSheetList.length !== 0 && userRoles.SUPER_ADMIN === props?.user?.role,
        },
        {
            Header: <><span>Pay</span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'holidaytype',
            show: props.timeSheetList.length !== 0 && userRoles.SUPER_ADMIN === props?.user?.role,
        },
        {
            Header: <><span> Actual Hours </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'actual',
            show: props.timeSheetList.length !== 0,
        },
        {
            Header: <><span> Total Working Hours </span> <FaArrowUp className="icon-asc" /><FaArrowDown className="icon-desc" /></>,
            accessor: 'total_working',
            show: props.timeSheetList.length !== 0,
        }
    ];

    const [tablePage, setTablePage] = useState(0);
    const tablePageNew = React.useRef();

    if (myTicketListCurPage !== tablePageNew.current) {
        tablePageNew.current = myTicketListCurPage;
    }

    const setTablePageFn = (page) => {
        setTablePage(page)
        props.timeSheetListInfoPageClicked(page)
        tablePageNew.current = page;
    }

    const fetchData = (state, instance) => {
        if (SearchOrResetTimeSheetListClicked) {
            dispatch(actions.resetSearchOrResetTimeTracker())
        } else {
            if (!DateGenerate && !props.isTimeSheetList) {
                state.page = 2;
                const limit = state.pageSize;
                let page = tablePageNew.current >= 0 ? tablePageNew.current + 1 : myTicketListCurPage + 1;
                const myTicketsList_no_of_rows = state.pageSize;
                if (No_of_rows != myTicketsList_no_of_rows) {
                    page = 1;
                    setTablePageFn(0)
                }

                const order = state.sorted[0]?.id;
                const orderBy = state.sorted && state.sorted.length ? state.sorted[0]?.desc === true ? 'desc' : 'asc' : null;
                let userId = '';
                const timecard = true;

                if (props?.user?.role == 'frontline') {
                    userId = props?.user?.id;
                    props.getTimeSheetListInfo({ page, limit, order, orderBy, userId, timecard }, values);
                } else {
                    userId = props?.match?.params?.flUserId;
                    props.getTimeSheetListInfo({ page, limit, order, orderBy, userId, timecard }, values);
                }

            }
        }
    }

    return (
        SearchOrResetTimeSheetListClicked && props.isTimeSheetList ? (
            <div>
                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
            </div>
        ) : (
            <>
                <ReactTable
                    data={data}
                    loading={props.isTimeSheetList}
                    minRows={0}
                    defaultPageSize={No_of_rows}
                    pages={No_of_pages}
                    gotoPage={myTicketListCurPage}
                    page={myTicketListCurPage}
                    onPageChange={(page) => { setTablePageFn(page) }}
                    // onPageSizeChange={props.onUserPageSizeChange}
                    manual
                    onFetchData={fetchData}
                    showPaginationTop={true}
                    showPaginationBottom={false}
                    showPagination={props.timeSheetList.length !== 0}
                    columns={columns}
                    // style={{marginTop: '10px'}}
                    NoDataComponent={() => CustomNoDataComponent(props.isTimeSheetList, 'No Timesheet List Found')}
                    LoadingComponent={() =>
                        isTimeSheetList ?
                            <div style={{
                                display: "block",
                                position: "absolute",
                                left: 0,
                                height: '100%',
                                right: 0,
                                background: "rgba(255,255,255,0.8)",
                                transition: "all .3s ease",
                                top: 0,
                                bottom: 0,
                                textAlign: "center"
                            }}
                                className="loader_btn_block">
                                <ClapSpinner backColor="#89073c" size={40} frontColor="#89073c8a" />
                            </div> : null}
                    getTheadThProps={(state, rowInfo, column, instance) => {
                        // console.log(column);
                        return {
                            style: {
                                borderTop: 'none',
                                borderBottom: 'none',
                                borderRight: 'none',
                                textAlign: 'center',
                                color: '#566975',
                                fontSize: '14px',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                                float: 'left',
                            }
                        }
                    }
                    }
                    getTrProps={(state, rowInfo, column, instance) => {
                        // onclick = () => userDetail(rowInfo ? rowInfo.row.user : rowInfo);
                        console.log(column);
                        return {
                            className: 'react-table-tr-element',
                            style: {
                                border: '1px solid #c7cdd1',
                                transition: '.3s ease-in-out',
                                width: '100%',
                                // borderRadius: '4px',
                                // height: '80px',
                                marginTop: '5px',
                                float: 'left'
                            }
                        }

                    }}
                    getTdProps={(state, rowInfo, column, instance) => {

                        if (column.id == 'image') {
                            return {
                                style: {
                                    marginTop: '0px'
                                }
                            }
                        } else {
                            return {
                                className: 'react-td-element',
                            }
                            //  {
                            //   style: {
                            //     borderTop: 'none',
                            //     verticalAlign: 'middle',
                            //     fontSize: '14px',
                            //     color: '#2b3034',
                            //     marginTop: '10px !important',
                            //     textAlign: 'center',
                            //   }
                            // }
                        }
                    }
                    }
                    // getPaginationProps={() => {
                    //     return {
                    //         style: {
                    //             marginTop: '50px',
                    //             border: 'none',
                    //             boxShadow: 'none'
                    //         }
                    //     }
                    // }
                    // }
                    getProps={() => {
                        return {
                            style: {
                                border: 'none'
                            }
                        }
                    }}
                />

                {props.timeSheetList?.length === 0 ? null : (
                    <TimesheetCardsScreen
                        {...props}
                    />
                )}
            </>
        )
    )
};

export default TimesheetListTableScreen;