import React, { Component } from 'react';
import { connect } from 'react-redux';
import { decode, encode } from 'base64-arraybuffer';
import imageCompression from 'browser-image-compression';
import * as actions from '../../../redux/actions/index';
import { address_attributes } from '../../../utility/constants/constants';
import AddFrontlineForm from '../../component/Frontline/FrontlineForm';
import isEmpty from '../../../utility/IsEmptyValidator';
import getDetailAddress from '../../../utility/getDetailAddress';
import { getUser, resetOrientation, extractExtention, calculateHoursNumberFromMinutes_V1, calculateMinutesFromHours_V1 } from '../../../utility/utility';
import { animateScroll as scroll } from 'react-scroll'
import errorHandler from '../../../utility/errorHandler/errorHandler';
import { adminOrSuperAdminReducer } from '../../../redux/reducers/AdminOrSuperAdminReducer/adminOrSuperAdminReducer';
const cloneDeep = require('clone-deep');
class AddFrontline extends Component {

  constructor(props) {
    super(props);

    this.state = {
      base64: null,
      extension: null,
      file: null,
      profileImageName: '',

      isAddressEmpty: false,
      isZipEmpty: false,
      address_attributes: isEmpty(this.props.frontlineUser) ? address_attributes : this.props.frontlineUser.address,
    }
    this.inputOpenFileRef = React.createRef();
    this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // if (!this.props.accountsList) {
    // this.props.accountsList = null;
    const user = getUser();
    this.props.getModeAccounts(user.mode);
    // }
    this.props.getSkillSetList({ activeStatus: 'active' });
    scroll.scrollToTop();
  }

  componentWillUnmount() {
    //this.props.resetObjects();
  }

  async onFileChange(e) {

    let reader = new FileReader();
    let file = e.target.files[0];
    this.setState({ file })
    const orientation = await imageCompression.getExifOrientation(file);

    reader.onloadend = () => {

      resetOrientation(reader.result, orientation, this);

      this.setState({
        extension: file.name.split('.').pop().toLowerCase(),
        profileImageName: file.name,
        base64: reader.result,
      })

    }
    reader.readAsDataURL(file)

  }

  choosePhotoClicked = () => {
    this.inputOpenFileRef.current.click();
  }

  handleAddressChange = address => {
    let addressAttributes = { ...this.state.address_attributes };
    addressAttributes.formatted_address = address;

    this.setState({ address_attributes: addressAttributes, isAddressEmpty: false });
  };

  async handleAddressSelect(address) {
    const addressFields = await getDetailAddress(address);
    this.setState({
      address_attributes: addressFields,
      isAddressEmpty: false
    })
  };

  handleInputChange = (e) => {
    console.log(this.state);
    if (e.target.name === 'zip') {
      let addressFields = cloneDeep(this.state.address_attributes);
      addressFields.zip = e.target.value;
      this.setState({
        address_attributes: addressFields,
        isZipEmpty: false
      })
    } else {
      this.setState({
        about_me: e.target.value,
      })
    }
  }

  handleSubmit = (values) => {
    debugger

    // if (this.state.profileImageName == '' && this.state.base64 == null) {
    //   errorHandler('Kindly add profile picture');
    //   return false;
    // }

    if (values.frontline.first_name.length == 0) {
      errorHandler('Please enter First Name');
      return false;
    }
    if (values.frontline.last_name.length == 0) {
      errorHandler('Please enter Last Name');
      return false;
    }

    if (values.frontline.employee_id.length == 0) {
      errorHandler('Please enter Employee Id');
      return false;
    }
    if (values.frontline.dob_password.length == 0) {
      errorHandler('Please enter Password');
      return false;
    }

    // if (values.frontline.skill_ids.length == 0) {
    //   errorHandler('Please select Skill Sets');
    //   return false;
    // }

    // if(values.frontline.account_ids.length == 0) {
    //   errorHandler('Please select Jobs');
    //   return false;
    // }

    if (values.frontline.phone_number.length == 0) {
      errorHandler('Please enter Contact No');
      return false;
    }

    // if(values.frontline.primary_email == null) {
    //   errorHandler('Please enter Primary Email');
    //   return false;
    // }

    if (values.frontline.timezone == null) {
      errorHandler('Please select your Timezone');
      return false;
    }

    if (values.frontline.scan_type.length == 0) {
      errorHandler('Please choose Scan Type');
      return false;
    }

    // if(values.frontline.overtime &&values.frontline.overtime_limit_hours == null ) {
    //   errorHandler('Please fill Over Time Limits');
    //   return false;
    // }
    let setData = { user: {} };
    let fetchedValues = cloneDeep(values);
    let base_photo, arrayBuffer;
    let extension = this.state.profileImageName && '.' + this.state.profileImageName.split('.')[1];

    setData["user"]["role"] = fetchedValues.frontline.role;
    setData["user"]["first_name"] = fetchedValues.frontline.first_name;
    setData["user"]["last_name"] = fetchedValues.frontline.last_name;
    setData["user"]["employee_id"] = fetchedValues.frontline.employee_id;
    setData["user"]["dob_password"] = fetchedValues.frontline.dob_password;
    setData["user"]["skill_ids"] = fetchedValues.frontline["skill_ids"].map(e => e.value.toString());
    setData["user"]["account_ids"] = fetchedValues.frontline["account_ids"].map(e => e.value);
    setData["user"]["pin_number"] = fetchedValues.frontline.pin_number;
    setData["user"]["phone_number"] = fetchedValues.frontline.phone_number;
    setData["user"]["emergency_contact_number"] = fetchedValues.frontline.emergency_contact_number;
    setData["user"]["primary_email"] = fetchedValues.frontline.primary_email;
    setData["user"]["secondary_email"] = fetchedValues.frontline.secondary_email;
    setData["user"]["timezone"] = fetchedValues.frontline.timezone && fetchedValues.frontline.timezone;

    setData["user"]["phone_clock_in"] = fetchedValues.frontline.phone_clock_in;
    setData["user"]["overtime"] = fetchedValues.frontline.overtime;

    setData["user"]["membership_type"] = fetchedValues.frontline.membership_type;
    setData["user"]["exemption_type"] = fetchedValues.frontline.exemption_type;
    setData["user"]["scan_type"] = fetchedValues.frontline.scan_type;
    setData["user"]["safety_access"] = fetchedValues.frontline.safety_access;

    if (values.frontline && values.frontline.account_ids && values.frontline.account_ids.length > 0) {
      setData["user"]["default_account"] = fetchedValues.frontline["account_ids"][0].value;
    }

    setData["user"]["address_attributes"] = this.state.address_attributes;
    debugger;
    setData["user"]["form_access"] = fetchedValues.frontline["form_access"]

    ///set up new body from here

    // setData["user"]["daily_limit"] = fetchedValues.frontline.overtime ? true : false;
    // setData["user"]["weekly_limit"] = fetchedValues.frontline.overtime ? true : false;
    // setData["user"]["overtime_limit"] = fetchedValues.frontline.overtime ? true : false;

   setData["user"]["daily_limit_minutes"] = fetchedValues.frontline.daily_limit_minutes ;
   setData["user"]["weekly_limit_minutes"] = fetchedValues.frontline.weekly_limit_minutes;
   // setData["user"]["overtime_limit_minutes"] = fetchedValues.frontline.overtime_limit_minutes;

    
    
    if (this.state.base64 !== null && this.state.base64 !== '') {

      const image = this.state.base64.split(',');
      base_photo = image[1];
      arrayBuffer = decode(base_photo);

      this.props.getCreateFLUserProfileImagePresignUrl(setData, arrayBuffer, extension)

    } else {

      this.props.addFrontline(setData);

    }

  };

  render() {

    return (
      <AddFrontlineForm
        {...this.props}
        isAddressEmpty={this.state.isAddressEmpty}
        isZipEmpty={this.state.isZipEmpty}
        handleAddressChange={this.handleAddressChange}
        handleInputChange={this.handleInputChange}
        handleAddressSelect={this.handleAddressSelect}
        address_attributes={this.state.address_attributes}

        onSubmit={this.handleSubmit}
        areaErrorMessage={this.state.areaErrorMessage}

        base64={this.state.base64}
        onFileChange={this.onFileChange}
        reference={this.inputOpenFileRef}
        choosePhotoClicked={this.choosePhotoClicked}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountsList: state.adminOrSuperAdminReducer.modeAccountsList,
    skillSet_list: state.TimeTrackerModReducer.skillSet_list,
    isLoading: state.adminOrSuperAdminReducer.isLoading,
    weekly_limit_modal: state.adminOrSuperAdminReducer.weekly_limit_modal
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getModeAccounts: (mode) => dispatch(actions.getModeAccounts(mode)),
    getSkillSetList: (data) => dispatch(actions.getSkillSetList(data)),
    addFrontline: (data) => dispatch(actions.addFrontline(data)),
    handleCloseFrontlineForm: () => dispatch(actions.handleCloseFrontlineForm()),
    getCreateFLUserProfileImagePresignUrl: (data, arrayBuffer, extension) => dispatch(actions.getCreateFLUserProfileImagePresignUrl(data, arrayBuffer, extension)),
    handleWeeklyLimitSet_closeModal: () => dispatch(actions.handleWeeklyLimitSet_closeModal()),
    handleWeeklyLimitSet_openModal: () => dispatch(actions.handleWeeklyLimitSet_openModal()),
    resetFrontlineUser:()=>dispatch(actions.resetFrontlineUser())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AddFrontline);