import React, { Component } from 'react';
import * as actions from '../../../../redux/actions/index';
import { routes } from '../../../../utility/constants/constants';
import { extractExtention } from '../../../../utility/utility';
import { connect } from 'react-redux';
import { decode } from 'base64-arraybuffer';
import moment from "moment";
import * as commonService from "../../../../utility/utility";
import VehicleFormComponent from '../../../component/SafetyComponent/VehicleComponent/VehicleFormComponent';
import errorHandler from '../../../../utility/errorHandler/errorHandler';

const cloneDeep = require('clone-deep');

class VehicleFormContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            faultImages: 0,
            isImagesEmpty: false,
            imageName: '',
            idsToDelete: [],
            imageUrl: null,
            base64: [],
            savedPortfolioURls:  [],
            AllFileTypes: [],
            fileType: '',
        }
        this.inputOpenFileRef = React.createRef();
        this.onPortfolioFileChange = this.onPortfolioFileChange.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.choosePhotoClicked = this.choosePhotoClicked.bind(this);
    }

    onCancelClicked = () => {
        this.props.history.push(routes.TICKETS);
    }

    onCancelTicketModalClicked = () => {
        this.props.closeModal();
    }

    choosePhotoClicked = () => {
        this.inputOpenFileRef.current.click();
    }

    onPortfolioFileChange = (e) => {
        this.setState({
            faultImages: 0
        });
        console.log(this.state.base64);
        let files = e.target.files;
        let imageName = [], fileType = [], AllFileTypes = [], base64 = [];
        const that = this;
        async function readFile(index) {
            if (index < files.length) {
                let reader = new FileReader();
                let file = files[index];
                imageName.push(file.name)
                fileType.push(file.type)
                AllFileTypes.push(file.type)

                if (file.type.match('image')) {

                    let fileDate = await commonService.compressImage(file, {});
                    let reader = new FileReader();
                    reader.readAsDataURL(fileDate);
                    reader.onload = function () {
                        base64.push(reader.result)
                        if (index === files.length - 1) {
                            that.setState(prevState => ({
                                base64: [...prevState.base64, ...base64]
                            }))
                        }
                    };
                    reader.onerror = function (error) {
                        console.log("Error: ", error);
                    };
                    if (index === files.length - 1) {
                        //   
                        that.setState(prevState => ({
                            imageName: [...prevState.imageName, ...imageName],
                            fileType: [...prevState.fileType, ...fileType],
                            AllFileTypes: [...prevState.AllFileTypes, ...AllFileTypes],
                        }))
                    }
                    readFile(index + 1);

                }
            }
        }

        readFile(0);
    }


    removeImage = (url, fileTypeIndex) => {
        let updatedUrls = [...this.state.base64];
        let updatedFileTypes = [...this.state.fileType]
        let updatedImageNames = [...this.state.imageName]

        let urlIndex = this.state.base64.findIndex(function (u) {
            return u === url
        })
        updatedUrls.splice(urlIndex, 1);
        updatedFileTypes.splice(fileTypeIndex, 1)
        updatedImageNames.splice(fileTypeIndex, 1)

        this.setState({
            base64: updatedUrls,
            fileType: updatedFileTypes,
            imageName: updatedImageNames
        })

        this.inputOpenFileRef.current.value = ''

    }

    removeUrls = (url) => {
        let updatedUrls = [...this.state.savedPortfolioURls];

        if (url.photo_urls) {
            this.setState(prevState => ({
                idsToDelete: [...prevState.idsToDelete, url.id]
            }))
        }

        let urlIndex = this.state.savedPortfolioURls.findIndex(function (u) {
            return u === url
        })

        updatedUrls.splice(urlIndex, 1);

        this.setState({
            savedPortfolioURls: updatedUrls,
        })

    }


    componentDidMount() {

            this.props.getUsersListByTheirRoles('safetyInspection');
            this.props.getLogbookLogQuestions({type : "vehicle_inspection"})
    }



    addNewTicket = (value) => {
        if(this.state.base64.length >= 4){
            let values = cloneDeep(value);
            values.data.date = moment(values.data.date).format('MM-DD-YYYY') ;
             values.data.employee_id = values.data.employee_id.value;
             values.data.created_by = this.props.user.id
             values.data.car_wash = values.data.car_wash.id
            //   delete values.data.email_preference
              delete values.data.ready_to_submit
             const data = values.data
 
                 const arrayBuffer = [];
                 this.state.base64.map((url, i) => {
                     if (!url.photo_urls) {
                         let base_photo = null;
                         const image = url.split(',');
                         base_photo = image[1];
                         arrayBuffer.push(decode(base_photo))
                     }
     
                 })
                 const extentions = extractExtention(this.state.imageName);
                 
                 this.props.getVehicleSafetyPresignedUrl({data}, extentions, arrayBuffer, 'vehicle_inspection');
             
             
        }else{
            errorHandler('Must be 4 attachments')
            return
        }
        
        
    }


    render() {

        return (
            <>
            <div id='resetVehicleSafetyImage' onClick={() => {this.setState({base64: [], imageName: '', AllFileTypes: [], fileType: ''}); this.inputOpenFileRef.current.value = ''}}></div>
                <VehicleFormComponent
                    user={this.props.user}
                    onSubmit={this.addNewTicket}
                    usersListBySelectedRole={this.props.usersListBySelectedRole}
                    userDropDownLoader={this.state.userDropDownLoader}
                    reference={this.inputOpenFileRef}
                    choosePhotoClicked={this.choosePhotoClicked}
                    portfolioImages={this.state.base64}
                    idsToDelete={this.state.idsToDelete}
                    removeUrls={this.removeUrls}
                    onCancelClicked={this.onCancelClicked}
                    isLoading={this.props.isLoading}
                    onPortfolioFileChange={this.onPortfolioFileChange}
                    savedPortfolioURls={this.state.savedPortfolioURls}
                    removeImage={this.removeImage}
                    units={this.props.units}
                />
                </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoading: state.safetyReducer.isLoading,
        usersListBySelectedRole: state.TimeTrackerModReducer.usersListBySelectedRole,
        units: state.adminOrSuperAdminReducer.logbookLogQuestions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getVehicleSafetyPresignedUrl: (data, extentions, arrayBuffer, resetForm) => dispatch(actions.getVehicleSafetyPresignedUrl(data, extentions, arrayBuffer, resetForm)),
        getUsersListByTheirRoles: (params) => dispatch(actions.getUsersListByTheirRoles(params)),
        getLogbookLogQuestions: (params) => dispatch(actions.getLogbookLogQuestions(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleFormContainer);