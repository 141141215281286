import moment from "moment";
import { EMAIL_REGEXP, URL_REGEXP, PhNoPattern, PortfolioProperties, RatingTypes, userRoles } from '../constants/constants';
import store from '../../redux/store/store';
import * as actions from '../../redux/actions/index';
import isEmpty from '../IsEmptyValidator';
import storage from '../../utility/storage';
// import { scroolError } from '.././../adminOrSuperAdminOrRequestor/component/Inspections/PerformInsepction/performInspection';
const cloneDeep = require('clone-deep');
// import { services, styles } from '../constants/constants';

export const signInValidator = (values) => {
    const errors = {};
    errors['user'] = {};
    console.log("In validate" + values);

    if (values['user']) {
        if (!values['user']['email']) {
            errors['user']['email'] = 'This field is required.';
        }
        //else if (!EMAIL_REGEXP.test(values['user']['email'])) {
        //    errors['user']['email'] = 'Email must be valid';
        //}

        if (!values['user']['password']) {
            errors['user']['password'] = 'This field is required.';
        }

        console.log("errors" + errors.email);

    } else {
        errors['user']['email'] = 'This field is required.';
    }
    console.log(errors);
    return errors;
};

export const searchQuotes = (values) => {
    const errors = {};
    console.log("In validate" + values);
    //  
    // store.dispatch(actions.searchQuotes(values['name'], values['region'], values['status'], values['fromDate'], values['toDate']));
    return errors;
};

export const AccountsSearchValidator = (values) => {
    const errors = {};
    // store.dispatch(actions.searchAccounts(values['name'], values['accountNumber']));
    return errors;
}

export const userSearchValidator = (values) => {
    const errors = {};
    // store.dispatch(actions.searchUsers(values['name'], values['email']));
    return errors;
};

export const companySearchValidator = (values) => {
    const errors = {};
    // store.dispatch(actions.searchCompany(values['name']));
    return errors;
}

export const companyUserSearchValidator = (values) => {
    const errors = {};
    // store.dispatch(actions.searchCompanyUsers(values['email']));
    return errors;
}

export const accountUsersSearchValidator = (values) => {
    const errors = {};
    // store.dispatch(actions.searchAccountUsers(values['email']));
    return errors;
}

export const inspectioFormSearchValidator = (values) => {
    let errors = {};
    // store.dispatch(actions.searchInspectionForm(values['name']));
    return errors;

}

export const ratingSearchValidator = (values) => {
    let errors = {};
    // store.dispatch(actions.ratingPageClicked(0))
    // store.dispatch(actions.ratingSearchValidator(values['name']));
    return errors;
}

export const InspectionSearchValidator = (values) => {
    let errors = {};
    // store.dispatch(actions.searchInspection(values['name']));
    return errors;
}


export const KpiDashboardSearchValidator = (values) => {
    let errors = {};
    // store.dispatch(actions.searchInspection(values['name']));
    return errors;
}

export const TicketSearchValidator = (values) => {
    let errors = {};

    // store.dispatch(actions.searchTickets(values['subject']));
    return errors;
}

export const categorySearchValidator = (values) => {
    let errors = {};
    // store.dispatch(actions.searchCategory(values['name']));
    return errors;
}

export const associateUserCompanyValidation = (values) => {
    const errors = {};
    if (!values['email']) {
        errors['email'] = 'This field is required.';
    }
    //  
    if (values['current_company']) {
        if (values['current_company'].length === 0) {
            if (values['selected_compnies'].length === 0) {
                errors['selected_compnies'] = ' '
            }
        }
    }
    return errors;
}

export const ForgetOrResetPassword = (values) => {
    let errors = {};
    errors['user'] = {};

    if (values['user']) {
        if (!values['user']['email']) {
            errors['user']['email'] = 'This field is required.';
        } else if (!EMAIL_REGEXP.test(values['user']['email'])) {
            errors['user']['email'] = 'Email must be valid';
        }

        if (!values['user']['password']) {
            errors['user']['password'] = 'This field is required.';
        } else if (values['user']['password'].includes(' ')) {
            errors['user']['password'] = 'Password should not contain spaces.';
        }
        if (!values['user']['password_confirmation']) {
            errors['user']['password_confirmation'] = 'This field is required.';
        } else if (values['user']['password_confirmation'] !== values['user']['password']) {
            errors['user']['password_confirmation'] = 'Passwords do not match!';
        }

    } else {
        errors['user']['email'] = 'This field is required.';
        errors['user']['password'] = 'This field is required.';
        errors['user']['password_confirmation'] = 'This field is required.';
    }


    return errors;
}

export const updateUserPasswordValidator = (values) => {
    let errors = {};
    errors['user'] = {};

    if (values['user']) {
        if (!values['user']['password']) {
            errors['user']['password'] = 'This field is required.';
        } else if (values['user']['password'].includes(' ')) {
            errors['user']['password'] = 'Password should not contain spaces.';
        }
        if (!values['user']['password_confirmation']) {
            errors['user']['password_confirmation'] = 'This field is required.';
        } else if (values['user']['password_confirmation'] !== values['user']['password']) {
            errors['user']['password_confirmation'] = 'Passwords do not match!';
        }

    } else {
        errors['user']['password'] = 'This field is required.';
        errors['user']['password_confirmation'] = 'This field is required.';
    }


    return errors;
}

export const accountDuplicateFormValidator = (values) => {
    let errors = {};

    if (values) {
        if (!values['name']) {
            errors['name'] = 'This field is required.';
        } else if (!values['number']) {
            errors['number'] = 'This field is required.';
        }

    } else {
        errors['name'] = 'This field is required.';
        errors['number'] = 'This field is required.';
    }


    return errors;
}
export const userDuplicateFormValidator = (values) => {
    let errors = {};

    if (values) {
        if (!values['first_name']) {
            errors['first_name'] = 'This field is required.';
        } else if (!values['last_name']) {
            errors['last_name'] = 'This field is required.';
        } else if (!values['email']) {
            errors['email'] = 'This field is required.';
        }

    } else {
        errors['first_name'] = 'This field is required.';
        errors['last_name'] = 'This field is required.';
        errors['email'] = 'This field is required.';
    }


    return errors;
}
export const inspectionDuplicateFormValidator = (values) => {
    let errors = {};

    if (values) {
        if (!values['inspection_form_name']) {
            errors['inspection_form_name'] = 'This field is required.';
        }

    } else {
        errors['first_name'] = 'This field is required.';
    }


    return errors;
}

export const newCompanyValidator = (values) => {
    let errors = {};
    errors['company'] = {}
    if (values['company']) {
        if (!values['company']['name']) {
            errors['company']['name'] = 'This field is required.';
        }
        // if (!values['company']['company_number']) {
        //     errors['company']['company_number'] = 'This field is required.';
        // }
        // if (!values['company']['account_ids'] || values['company']['account_ids'].length === 0) {
        //     errors['company']['account_ids'] = 'This field is required.'
        // }
        if (!values['company']['company_tier']) {
            errors['company']['company_tier'] = 'This field is required.';
        }
    } else {
        // errors['company']['account_ids'] = 'This field is required.'
        errors['company']['name'] = 'This field is required.';
        errors['company']['company_tier'] = 'This field is required.';
        // errors['company']['company_number'] = 'This field is required'
    }

    return errors;
}



export const AssociateUserValidator = (values) => {
    let errors = {};
    //  
    return errors;
}

export const newFrontlineValidator = (values) => {
    let errors = {};
    let re = /^[0-9\b]+$/;
    errors['frontline'] = {};

    if (values['frontline']) {
        // if (!values['frontline']['first_name']) {
        //     errors['frontline']['first_name'] = 'This field is required.';
        // }

        // if (!values['frontline']['last_name']) {
        //     errors['frontline']['last_name'] = 'This field is required.'
        // }
        // if (!values['frontline']['employee_id']) {
        //     errors['frontline']['employee_id'] = 'This field is required.';
        // }

        // if (!values['frontline']['dob_password']) {
        //     errors['frontline']['dob_password'] = 'This field is required.';
        // }

        if (values['frontline']['dob_password'] && values['frontline']['dob_password'].length !== 4) {
            errors['frontline']['dob_password'] = 'Password length should be 4 digit.';
        } else if (values['frontline']['dob_password'] && !re.test(values['frontline']['dob_password'])) {
            errors['frontline']['dob_password'] = 'Please enter only numbers.';
        }

        // if (!values['frontline']['skill_ids']) {
        //     errors['frontline']['skill_ids'] = 'This field is required.';
        // }
        // if (!values['frontline']['account_ids']) {
        //     errors['frontline']['account_ids'] = 'This field is required.';
        // }

        // if (!values['frontline']['pin_number']) {
        //     errors['frontline']['pin_number'] = 'This field is required.';
        // }

        if (values['frontline']['pin_number'] && !re.test(values['frontline']['pin_number'])) {
            errors['frontline']['pin_number'] = 'Please enter only numbers.';
        }

        // if (!values['frontline']['phone_number']) {
        //     errors['frontline']['phone_number'] = 'This field is required.';
        // }
        // if(!values['frontline']['emergency_contact_number']) {
        //     errors['frontline']['emergency_contact_number'] = 'This field is required.';
        // }

        // if (!values['frontline']['primary_email']) {
        //     errors['frontline']['primary_email'] = 'This field is required.';
        // } 

        if (values['frontline']['primary_email'] && !EMAIL_REGEXP.test(values['frontline']['primary_email'])) {
            errors['frontline']['primary_email'] = 'Email must be valid';
        }

        // if(!values['frontline']['secondary_email']) {
        //     errors['frontline']['secondary_email'] = 'This field is required.';
        // }

        if (values['frontline']['secondary_email'] && !EMAIL_REGEXP.test(values['frontline']['secondary_email'])) {
            errors['frontline']['secondary_email'] = 'Email must be valid';
        }

        // if (!values['frontline']['scan_type']) {
        //     errors['frontline']['scan_type'] = 'This field is required.';
        // }

    } else {
        // errors['frontline']['first_name'] = 'This field is required.';
        // errors['frontline']['last_name'] = 'This field is required.';
        // errors['frontline']['employee_id'] = 'This field is required.';
        // errors['frontline']['dob_password'] = 'This field is required.';
        // errors['frontline']['skill_ids'] = 'This field is required.';
        // errors['frontline']['account_ids'] = 'This field is required.';
        // errors['frontline']['pin_number'] = 'This field is required.';
        // errors['frontline']['phone_number'] = 'This field is required.';
        // errors['frontline']['emergency_contact_number'] = 'This field is required.';
        // errors['frontline']['primary_email'] = 'This field is required.';
        // errors['frontline']['secondary_email'] = 'This field is required.';
        // errors['frontline']['scan_type'] = 'This field is required.';
    }

    return errors;
}
export const newAccountValidator = (values) => {
    let errors = {};
    //  
    //  ;

    errors['account'] = {};

    if (values['account']) {

        if (!values['account']['email']) {
            errors['account']['email'] = 'This field is required.';
        } else if (!EMAIL_REGEXP.test(values['account']['email'])) {
            errors['account']['email'] = 'Email must be valid';
        }

        if (!values['account']['first_name']) {
            errors['account']['first_name'] = 'This field is required.';
        }

        if (!values['account']['timezone']) {
            errors['account']['timezone'] = 'This field is required.';
        }

        if (!values['account']['phone_number']) {
            errors['account']['phone_number'] = 'This field is required.';
        }

        if (!values['account']['account_number']) {
            errors['account']['account_number'] = 'This field is required.';
        }
        //  else if (values['account']['account_number'].length < 10) {
        //     errors['account']['account_number'] = 'Account number should be atlease 10 characters.!'
        // }

        if (!values['account']['name']) {
            errors['account']['name'] = 'This field is required.';
        }

        if (!values['account']['region_id']) {
            errors['account']['region_id'] = 'This field is required.'
        }
        if (!values['account']['last_name']) {
            errors['account']['last_name'] = 'This field is required.';
        }

        // if (!values['account']['contact2_FN']) {
        //     errors['account']['contact2_FN'] = 'This field is required.';
        // }

        // if (!values['account']['contact2_LN']) {
        //     errors['account']['contact2_LN'] = 'This field is required.';
        // }

        // if (!values['account']['contact2_email']) {
        //     errors['account']['contact2_email'] = 'This field is required.';
        // } else if (!EMAIL_REGEXP.test(values['account']['contact2_email'])) {
        //     errors['account']['contact2_email'] = 'Email must be valid';
        // }

        // if (!values['account']['contact2_phone']) {
        //     errors['account']['contact2_phone'] = 'This field is required.';
        // }

    } else {
        errors['account']['email'] = 'This field is required.';
        errors['account']['timezone'] = 'This field is required.';
        errors['account']['first_name'] = 'This field is required.';
        errors['account']['phone_number'] = 'This field is required.';
        errors['account']['account_number'] = 'This field is required.';
        errors['account']['last_name'] = 'This field is required.';
        errors['account']['name'] = 'This field is required.';
        errors['account']['region_id'] = 'This field is required.';
        // errors['account']['contact2_FN'] = 'This field is required.';
        // errors['account']['contact2_LN'] = 'This field is required.';
        // errors['account']['contact2_email'] = 'This field is required.';
        // errors['account']['contact2_phone'] = 'This field is required.';
    }

    return errors;
}

export const newUserValidator = (values) => {


    let errors = {};
    //  
    errors['user'] = {};

    if (values['user']) {
        if (!values['user']['email']) {
            errors['user']['email'] = 'This field is required.';
        } else if (!EMAIL_REGEXP.test(values['user']['email'])) {
            errors['user']['email'] = 'Email must be valid';
        }

        if (!values['user']['role']) {
            errors['user']['role'] = 'This field is required.';
        }
        // if (!values['user']['password']) {
        //     errors['user']['password'] = 'This field is required.';
        // }
        if (!values['user']['region_id']) {
            errors['user']['region_id'] = 'This field is required.';
        }
        if (!values['user']['timezone']) {
            errors['user']['timezone'] = 'This field is required.';
        }
        if (!values['user']['mode']) {
            errors['user']['mode'] = 'This field is required.';
        }

        if (!values['user']['first_name']) {
            errors['user']['first_name'] = 'This field is required.';
        } else if (values['user']['first_name'].length <= 1) {
            errors['user']['first_name'] = 'First name is too short.';
        }

        if (!values['user']['last_name']) {
            errors['user']['last_name'] = 'This field is required.';
        } else if (values['user']['last_name'].length <= 1) {
            errors['user']['last_name'] = 'Last  name is too short';
        }

        if (!values['user']['position_id']) {
            errors['user']['position_id'] = 'This field is required.';
        }
    } else {
        // errors['user']['password'] = 'This field is required.';
        errors['user']['position_id'] = 'This field is required.';
        errors['user']['timezone'] = 'This field is required.';
        errors['user']['last_name'] = 'This field is required.';
        errors['user']['email'] = 'This field is required.';
        errors['user']['region_id'] = 'This field is required.';
        errors['user']['mode'] = 'This field is required.';
        errors['user']['first_name'] = 'This field is required.';
    }

    return errors;
}

export const newInspectionQuoteValidator = (values) => {
    let errors = {};

    errors['quote'] = {};

    if (values['quote']) {
        if (!values['quote']['account_id']) {
            errors['quote']['account_id'] = 'This field is required.';
        }
        // if (!values['quote']['total_sale']) {
        //     errors['quote']['total_sale'] = 'This field is required.';
        // } else if (values['quote']['total_sale'] < 0) {
        //     values['quote']['total_sale'] = '';
        // }
        if (!values['quote']['description']) {
            errors['quote']['description'] = 'This field is required.';
        } else if (values['quote']['description'].trim().length < 20) {
            errors['quote']['description'] = 'Description should be atleast 20 characters.'
        }

    } else {
        errors['quote']['account_id'] = 'This field is required.';
        errors['quote']['total_sale'] = 'This field is required.';
        errors['quote']['description'] = 'This field is required.';

    }
    return errors;
}

export const newManualQuoteValidator = (values) => {
    let errors = {};

    errors['quote'] = {};

    if (values['quote']) {
        if (!values['quote']['account_id']) {
            errors['quote']['account_id'] = 'This field is required.';
        }
        if (!values['quote']['total_sale']) {
            errors['quote']['total_sale'] = 'This field is required.';
        } else if (values['quote']['total_sale'] < 0) {
            values['quote']['total_sale'] = '';
        }
        if (!values['quote']['by_method']) {
            errors['quote']['by_method'] = 'This field is required.';
        }
        if (!values['quote']['quote_created_at']) {
            errors['quote']['quote_created_at'] = 'This field is required.';
        }
        // if (!values['quote']['description']) {
        //     errors['quote']['description'] = 'This field is required.';
        // } else if (values['quote']['description'].trim().length < 20) {
        //     errors['quote']['description'] = 'Description should be atleast 20 characters.'
        // }
    } else {
        errors['quote']['account_id'] = 'This field is required.';
        errors['quote']['total_sale'] = 'This field is required.';
        errors['quote']['by_method'] = 'This field is required.';
        errors['quote']['quote_created_at'] = 'This field is required.';
    }
    return errors;
}

export const newQuoteValidator = (values) => {
    let errors = {};

    errors['quote'] = {};

    if (values['quote']) {
        if (!values['quote']['account_id']) {
            errors['quote']['account_id'] = 'This field is required.';
        }

        if (values['quote']['tag_opportunity'] === true || values['quote']['tag_opportunity'] === "true") {
            // if (!values['quote']['total_sale']) {
            //     errors['quote']['total_sale'] = 'This field is required.';
            // } else if (values['quote']['total_sale'] < 0) {
            //     values['quote']['total_sale'] = '';
            // }
            // if (!values['quote']['description']) {
            //     errors['quote']['description'] = 'This field is required.';
            // } else if (values['quote']['description'].trim().length < 20) {
            //     errors['quote']['description'] = 'Description should be atleast 20 characters.'
            // }
        }
    } else {
        errors['quote']['account_id'] = 'This field is required.';
        // errors['quote']['total_sale'] = 'This field is required.';
        // errors['quote']['description'] = 'This field is required.';

    }
    return errors;
}

export const approveQuoteValidator = (values) => {
    let errors = {};
    if (values['totalSale'] || values['description']) {
        if (!values['totalSale']) {
            errors['totalSale'] = 'This field is required.';
        } else {
            // values['totalSale'] = values['totalSale'].replace(/\,/g, "")
            if (values['totalSale'] < 0) {
                values['totalSale'] = '';
            }
        }
        if (!values['description']) {
            errors['description'] = 'This field is required.';
        } else if (values['description'].trim().length < 20) {
            errors['description'] = 'Description should be atleast 20 characters.'
        }
    } else {
        errors['totalSale'] = 'This field is required.';
        errors['description'] = 'Description should be atleast 20 characters.'
    }
    return errors;
}

export const newPercentageRatingValidator = (values, props, a, b, c, d) => {
    let errors = {};
    errors['rating_type'] = {};

    if (values.rating_type) {
        if (!values['rating_type']['name']) {
            errors['rating_type']['name'] = "This filed is required."
        }
        if (values['rating_type']['rating_options_attributes']) {
            errors['rating_type']['rating_options_attributes'] = {};
            values['rating_type']['rating_options_attributes'].map((rating, i) => {
                errors['rating_type']['rating_options_attributes'][`${i}`] = {};
                if (!rating.name) {
                    errors['rating_type']['rating_options_attributes'][`${i}`]['name'] = 'This field is required.';
                }

                if (rating.value !== 0 && !rating.value) {
                    errors['rating_type']['rating_options_attributes'][`${i}`]['value'] = 'This field is required.'
                } else if (parseInt(rating.value) > 100) {
                    rating.value = 0
                }
            })
        }
        //  
        if (values.rating_type.rating_options_attributes && values.rating_type.rating_options_attributes.filter(r => (r.default === "true")).length === 1) {
            let index = values.rating_type.rating_options_attributes.findIndex(r => r.default === "true")
            values.rating_type.rating_options_attributes[index]['defaultTrue'] = true;

        } else if (values.rating_type && values.rating_type.rating_options_attributes && values.rating_type.rating_options_attributes.filter(r => (r.default === "true")).length > 1) {

            let index = values.rating_type.rating_options_attributes.findIndex(r => r.defaultTrue === true)
            values.rating_type.rating_options_attributes[index].default = "false";
            values.rating_type.rating_options_attributes[index]['defaultTrue'] = false;

            let defaultIndex = values.rating_type.rating_options_attributes.findIndex(r => r.default === "true")
            values.rating_type.rating_options_attributes[defaultIndex]['defaultTrue'] = true
        }
    }

    return errors;
}

export const newLineRatingValidator = (values) => {
    let errors = {};
    errors['rating_type'] = {};

    if (values.rating_type) {
        if (!values['rating_type']['name']) {
            errors['rating_type']['name'] = "This filed is required."
        }
        if (values['rating_type']['rating_options_attributes']) {
            errors['rating_type']['rating_options_attributes'] = {};
            values['rating_type']['rating_options_attributes'].map((rating, i) => {
                errors['rating_type']['rating_options_attributes'][`${i}`] = {};
                if (!rating.name) {
                    errors['rating_type']['rating_options_attributes'][`${i}`]['name'] = 'This field is required.';
                }
            })
        }
    }
    return errors;
}

export const newInspectionValidator = (values, props) => {

    const TextRatings = props.ratingList && props.ratingList.filter(rating => rating.category === 'text');

    let errors = {};
    errors['inspection_form'] = {};

    if (values.inspection_form) {
        if (!values.inspection_form.name) {
            errors['inspection_form']['name'] = "Inspection form name can't be blank."
        }

        if (!values.inspection_form.mode) {
            errors['inspection_form']['mode'] = "Mode can't be blank."
        } if (!values.inspection_form.criteria) {
            errors['inspection_form']['criteria'] = "criteria can't be blank."
        }
        if (values.inspection_form.account_ids && values.inspection_form.account_ids.length > 0) {
            const index = values.inspection_form.account_ids.findIndex(a => a.label === 'Job-wide');
            if (index > -1) {
                values.inspection_form['form_category'] = 'account_wide';
                // values.inspection_form.account_ids.map((a, i) => {

                // if (i === index) {
                values.inspection_form.account_ids = values.inspection_form.account_ids.slice(index, index + 1);
                // }
                // })
            } else {
                values.inspection_form['form_category'] = 'site_specific'
            }


        } else {
            errors['inspection_form']['account_ids'] = "Available to can't be blank."
        }

        if (values.inspection_form.sections_attributes) {
            errors['inspection_form']['sections_attributes'] = {}
            values.inspection_form.sections_attributes.map((section, si) => {
                errors['inspection_form']['sections_attributes'][`${si}`] = {}
                if (!values.inspection_form.sections_attributes[si].name) {
                    errors['inspection_form']['sections_attributes'][`${si}`]['name'] = ' '
                }
                errors['inspection_form']['sections_attributes'][`${si}`]['line_items_attributes'] = {}
                if (!isEmpty(section) && section.line_items_attributes) {
                    section.line_items_attributes.map((line, li) => {
                        errors['inspection_form']['sections_attributes'][`${si}`]['line_items_attributes'][`${li}`] = {};
                        if (!line.name) {
                            errors['inspection_form']['sections_attributes'][`${si}`]['line_items_attributes'][`${li}`]['name'] = ' '
                        }

                        if (line.rating_type_id) {
                            if (!line.rating_type_id.value) {
                                errors['inspection_form']['sections_attributes'][`${si}`]['line_items_attributes'][`${li}`]['rating_type_id'] = ' '
                            } else {
                                if (TextRatings.some(textR => textR.id === line.rating_type_id.value)) {
                                    line['weight'] = 0;
                                }
                            }
                        } else {
                            errors['inspection_form']['sections_attributes'][`${si}`]['line_items_attributes'][`${li}`]['rating_type_id'] = ' '
                        }

                        if (line.weight) {
                            if (!(line.weight >= 0)) {
                                errors['inspection_form']['sections_attributes'][`${si}`]['line_items_attributes'][`${li}`]['weight'] = ' '
                            }
                            else if (line.weight < 0) {
                                line.weight = 0;
                            }
                        }

                        // let commented this code for future purpose
                        // if (!(line.weight && line.weight >= 0) && line.rating_type_id && line.rating_type_id.label !== "Other" && line.rating_type_id.label !== "Netflix Check" && line.rating_type_id.label !=="Name") {
                        //     errors['inspection_form']['sections_attributes'][`${si}`]['line_items_attributes'][`${li}`]['weight'] = ' '
                        // }  
                        // else if (line.weight < 0) {
                        //     line.weight = 0;
                        // }
                    })
                }
            })
        }

    }


    if (values.inspection_form && !values.inspection_form.id) {
        storage.set('inspectionFormData', values);
    }
    return errors;
}

export const CreatingInspectionValidator = (values) => {
    let errors = {};
    errors['inspection'] = {}
    if (values.inspection) {

        if (values.inspection.account_id) {
            // errors['inspection']['account_id'] = {};
            if (!values.inspection.account_id.value) {
                errors['inspection']['account_id'] = 'This is a required field.'
            }
        } else {
            errors['inspection']['account_id'] = 'This is a required field.'
        }

        if (values.inspection.inspection_form_id) {
            if (!values.inspection.inspection_form_id.value) {
                errors['inspection']['inspection_form_id'] = 'This is a required field.'
            }
        } else {
            errors['inspection']['inspection_form_id'] = 'This is a required field.'
        }
    } else {
        errors['inspection']['account_id'] = 'This is a required field.';
        errors['inspection']['inspection_form_id'] = 'This is a required field.';
    }

    return errors;
}

export const performInspectionValidator = (values, props) => {
    const CurrentInspectonData = storage.get("performInspectionData", null);

    let errors = {};
    errors['inspection'] = {}
    errors['inspection']['inspection_form'] = {}

    if (values.inspection) {
        errors['inspection']['inspection_form']['sections_attributes'] = {}
        values.inspection.inspection_form.sections_attributes.map((section, seci) => {

            errors['inspection']['inspection_form']['sections_attributes'][seci] = {}
            errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'] = {}
            if (section.line_items_attributes.some(line => line.n_a === false)) {
                section.n_a = false
            } else {
                section.n_a = true
            }
            section.line_items_attributes.map((line, li) => {
                if (!line.portfolioImages) {
                    //  
                    line['portfolioImages'] = PortfolioProperties;
                }
                // line.rating_option_id = parseInt(line.rating_option_id);
                errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'][li] = {}

                if (!line.rating_option_id && !line.n_a && (line.rating_type.name !== 'Name' || (line.rating_option && line.rating_option.name === RatingTypes.Text))) {
                    errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'][li]['rating_option_id'] = ' ';
                }
                if (!props.inspection.inspection_form.sections_attributes[seci].line_items_attributes[li].attachment_paths && !line.n_a && props.inspection.inspection_form.sections_attributes[seci].line_items_attributes[li].attachments_attributes.length < 1) {
                    let deficentRating = line.rating_type &&
                        line.rating_type.rating_options_attributes.filter(rating => rating.deficient === true);
                    const isDeficent = deficentRating && deficentRating.findIndex(rating => rating.id === line.rating_option_id);

                    if (deficentRating && deficentRating.length !== 0 && isDeficent > -1) {
                        errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'][li]['comment'] = "Attachment is required";
                    }
                }
                if (props.inspection.inspection_form.sections_attributes[seci].name.includes("Critical Three Items")) {
                    if (!props.inspection.inspection_form.sections_attributes[seci].line_items_attributes[li].attachment_paths && props.inspection.inspection_form.sections_attributes[seci].line_items_attributes[li].attachments_attributes.length < 1) {
                        errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'][li]['comment'] = "Attachment is required";
                    }
                }
                if ((!line.comment || line.comment === ' ') && !line.n_a) {
                    // if (line.comment === ' ') {
                    //     line.comment = '';
                    // }
                    let deficentRating = line.rating_type &&
                        line.rating_type.rating_options_attributes.filter(rating => rating.deficient === true);
                    const isDeficent = deficentRating && deficentRating.findIndex(rating => rating.id === line.rating_option_id);

                    if (deficentRating && deficentRating.length !== 0 && isDeficent > -1) {
                        errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'][li]['comment'] = "This field is required";
                    }
                } else if (line.comment === '' && !line.n_a) {

                    let deficentRating = line.rating_type &&
                        line.rating_type.rating_options_attributes.filter(rating => rating.deficient === true);
                    const isDeficent = deficentRating && deficentRating.findIndex(rating => rating.id === line.rating_option_id);

                    if (deficentRating && deficentRating.length !== 0 && isDeficent > -1) {
                        errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'][li]['comment'] = "This field is required";
                    }
                } else if (line.n_a) {
                    line.rating_option_id = ''
                    line.comment = ' '
                } else if (!line.n_a) {
                    if (line.name.toLowerCase().includes('customer contact')) {
                        if (line.comment.length > 50) {
                            errors['inspection']['inspection_form']['sections_attributes'][seci]['line_items_attributes'][li]['comment'] = 'Contact Name should be less than 50 characters.'
                        }
                        // errors['inspection']['inspection_form'] = 'Description should be atleast 20 characters.'
                    }

                    section.n_a = false
                }
                // }
                if (line.customer_rep_available) {
                    line.comment = '';
                }
            })
        })

        // if (!CurrentInspectonData && !isEmpty(values.inspection) && values.inspection.inspection_form.id) {
        if (props.inspection.account_info) {
            values.inspection['account_info'] = props.inspection.account_info;
        }
        if (props.inspection.address) {
            values.inspection['address'] = props.inspection.address;
        }
        storage.set('performInspectionData', values);
        // }

    }
    //  else {
    //     errors['inspection']['inspection_form']['sections_attributes'] = {}
    //     errors['inspection']['inspection_form']['sections_attributes'][0] = {}
    //     errors['inspection']['inspection_form']['sections_attributes'][0]['line_items_attributes'] = {}
    //     errors['inspection']['inspection_form']['sections_attributes'][0]['line_items_attributes'][0] = {}
    //     errors['inspection']['inspection_form']['sections_attributes'][0]['line_items_attributes'][0]['comment'] = "This field is required";
    // }

    // scroolError(errors);

    return errors;
}

export const newTicketValidator = (values) => {
    let errors = {};
    const User = storage.get("user", null);
    errors['ticket'] = {};
    if (values.ticket) {
        if (values.ticket.account_id) {

            if (!values.ticket.account_id.value) {
                errors['ticket']['account_id'] = 'This field is required.'
            }
        } else {
            errors['ticket']['account_id'] = 'This field is required.'
        }

        if (!values.ticket.category_id) {
            errors['ticket']['category_id'] = 'This field is required.'
        }

        if (!values.ticket.message) {
            errors['ticket']['message'] = 'This field is required.'
        }

        if (!values.ticket.subject) {
            errors['ticket']['subject'] = 'This field is required.'
        }

        // if (User.role !== userRoles.CLIENT) {
        if (values.ticket.user_id) {
            if (!values.ticket.user_id.value) {
                errors['ticket']['user_id'] = 'This field is required.'
            }
        } else {
            errors['ticket']['user_id'] = 'This field is required.'
        }
        // }

        if (!values.ticket.due_on) {
            errors['ticket']['due_on'] = 'This field is required.'
        }
        // else if (values.ticket.due_on && values.ticket.due_on < new Date()) {
        //     errors['ticket']['due_on'] = 'Due date and time should be greater than the current time.'
        // }

        if (!values.ticket.priority) {
            errors['ticket']['priority'] = 'This field is required.'
        }
    } else {
        errors['ticket']['account_id'] = 'This field is required.';
        errors['ticket']['category_id'] = 'This field is required.';
        errors['ticket']['subject'] = 'This field is required.';
        errors['ticket']['message'] = 'This field is required.';
        // if (User.role !== userRoles.CLIENT) {
        //     errors['ticket']['user_id'] = 'This field is required.';
        // }
        errors['ticket']['due_on'] = 'This field is required.';
        errors['ticket']['priority'] = 'This field is required.';
    }

    return errors;
}

export const previewTicketValidator = (values) => {
    let errors = {};
    errors['ticket'] = {};
    if (values.ticket) {
        errors.ticket['comments_attributes'] = {};
        if (values.ticket.comments_attributes && values.ticket.comments_attributes[values.ticket.comments_attributes.length - 1]) {
            if (!values.ticket.comments_attributes[values.ticket.comments_attributes.length - 1].description) {
                errors.ticket.comments_attributes = new Array(values.ticket.comments_attributes.length).fill({});
                errors.ticket.comments_attributes[values.ticket.comments_attributes.length - 1]['description'] = 'This field is required.'
            }
        }

    }
    return errors;
}

export const priorPreviewTicketValidator = (values) => {
    let errors = {};
    errors['ticket'] = {};
    if (values.ticket) {
        // errors.ticket['comments_attributes'] = {};
        // if (values.ticket.comments_attributes && values.ticket.comments_attributes[values.ticket.comments_attributes.length - 1]) {
        //     if (!values.ticket.comments_attributes[values.ticket.comments_attributes.length - 1].description) {
        //         errors.ticket.comments_attributes = new Array(values.ticket.comments_attributes.length).fill({});
        //         errors.ticket.comments_attributes[values.ticket.comments_attributes.length - 1]['description'] = 'This field is required.'
        //     }
        // }

    }
    return errors;
}

export const myProfile = (values) => {
    let errors = {};

    errors['user'] = {};

    if (values['user']) {
        if (!values['user']['email']) {
            errors['user']['email'] = 'This field is required.';
        } else if (!EMAIL_REGEXP.test(values['user']['email'])) {
            errors['user']['email'] = 'Email must be valid';
        }

        // if (!values['user']['password']) {
        //     errors['user']['password'] = 'This field is required.';
        // }

        if (!values['user']['first_name']) {
            errors['user']['first_name'] = 'This field is required.';
        }

        if (!values['user']['last_name']) {
            errors['user']['last_name'] = 'This field is required.';
        }

        if (!values['user']['position']) {
            errors['user']['position'] = 'This field is required.';
        }
    } else {
        // errors['user']['password'] = 'This field is required.';
        errors['user']['position'] = 'This field is required.';
        errors['user']['last_name'] = 'This field is required.';
        errors['user']['email'] = 'This field is required.';
        errors['user']['first_name'] = 'This field is required.';
    }

    return errors;
}

export const changePassword = (values) => {

    let errors = {}
    //  ;
    errors['user'] = {};

    if (values['user']) {

        if (!values['user']['current_password']) {
            errors['user']['current_password'] = 'This field is required.';
        }
        if (!values['user']['password']) {
            errors['user']['password'] = 'This field is required.';
        } else if (values['user']['password'].includes(' ')) {
            errors['user']['password'] = 'Password should not contain spaces.';
        }
        if (!values['user']['password_confirmation']) {
            errors['user']['password_confirmation'] = 'This field is required.';
        } else if (values['user']['password_confirmation'] !== values['user']['password']) {
            errors['user']['password_confirmation'] = 'Passwords do not match!';
        }
    } else {
        errors['user']['current_password'] = 'This field is required.';
        errors['user']['password'] = 'This field is required.';
        errors['user']['password_confirmation'] = 'This field is required.';
    }

    return errors;
}

export const positions = (values) => {
    let errors = {};

    errors['position'] = {};

    if (values['position']) {

        if (!values['position']['name']) {
            errors['position']['name'] = 'This field is required.';
        }
    } else {
        errors['position']['name'] = 'This field is required.';
    }

    return errors;
}
export const areaTypeValidation = (values) => {
    let errors = {};

    errors['area_type'] = {};

    if (values['area_type']) {

        if (!values['area_type']['name']) {
            errors['area_type']['name'] = 'This field is required.';
        }
    } else {
        errors['area_type']['name'] = 'This field is required.';
    }

    return errors;
}

export const locationValidation = (values) => {
    let errors = {};

    errors['location'] = {};

    if (values['location']) {

        if (!values['location']['name']) {
            errors['location']['name'] = 'This field is required.';
        }
    } else {
        errors['location']['name'] = 'This field is required.';
    }

    return errors;
}

export const locationAssociationValidation = (values) => {
    let errors = {};

    errors['location_association'] = {};

    if (values['location_association']) {

        if (!values['location_association']['location']) {
            errors['location_association']['location'] = 'This field is required.';
        }
        if (!values['location_association']['fulfilment_manager']) {
            errors['location_association']['fulfilment_manager'] = 'This field is required.';
        }
    } else {
        errors['location_association']['location'] = 'This field is required.';
        errors['location_association']['fulfilment_manager'] = 'This field is required.';
    }

    return errors;
}

export const deficiencyValidation = (values) => {
    let errors = {};

    errors['difficency'] = {};

    if (values['difficency']) {

        if (!values['difficency']['name']) {
            errors['difficency']['name'] = 'This field is required.';
        }
    } else {
        errors['difficency']['name'] = 'This field is required.';
    }

    return errors;
}

export const floorValidation = (values) => {
    let errors = {};

    errors['floor'] = {};

    if (values['floor']) {

        if (!values['floor']['name']) {
            errors['floor']['name'] = 'This field is required.';
        }
    } else {
        errors['floor']['name'] = 'This field is required.';
    }

    return errors;
}

export const region = (values) => {
    let errors = {};

    errors['region'] = {};

    if (values['region']) {

        if (!values['region']['name']) {
            errors['region']['name'] = 'This field is required.';
        }
    } else {
        errors['region']['name'] = 'This field is required.';
    }

    return errors;
}

export const category = (values) => {
    let errors = {};
    errors['category'] = {};
    if (values.category) {
        if (!values.category.name || values.category.name.length === 0) {
            errors['category']['name'] = 'This field is required.'
        }
    } else {
        errors['category']['name'] = 'This field is required.';
    }
    return errors;
}

export const settingsPreferenceValidator = (values, props) => {
    let errors = {};
    errors['user'] = {}

    if (values.user) {
        // if ((values.user.all_inspection_email || values.user.no_inspection_email) && (values.ALL_MAILS || values.NO_MAIL)) {
        // if (values.user.all_inspection_email && !values.ALL_MAILS) {
        //     values['ALL_MAILS'] = true;
        //     values['NO_MAIL'] = false;

        //     values.user['all_inspection_email'] = "true";
        //     // values.user['some_inspection_email'] = false;
        //     values.user['no_inspection_email'] = false;

        //     values.user['no_deficiency'] = true;
        //     values.user['contains_deficiency'] = true;
        //     values.user['has_photos'] = true;
        //     values.user['has_comments'] = true;

        // } else if ((values.user.all_inspection_email && values.ALL_MAILS) &&
        //     (values.user.no_deficiency || values.user.contains_deficiency ||
        //         values.user.has_photos || values.user.has_comments)) {
        //     values.user['all_inspection_email'] = false;
        // }

        // if (values.user.no_inspection_email && !values.NO_MAIL) {
        //     values['NO_MAIL'] = true;
        //     values['ALL_MAILS'] = false;

        //     // values.user['some_inspection_email'] = false;
        //     values.user['all_inspection_email'] = false;

        //     values.user['no_deficiency'] = false;
        //     values.user['contains_deficiency'] = false;
        //     values.user['has_photos'] = false;
        //     values.user['has_comments'] = false;
        // } else if ((values.user.no_inspection_email && values.NO_MAIL) &&
        //     (values.user.no_deficiency || values.user.contains_deficiency ||
        //         values.user.has_photos || values.user.has_comments)) {

        //     // values.user['some_inspection_email'] = "true";
        //     values.user['no_inspection_email'] = false;
        //     values['NO_MAIL'] = false;

        // }

        // if (!values.user.all_inspection_email && values.user.no_deficiency && values.user.contains_deficiency &&
        //     values.user.has_photos && values.user.has_comments) {
        //     values.user['all_inspection_email'] = "true";
        //     values['ALL_MAILS'] = true;
        // }
        // }
    }

    return errors;
}

export const quotesReportValidator = (values) => {
    let errors = {};
    if (values) {
        if (!values.account_ids || values.account_ids.length === 0) {
            errors['account_ids'] = ' '
        }

        if (!values.tag_opportunity) {
            errors['tag_opportunity'] = 'This field is required.'
        }

        if (!values.active_status) {
            errors['active_status'] = 'This field is required.'
        }

        if (!values.fromDate) {
            errors['fromDate'] = ' '
        }
        if (!values.toDate) {
            errors['toDate'] = ' '
        }
    } else {
        errors['account_ids'] = ' ';
        errors['tag_opportunity'] = 'This field is required.';
        errors['active_status'] = 'This field is required.';
        errors['fromDate'] = ' ';
        errors['toDate'] = ' '
    }
    return errors;
}

export const pegassureReportValidator = (values) => {
    console.log('validator mmmmm', values);
    let errors = {};
    if (values) {
        if (!values.account_ids || values.account_ids.length === 0) {
            errors['account_ids'] = ' '
        }

        if (!values.area_ids || values.area_ids.length === 0) {
            errors['area_ids'] = ' '
        }

        // if (!values.active_status) {
        //     errors['active_status'] = 'This field is required.'
        // }

        if (!values.fromDate) {
            errors['fromDate'] = ' '
        }
        if (!values.toDate) {
            errors['toDate'] = ' '
        }
    } else {
        errors['account_ids'] = ' ';
        errors['area_ids'] = 'This field is required.';
        // errors['active_status'] = 'This field is required.';
        errors['fromDate'] = ' ';
        errors['toDate'] = ' '
    }
    return errors;
}

export const inspectionReportsValidator = (values) => {
    let errors = {};
    if (values) {
        if (!values.account_ids || values.account_ids.length === 0) {
            errors['account_ids'] = ' '
        }

        if (!values.fromDate) {
            errors['fromDate'] = ' '
        }
        if (!values.toDate) {
            errors['toDate'] = ' '
        }
    } else {
        errors['account_ids'] = ' ';
        errors['fromDate'] = ' ';
        errors['toDate'] = ' '
    }
    return errors;
}
export const CriteriaSearchValidator = (values) => {
    let errors = {};
    return errors;
}
export const criteria = (values) => {
    let errors = {};
    errors['criteria'] = {};
    if (values.criteria) {
        if (!values.criteria.name || values.criteria.name.length === 0) {
            errors['criteria']['name'] = 'This field is required.'
        }
    } else {
        errors['criteria']['name'] = 'This field is required.';
    }
    return errors;
}

export const NewHolidayValidator = (values) => {
    let errors = {};
    errors['holiday'] = {}
    if (values.holiday) {
        if (!values.holiday.holiday_reason) {
            errors.holiday['holiday_reason'] = 'This field is required.'
        } else if (values.holiday.holiday_reason.trim() === '') {
            errors.holiday['holiday_reason'] = 'This field is required.'
        }
        if (!values.holiday.holiday_date) {
            errors.holiday['holiday_date'] = 'This field is required.'
        }
    } else {
        errors.holiday['holiday_reason'] = 'This field is required.';
        errors.holiday['holiday_date'] = 'This field is required.';
    }

    return errors;
}

export const CustomDateFormSearchValidator = (values) => {
    let errors = {};

    // store.dispatch(actions.searchTickets(values['subject']));
    return errors;
}

export const newScheduleValidator = (schedules) => {
    let errors = {};
    errors['schedules'] = {}
    if (schedules['schedules']) {
        if (!schedules['schedules']['title']) {
            errors['schedules']['title'] = 'This field is required.';
        }
        if (!schedules.schedules.inspection_form_id) {
            errors['schedules']['inspection_form_id'] = 'This field is required.'
        }
        if (!schedules.schedules.account_id) {
            errors['schedules']['account_id'] = 'This field is required.'
        }
        if (!schedules.schedules.frequency) {
            errors['schedules']['frequency'] = 'This field is required.'
        }
        if (!schedules.schedules.day) {
            errors['schedules']['day'] = 'This field is required.'
        }
        if (!schedules.schedules.start_date) {
            errors['schedules']['start_date'] = 'This field is required.'
        }
        if (!schedules.schedules.end_date) {
            errors['schedules']['end_date'] = 'This field is required.'
        } if (!schedules.schedules.selected_week) {
            errors['schedules']['selected_week'] = 'This field is required.'
        } if (!schedules.schedules.selected_month) {
            errors['schedules']['selected_month'] = 'This field is required.'
        } if (!schedules.schedules.recur) {
            errors['schedules']['recur'] = 'This field is required.'
        } if (!schedules.schedules.select_week) {
            errors['schedules']['select_week'] = 'This field is required.'
        }
    } else {
        errors['schedules']['title'] = 'This field is required.';
        errors['schedules']['inspection_form_id'] = 'This field is required.';
        errors['schedules']['account_id'] = 'This field is required.';
        errors['schedules']['frequency'] = 'This field is required.';
        errors['schedules']['day'] = 'This field is required.';
        errors['schedules']['start_date'] = 'This field is required.'
        errors['schedules']['end_date'] = 'This field is required.'
        errors['schedules']['selected_week'] = 'This field is required.'
        errors['schedules']['selected_month'] = 'This field is required.'
        errors['schedules']['recur'] = 'This field is required.'
        errors['schedules']['select_week'] = 'This field is required.'
    }

    return errors;
}

export const createNewScheduleTicketValidator = (fieldValues, index) => {
    let errors = {};
    errors['scheduleTicket'] = {}
    errors['scheduleTicket']['schedule'] = {}
    errors['scheduleTicket']['reminders'] = []
    if (fieldValues['scheduleTicket']) {
        if (!fieldValues.scheduleTicket.title) {
            errors['scheduleTicket']['title'] = 'This field is required.';
        }
        if (!fieldValues.scheduleTicket.account_id) {
            errors['scheduleTicket']['account_id'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.area_id) {
            errors['scheduleTicket']['area_id'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.assigned_to) {
            errors['scheduleTicket']['assigned_to'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.subject) {
            errors['scheduleTicket']['subject'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.due_on) {
            errors['scheduleTicket']['due_on'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.message) {
            errors['scheduleTicket']['message'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.category) {
            errors['scheduleTicket']['category'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.priority) {
            errors['scheduleTicket']['priority'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.is_ticket_recur) {
            errors['scheduleTicket']['is_ticket_recur'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.start_date) {
            errors['scheduleTicket']['start_date'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.building) {
            errors['scheduleTicket']['building'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.manager) {
            errors['scheduleTicket']['manager'] = 'This field is required.'
        }
        if (!fieldValues.scheduleTicket.area) {
            errors['scheduleTicket']['area'] = 'This field is required.'
        }

        if (fieldValues.scheduleTicket) {
            if (fieldValues.scheduleTicket.schedule) {
                if (!(fieldValues.scheduleTicket.schedule.frequency)) {
                    errors['scheduleTicket']['schedule']['frequency'] = 'This field is required.'
                }
                if (!(fieldValues.scheduleTicket.schedule.select_week)) {
                    errors['scheduleTicket']['schedule']['select_week'] = 'This field is required.'
                }
                if (!(fieldValues.scheduleTicket.schedule.selected_week)) {
                    errors['scheduleTicket']['schedule']['selected_week'] = 'This field is required.'
                }
                if (!(fieldValues.scheduleTicket.schedule.selected_month)) {
                    errors['scheduleTicket']['schedule']['selected_month'] = 'This field is required.'
                }
                if (!(fieldValues.scheduleTicket.schedule.is_recur)) {
                    errors['scheduleTicket']['schedule']['is_recur'] = 'This field is required.'
                }
                if (!(fieldValues.scheduleTicket.schedule.day)) {
                    errors['scheduleTicket']['schedule']['day'] = 'This field is required.'
                }
                if (!(fieldValues.scheduleTicket.schedule.start_date)) {
                    errors['scheduleTicket']['schedule']['start_date'] = 'This field is required.'
                }
                if (!(fieldValues.scheduleTicket.schedule.end_date)) {
                    errors['scheduleTicket']['schedule']['end_date'] = 'This field is required.'
                }
            }
        }

        if (fieldValues.scheduleTicket) {
            if (fieldValues.scheduleTicket.reminders) {
                fieldValues.scheduleTicket.reminders.map((tycoon, index) => {
                    errors['scheduleTicket']['reminders'][index] = {}
                    if (!(fieldValues['scheduleTicket']['reminders'][index]['ba_value'])) {
                        errors['scheduleTicket']['reminders'][index]['ba_value'] = '.'
                    }
                    if (!(fieldValues['scheduleTicket']['reminders'][index]['hours_value'])) {
                        errors['scheduleTicket']['reminders'][index]['hours_value'] = '.'
                    }
                    if (!(fieldValues['scheduleTicket']['reminders'][index]['note'])) {
                        errors['scheduleTicket']['reminders'][index]['note'] = '.'
                    }
                })
            }
        }

    } else {
        errors['scheduleTicket']['title'] = 'This field is required.';
        errors['scheduleTicket']['account_id'] = 'This field is required.';
        errors['scheduleTicket']['area_id'] = 'This field is required.';
        errors['scheduleTicket']['assigned_to'] = 'This field is required.';
        errors['scheduleTicket']['subject'] = 'This field is required.'
        errors['scheduleTicket']['due_on'] = 'This field is required.'
        errors['scheduleTicket']['message'] = 'This field is required.'
        errors['scheduleTicket']['category'] = 'This field is required.'
        errors['scheduleTicket']['priority'] = 'This field is required.'
        errors['scheduleTicket']['is_ticket_recur'] = 'This field is required.'
        errors['scheduleTicket']['start_date'] = 'This field is required.'
        errors['scheduleTicket']['building'] = 'This field is required.'
        errors['scheduleTicket']['area'] = 'This field is required.'
        errors['scheduleTicket']['manager'] = 'This field is required.'

        errors['scheduleTicket']['schedule']['frequency'] = 'This field is required.'
        errors['scheduleTicket']['schedule']['select_week'] = 'This field is required.'
        errors['scheduleTicket']['schedule']['selected_week'] = 'This field is required.'
        errors['scheduleTicket']['schedule']['selected_month'] = 'This field is required.'
        errors['scheduleTicket']['schedule']['is_recur'] = 'This field is required.'
        errors['scheduleTicket']['schedule']['day'] = 'This field is required.'
        errors['scheduleTicket']['schedule']['start_date'] = 'This field is required.'
        errors['scheduleTicket']['schedule']['end_date'] = 'This field is required.'

        if (fieldValues.scheduleTicket) {
            if (fieldValues.scheduleTicket.reminders) {
                fieldValues.scheduleTicket.reminders.map((tycoon, index) => {
                    errors['scheduleTicket']['reminders'][index] = {}
                    errors['scheduleTicket']['reminders'][index]['ba_value'] = '.'
                    errors['scheduleTicket']['reminders'][index]['hours_value'] = '.'
                    errors['scheduleTicket']['reminders'][index]['note'] = '.'
                })
            }
        }

    }

    return errors;
}

export const createApiAccessValidator = (values) => {

    let errors = {};
    errors['company_access'] = {}
    if (values['company_access']) {

        if (!values.company_access.company_id) {
            errors['company_access']['company_id'] = 'This field is required.';
        }
        if (!values.company_access.token_expires) {
            errors['company_access']['token_expires'] = 'This field is required.'
        }
        if (!values.company_access.access_type) {
            errors['company_access']['access_type'] = 'This field is required.'
        }
        if (!values.company_access.access_limit) {
            errors['company_access']['access_limit'] = 'This field is required.'
        }
        if (!values.company_access.is_limited) {
            errors['company_access']['is_limited'] = 'This field is required.'
        }
        if (!values.company_access.active_status) {
            errors['company_access']['active_status'] = 'This field is required.'
        }


    } else {


        errors['company_access']['company_id'] = 'This field is required.';
        errors['company_access']['token_expires'] = 'This field is required.';
        errors['company_access']['access_type'] = 'This field is required.';
        errors['company_access']['access_limit'] = 'This field is required.';
        errors['company_access']['is_limited'] = 'This field is required.';
        errors['company_access']['active_status'] = 'This field is required.';

    }

    return errors;
}

export const newInventoryItemValidator = (values) => {
    
    let errors = {};
    errors['item'] = {};
    if (values.item) {

        if (!values.item.name) {
            errors['item']['name'] = 'This field is required.'
        }
        if (!values.item.initial_quantity) {
            errors['item']['initial_quantity'] = 'This field is required.'
        }
        if (values.item.initial_quantity == 0) {
            errors['item']['initial_quantity'] = 'Atleast one quantity required.'
        }
        if (!values.item.manufacture) {
            errors['item']['manufacture'] = 'This field is required.'
        }
        if (!values.item.sku_detail) {
            errors['item']['sku_detail'] = 'This field is required.'
        }
        if (!values.item.expiration_date) {
            errors['item']['expiration_date'] = 'This field is required.'
        }
        if (!values.item.supplier) {
            errors['item']['supplier'] = 'This field is required.'
        }
        if (!values.item.reason) {
            errors['item']['reason'] = 'This field is required.'
        }

        if (!values.item.cost_at_replacement) {
            errors['item']['cost_at_replacement'] = 'This field is required.'
        }
        if (!values.item.initial_cost) {
            errors['item']['initial_cost'] = 'This field is required.'
        }
        if (!values.item.location) {
            errors['item']['location'] = 'This field is required.'
        }





    } else {
        errors['item']['name'] = 'This field is required.';
        errors['item']['initial_quantity'] = 'This field is required.';
        errors['item']['manufacture'] = 'This field is required.';
        errors['item']['sku_detail'] = 'This field is required.';
        errors['item']['expiration_date'] = 'This field is required.';
        errors['item']['supplier'] = 'This field is required.';
        errors['item']['reason'] = 'This field is required.';
        errors['item']['cost_at_replacement'] = 'This field is required.';
        errors['item']['initial_cost'] = 'This field is required.';
        errors['item']['location'] = 'This field is required.';
    }

    return errors;
}

export const newBoxValidator = (values) => {
    let errors = {};
    errors['box'] = {};
    if (values.box) {

        if (!values.box.name) {
            errors['box']['name'] = 'This field is required.'
        }
        if (!values.box.number) {
            errors['box']['number'] = 'This field is required.'
        }

    } else {
        errors['box']['name'] = 'This field is required.';
        errors['box']['number'] = 'This field is required.';
    }

    return errors;
}

export const newAssetValidator = (values) => { 
    
    
    let errors = {};
    errors['item'] = {};
    
    if (values.item) {

        if (!values.item.name) {
            errors['item']['name'] = 'This field is required.'
        }
        if (!values.item.initial_quantity) {
            errors['item']['initial_quantity'] = 'This field is required.'
        }
        if (!values.item.manufacture) {
            errors['item']['manufacture'] = 'This field is required.'
        }
        if (!values.item.expiration_date) {
            errors['item']['expiration_date'] = 'This field is required.'
        }
        if (!values.item.supplier) {
            errors['item']['supplier'] = 'This field is required.'
        }
        if (!values.item.purchase_date) {
            errors['item']['purchase_date'] = 'This field is required.'
        }
        if (!values.item.initial_cost) {
            errors['item']['initial_cost'] = 'This field is required.'
        }
        if (!values.item.facility) {
            errors['item']['facility'] = 'This field is required.'
        }
        if (!values.item.life_expectancy) {
            errors['item']['life_expectancy'] = 'This field is required.'
        }
        if (!values.item.cost_at_replacement) {
            errors['item']['cost_at_replacement'] = 'This field is required.'
        }
        if (!values.item.salvage_date) {
            errors['item']['salvage_date'] = 'This field is required.'
        }
        if (!values.item.manufacturer_serial_number) {
            errors['item']['manufacturer_serial_number'] = 'This field is required.'
        }
        if (!values.item.location) {
            errors['item']['location'] = 'This field is required.'
        }
        if (!values.item.serial_number) {
            errors['item']['serial_number'] = 'This field is required.'
        }
        
    } else {
        errors['item']['name'] = 'This field is required.';
        errors['item']['initial_quantity'] = 'This field is required.';
        errors['item']['manufacture'] = 'This field is required.';
        errors['item']['expiration_date'] = 'This field is required.';
        errors['item']['supplier'] = 'This field is required.';
        errors['item']['purchase_date'] = 'This field is required.';
        errors['item']['initial_cost'] = 'This field is required.';
        errors['item']['facility'] = 'This field is required.';
        errors['item']['life_expectancy'] = 'This field is required.';
        errors['item']['cost_at_replacement'] = 'This field is required.';
        errors['item']['salvage_date'] = 'This field is required.';
        errors['item']['manufacturer_serial_number'] = 'This field is required.';
        errors['item']['location'] = 'This field is required.';
        errors['item']['serial_number'] = 'This field is required.';
    }

    
    
    return errors;
}


export const newAssetValidatorPage = (values) => { 
    
    
    let errors = {asset:{}};
    errors["asset"]['item'] = {};
    
    

    if (values.asset.item && values.asset.item) {
        values.asset.item.map((tycoon, index) => {
            errors['asset']["item"][index] = {}
            if (!(values['asset']['item'][index]['name'])) {
                errors['asset']['item'][index]['name'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['initial_quantity'])) {
                errors['asset']['item'][index]['initial_quantity'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['manufacture'])) {
                errors['asset']['item'][index]['manufacture'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['supplier'])) {
                errors['asset']['item'][index]['supplier'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['purchase_date'])) {
                errors['asset']['item'][index]['purchase_date'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['initial_cost'])) {
                errors['asset']['item'][index]['initial_cost'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['facility'])) {
                errors['asset']['item'][index]['facility'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['life_expectancy'])) {
                errors['asset']['item'][index]['life_expectancy'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['cost_at_replacement'])) {
                errors['asset']['item'][index]['cost_at_replacement'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['salvage_date'])) {
                errors['asset']['item'][index]['salvage_date'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['location'])) {
                errors['asset']['item'][index]['location'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['manufacturer_serial_number'])) {
                errors['asset']['item'][index]['manufacturer_serial_number'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['serial_number'])) {
                errors['asset']['item'][index]['serial_number'] = 'This field is required.'
            }
            if (!(values['asset']['item'][index]['expiration_date'])) {
                errors['asset']['item'][index]['expiration_date'] = 'This field is required.'
            }
        })
    }
    
    return errors;
}



export const assetCheckInFormValidator = (values) => {
    let errors = {};
    errors['scan'] = {};
    if (values.scan) {

        if (!values.scan.account_id) {
            errors['scan']['account_id'] = 'This field is required.'
        }

        if (!values.scan.location_id) {
            errors['scan']['location_id'] = 'This field is required.'
        }
        if (!values.scan.assigned_area) {
            errors['scan']['assigned_area'] = 'This field is required.'
        }
        if (!values.scan.power_cord_safety_check) {
            errors['scan']['power_cord_safety_check'] = 'This field is required.'
        }
        if (!values.scan.comments) {
            errors['scan']['comments'] = 'This field is required.'
        }

    } else {
        errors['scan']['account_id'] = 'This field is required.';
        errors['scan']['location_id'] = 'This field is required.';
        errors['scan']['assigned_area'] = 'This field is required.';
        errors['scan']['power_cord_safety_check'] = 'This field is required.';
        errors['scan']['comments'] = 'This field is required.';
    }

    return errors;
}

export const assetCheckOutFormValidator = (values) => {
    let errors = {};
    errors['scan'] = {};
    if (values.scan) {

        if (!values.scan.desc) {
            errors['scan']['desc'] = 'This field is required.'
        }

        if (!values.scan.location_id) {
            errors['scan']['location_id'] = 'This field is required.'
        }

        if (!values.scan.equipment_condition) {
            errors['scan']['equipment_condition'] = 'This field is required.'
        }

        if (!values.scan.supervisor_initial_url) {
            errors['scan']['supervisor_initial_url'] = 'This field is required.'
        }

    } else {
        errors['scan']['desc'] = 'This field is required.';
        errors['scan']['location_id'] = 'This field is required.';
        errors['scan']['equipment_condition'] = 'This field is required.';
        errors['scan']['supervisor_initial_url'] = 'This field is required.';

    }

    return errors;
}

export const inventoryPullRequestValidator = (values) => {
    
    let errors = {};
    errors['ticket'] = {};
    errors['ticket']['items'] = []
    if (values.ticket) {

        if (!values.ticket.account_id) {
            errors['ticket']['account_id'] = 'This field is required.'
        }
        if (!values.ticket.delivery_at) {
            errors['ticket']['delivery_at'] = 'This field is required.'
        }
        if (!values.ticket.location_id) {
            errors['ticket']['location_id'] = 'This field is required.'
        }
        if (!values.ticket.is_emergency) {
            errors['ticket']['is_emergency'] = 'This field is required.'
        }
        if (!values.ticket.approver_id) {
            errors['ticket']['approver_id'] = 'This field is required.'
        }
        if (!values.ticket.supervisor_id) {
            errors['ticket']['supervisor_id'] = 'This field is required.'
        }
        if (!values.ticket.building) {
            errors['ticket']['building'] = 'This field is required.'
        }
        if (!values.ticket.packaged) {
            errors['ticket']['packaged'] = 'This field is required.'    
        }

        if (values.ticket && values.ticket.items) {
            values.ticket.items.map((tycoon, index) => {
                errors['ticket']['items'][index] = {}
                if (!(values['ticket']['items'][index]['id'])) {
                    errors['ticket']['items'][index]['id'] = 'This field is required.'
                }
                if (!(values['ticket']['items'][index]['quantity'])) {
                    errors['ticket']['items'][index]['quantity'] = 'This field is required.'
                }
                // if (!(values['ticket']['items'][index]['triggerPoint'])) {
                //     errors['ticket']['items'][index]['triggerPoint'] = 'This field is required.'
                // }
                if (!(values['ticket']['items'][index]['description'])) {
                    errors['ticket']['items'][index]['description'] = 'This field is required.'
                }
                if (!(values['ticket']['items'][index]['packaged'])) {
                    errors['ticket']['items'][index]['packaged'] = 'This field is required.'
                }
                
            })
        }

    } else {

        errors['ticket']['account_id'] = 'This field is required.';
        errors['ticket']['delivery_at'] = 'This field is required.';
        errors['ticket']['location_id'] = 'This field is required.';
        errors['ticket']['is_emergency'] = 'This field is required.';
        errors['ticket']['approver_id'] = 'This field is required.';
        errors['ticket']['supervisor_id'] = 'This field is required.';
        errors['ticket']['building'] = 'This field is required.';
        //errors['ticket']['packaged'] = 'This field is required.';

        if (values.ticket && values.ticket.items) {
            values.ticket.items.map((tycoon, index) => {
                errors['ticket']['items'][index] = {}
                errors['ticket']['items'][index]['id'] = 'This field is required.'
                errors['ticket']['items'][index]['quantity'] = 'This field is required.'
                // errors['ticket']['items'][index]['triggerPoint'] = 'This field is required.'
                errors['ticket']['items'][index]['description'] = 'This field is required.'
                errors['ticket']['items'][index]['packaged'] = 'This field is required.'
            })
        }
    }
      
    return errors;
}



export const BulkStorageFormValidator = (values) => {
    let errors = {};
    errors['ticket'] = {};
    errors['ticket']['items'] = []
    if (values.ticket) {

        if (!values.ticket.account_id) {
            errors['ticket']['account_id'] = 'This field is required.'
        }
        if (!values.ticket.delivery_at) {
            errors['ticket']['delivery_at'] = 'This field is required.'
        }
        if (!values.ticket.location_id) {
            errors['ticket']['location_id'] = 'This field is required.'
        }
        if (!values.ticket.is_emergency) {
            errors['ticket']['is_emergency'] = 'This field is required.'
        }
        if (!values.ticket.approver_id) {
            errors['ticket']['approver_id'] = 'This field is required.'
        }
        if (!values.ticket.supervisor_id) {
            errors['ticket']['supervisor_id'] = 'This field is required.'
        }
        if (!values.ticket.building) {
            errors['ticket']['building'] = 'This field is required.'
        }
        if (!values.ticket.packaged) {
            errors['ticket']['packaged'] = 'This field is required.'    
        }

        if (values.ticket && values.ticket.items) {
            values.ticket.items.map((tycoon, index) => {
                errors['ticket']['items'][index] = {}
                if (!(values['ticket']['items'][index]['id'])) {
                    errors['ticket']['items'][index]['id'] = 'This field is required.'
                }
                if (!(values['ticket']['items'][index]['quantity'])) {
                    errors['ticket']['items'][index]['quantity'] = 'This field is required.'
                }
                if (!(values['ticket']['items'][index]['triggerPoint'])) {
                    errors['ticket']['items'][index]['triggerPoint'] = 'This field is required.'
                }
                if (!(values['ticket']['items'][index]['description'])) {
                    errors['ticket']['items'][index]['description'] = 'This field is required.'
                }
                if ((!values['ticket']['items'][index]['packaged'])) {
                    errors['ticket']['items'][index]['packaged'] = 'This field is required.'
                }
            })
        }

    } else {

        errors['ticket']['account_id'] = 'This field is required.';
        errors['ticket']['delivery_at'] = 'This field is required.';
        errors['ticket']['location_id'] = 'This field is required.';
        errors['ticket']['is_emergency'] = 'This field is required.';
        errors['ticket']['approver_id'] = 'This field is required.';
        errors['ticket']['supervisor_id'] = 'This field is required.';

        if (values.ticket && values.ticket.items) {
            values.ticket.items.map((tycoon, index) => {
                errors['ticket']['items'][index] = {}
                errors['ticket']['items'][index]['id'] = 'This field is required.'
                errors['ticket']['items'][index]['quantity'] = 'This field is required.'
                errors['ticket']['items'][index]['triggerPoint'] = 'This field is required.'
                errors['ticket']['items'][index]['packaged'] = 'This field is required.'
            })
        }
    }

    return errors;
}




export const newFulfillmentManagerFormValidator = (values) => {
    let errors = {};
    errors['fulfillmentManager'] = {};
    errors['fulfillmentManager']['ticket'] = {};
    if (values.fulfillmentManager && values.fulfillmentManager.ticket) {

        if (!values.fulfillmentManager.ticket.box_name) {
            errors['fulfillmentManager']['ticket']['box_name'] = 'This field is required.'
        }

    } else {
        errors['fulfillmentManager']['ticket']['box_name'] = 'This field is required.';
    }

    return errors;
}

export const scanForDeliveryFormValidator = (values) => {
    let errors = {};
    errors['deliveryItems'] = {};
    errors['deliveryItems']['ticket'] = {};
    if (values.deliveryItems && values.deliveryItems.ticket) {

        if (!values.deliveryItems.ticket.is_extras) {
            errors['deliveryItems']['ticket']['is_extras'] = 'This field is required.'
        }

    } else {
        errors['deliveryItems']['ticket']['is_extras'] = 'This field is required.';
    }

    return errors;
}

export const listedItemsComingBackFormValidator = (fieldValues, index) => {
    let errors = {};
    errors['delivery'] = {}
    errors['delivery']['items'] = []
    errors['delivery']['newItems'] = []
    if (fieldValues['delivery']) {

        if (fieldValues.delivery.items) {
            fieldValues.delivery.items.map((tycoon, index) => {
                errors['delivery']['items'][index] = {}
                if (!(fieldValues['delivery']['items'][index]['id'])) {
                    errors['delivery']['items'][index]['id'] = 'This field is required.'
                }
                if (!(fieldValues['delivery']['items'][index]['quantity'])) {
                    errors['delivery']['items'][index]['quantity'] = 'This field is required.'
                }
                if (!(fieldValues['delivery']['items'][index]['triggerPoint'])) {
                    errors['delivery']['items'][index]['triggerPoint'] = 'This field is required.'
                }
            })
        }

        if (fieldValues.delivery.newItems) {
            fieldValues.delivery.newItems.map((tycoon, index) => {
                errors['delivery']['newItems'][index] = {}
                if (!(fieldValues['delivery']['newItems'][index]['name'])) {
                    errors['delivery']['newItems'][index]['name'] = 'This field is required.'
                }
                if (!(fieldValues['delivery']['newItems'][index]['quantity'])) {
                    errors['delivery']['newItems'][index]['quantity'] = 'This field is required.'
                }
                if (!(fieldValues['delivery']['newItems'][index]['isAuthorized'])) {
                    errors['delivery']['newItems'][index]['isAuthorized'] = 'This field is required.'
                }
            })
        }

    } else {

        if (errors.delivery) {
            if (errors.delivery.items) {
                errors.delivery.items.map((tycoon, index) => {
                    errors['delivery']['items'][index] = {}
                    errors['delivery']['items'][index]['id'] = 'This field is required.'
                    errors['delivery']['items'][index]['quantity'] = 'This field is required.'
                    errors['delivery']['items'][index]['triggerPoint'] = 'This field is required.'
                })
            }
        }

        if (fieldValues.delivery) {
            if (fieldValues.delivery.newItems) {
                fieldValues.delivery.newItems.map((tycoon, index) => {
                    errors['delivery']['newItems'][index] = {}
                    errors['delivery']['newItems'][index]['name'] = 'This field is required.'
                    errors['delivery']['newItems'][index]['quantity'] = 'This field is required.'
                    errors['delivery']['newItems'][index]['isAuthorized'] = 'This field is required.'
                })
            }
        }

    }

    return errors;
}

export const skillsetValidator = (values) => {
    let errors = {};
    errors['skillset'] = {};
    if (values.skillset) {
        if (!values.skillset.name || values.skillset.name.length === 0) {
            errors['skillset']['name'] = 'This field is required.'
        }
        if (!values.skillset.description || values.skillset.description.length === 0) {
            errors['skillset']['description'] = 'This field is required.'
        }
    } else {
        errors['skillset']['name'] = 'This field is required.';
        errors['skillset']['description'] = 'This field is required.';
    }
    return errors;
};

export const HoursTypeValidator = (values) => {
    let errors = {};
    errors['hoursType'] = {};
    if (values.hoursType) {
        if (!values.hoursType.id || values.hoursType.id.length === 0) {
            errors['hoursType']['id'] = 'This field is required.'
        }
        if (!values.hoursType.description || values.hoursType.description.length === 0) {
            errors['hoursType']['description'] = 'This field is required.'
        }
    } else {
        errors['hoursType']['name'] = 'This field is required.';
        errors['hoursType']['description'] = 'This field is required.';
    }
    return errors;
};


export const departmentValidator = (values) => {
    let errors = {};
    errors['department'] = {};
    if (values.department) {
        if (!values.department.name || values.department.name.length === 0) {
            errors['department']['name'] = 'This field is required.'
        }
        if (!values.department.description || values.department.description.length === 0) {
            errors['department']['description'] = 'This field is required.'
        }
    } else {
        errors['department']['name'] = 'This field is required.';
        errors['department']['description'] = 'This field is required.';
    }
    return errors;
};



export const newLeaveFormValidator = (values) => {
    // let errors = {};
    // errors['leaveUpdate'] = {};
    // if (values.leaveUpdate) {

    //     if (!values.leaveUpdate.type) {
    //         errors['leaveUpdate']['type'] = 'This field is required.'
    //     }
    //     if (!values.leaveUpdate.day_coverage) {
    //         errors['leaveUpdate']['day_coverage'] = 'This field is required.'
    //     }
    //     // if (!values.leaveUpdate.startTime) {
    //     //     errors['leaveUpdate']['startTime'] = 'This field is required.'
    //     // }
    //     // if (!values.leaveUpdate.endTime) {
    //     //     errors['leaveUpdate']['endTime'] = 'This field is required.'
    //     // }
    //     if (!values.leaveUpdate.start_date) {
    //         errors['leaveUpdate']['start_date'] = 'This field is required.'
    //     }
    //     if (values.leaveUpdate.day_coverage) {

    //         if (values.leaveUpdate.day_coverage == 'true') {

    //             // if (!values.leaveUpdate.time_duration) {
    //             //     errors['leaveUpdate']['time_duration'] = 'This field is required, So please approach Start Date and End Date.'
    //             // }
    //             if (!values.leaveUpdate.end_date) {
    //                 errors['leaveUpdate']['end_date'] = 'This field is required.'
    //             }

    //         } else if (values.leaveUpdate.day_coverage == 'false') {

    //             // if (!values.leaveUpdate.time_duration) {
    //             //     errors['leaveUpdate']['time_duration'] = 'This field is required, So please approach Start time and End time.'
    //             // }

    //         }

    //     }
    //     // if (!values.leaveUpdate.info) {
    //     //     errors['leaveUpdate']['info'] = 'This field is required.'
    //     // }
    //     if (!values.leaveUpdate.account_id) {
    //         errors['leaveUpdate']['account_id'] = 'This field is required.'
    //     }
    //     if (!values.leaveUpdate.department_id) {
    //         errors['leaveUpdate']['department_id'] = 'This field is required.'
    //     }
    //     if (!values.leaveUpdate.manager_id) {
    //         errors['leaveUpdate']['manager_id'] = 'This field is required.'
    //     }

    // } else {
    //     errors['leaveUpdate']['type'] = 'This field is required.';
    //     errors['leaveUpdate']['day_coverage'] = 'This field is required.';
    //     errors['leaveUpdate']['time_duration'] = 'This field is required, So please approach Start time and End time.';
    //     // errors['leaveUpdate']['startTime'] = 'This field is required.';
    //     // errors['leaveUpdate']['endTime'] = 'This field is required.';
    //     errors['leaveUpdate']['start_date'] = 'This field is required.';
    //     errors['leaveUpdate']['end_date'] = 'This field is required.';
    //     // errors['leaveUpdate']['info'] = 'This field is required.';
    //     errors['leaveUpdate']['account_id'] = 'This field is required.';
    //     errors['leaveUpdate']['department_id'] = 'This field is required.';
    //     errors['leaveUpdate']['manager_id'] = 'This field is required.';
    // }

    // return errors;
}

export const leaveRequestRejectionValidator = (values) => {
    let errors = {};
    errors['rejectMod'] = {};
    if (values.rejectMod) {
        if (!values.rejectMod.reject_reason) {
            errors['rejectMod']['reject_reason'] = 'This field is required.'
        }
    } else {
        errors['rejectMod']['reject_reason'] = 'This field is required.';
    }
    return errors;
};

export const scheduledTaskValidator = (values) => {
    let errors = {};
    errors['scheduledTask'] = {};
    errors['scheduledTask']['subtasks'] = []
    if (values.scheduledTask) {
        
        if (!values.scheduledTask.task_name) {
            errors['scheduledTask']['task_name'] = 'This field is required.'
        }
        // if (!values.scheduledTask.company) {
        //     errors['scheduledTask']['company'] = 'This field is required.'
        // }
        if (!values.scheduledTask.job) {
            errors['scheduledTask']['job'] = 'This field is required.'
        }
        if (!values.scheduledTask.location) {
            errors['scheduledTask']['location'] = 'This field is required.'
        }
        if (!values.scheduledTask.start_date) {
            errors['scheduledTask']['start_date'] = 'This field is required.'
        }
        if (!values.scheduledTask.end_date) {
            errors['scheduledTask']['end_date'] = 'This field is required.'
        }

        if (values.scheduledTask && values.scheduledTask.subtasks) {
            values.scheduledTask.subtasks.map((tycoon, index) => {
                errors['scheduledTask']['subtasks'][index] = {}
                if (!(values['scheduledTask']['subtasks'][index]['ticket'])) {
                    errors['scheduledTask']['subtasks'][index]['ticket'] = 'This field is required.'
                }
                if (!(values['scheduledTask']['subtasks'][index]['skill_set'])) {
                    errors['scheduledTask']['subtasks'][index]['skill_set'] = 'This field is required.'
                }
                if (!(values['scheduledTask']['subtasks'][index]['priority'])) {
                    errors['scheduledTask']['subtasks'][index]['priority'] = 'This field is required.'
                }
                if (!(values['scheduledTask']['subtasks'][index]['start_date'])) {
                    errors['scheduledTask']['subtasks'][index]['start_date'] = 'This field is required.'
                }
                if (!(values['scheduledTask']['subtasks'][index]['end_date'])) {
                    errors['scheduledTask']['subtasks'][index]['end_date'] = 'This field is required.'
                }
                if (!(values['scheduledTask']['subtasks'][index]['triggerPoint'])) {
                    errors['scheduledTask']['subtasks'][index]['triggerPoint'] = 'This field is required.'
                }
            })
        }

    } else {

        errors['scheduledTask']['task_name'] = 'This field is required.';
        //errors['scheduledTask']['company'] = 'This field is required.';
        errors['scheduledTask']['job'] = 'This field is required.';
        errors['scheduledTask']['location'] = 'This field is required.';
        errors['scheduledTask']['start_date'] = 'This field is required.';
        errors['scheduledTask']['end_date'] = 'This field is required.';

        if (values.scheduledTask && values.scheduledTask.subtasks) {
            values.scheduledTask.subtasks.map((tycoon, index) => {
                errors['scheduledTask']['subtasks'][index] = {}
                errors['scheduledTask']['subtasks'][index]['ticket'] = 'This field is required.'
                errors['scheduledTask']['subtasks'][index]['skill_set'] = 'This field is required.'
                errors['scheduledTask']['subtasks'][index]['priority'] = 'This field is required.'
                errors['scheduledTask']['subtasks'][index]['start_date'] = 'This field is required.'
                errors['scheduledTask']['subtasks'][index]['quantity'] = 'This field is required.'
                errors['scheduledTask']['subtasks'][index]['triggerPoint'] = 'This field is required.'

            })
        }
    }

    return errors;
}


export const overRideAvailabilityFormValidator = (values) => {
    let errors = {};
    errors['data'] = {};
    if (values.data) {

        if (!values.data.start_date) {
            errors['data']['start_date'] = 'This field is required.'
        }

        if (!values.data.start_time) {
            errors['data']['start_time'] = 'This field is required.'
        }

        if (!values.data.end_time) {
            errors['data']['end_time'] = 'This field is required.'
        }

    } else {
        errors['data']['start_date'] = 'This field is required.';
        errors['data']['start_time'] = 'This field is required.';
        errors['data']['end_time'] = 'This field is required.';
    }

    return errors;
}

export const newShiftFormValidator = (values) => {
    let errors = {};
    errors['shiftOrigin'] = {};
    if (values.shiftOrigin) {

        if (!values.shiftOrigin.accountIds) {
            errors['shiftOrigin']['accountIds'] = 'This field is required.'
        }

        if (!values.shiftOrigin.user_id) {
            errors['shiftOrigin']['user_id'] = 'This field is required.'
        }

        if (!values.shiftOrigin.start_date) {
            errors['shiftOrigin']['start_date'] = 'This field is required.'
        }
        if (!values.shiftOrigin.end_date) {
            errors['shiftOrigin']['end_date'] = 'This field is required.'
        }

        if (!values.shiftOrigin.start_time) {
            errors['shiftOrigin']['start_time'] = 'This field is required.'
        }
        if (!values.shiftOrigin.end_time) {
            errors['shiftOrigin']['end_time'] = 'This field is required.'
        }

        // if (!values.shiftOrigin.notes) {
        //     errors['shiftOrigin']['notes'] = 'This field is required.'
        // }

    } else {
        errors['shiftOrigin']['accountIds'] = 'This field is required.';
        errors['shiftOrigin']['user_id'] = 'This field is required.';
        errors['shiftOrigin']['start_date'] = 'This field is required.';
        errors['shiftOrigin']['end_date'] = 'This field is required.';
        errors['shiftOrigin']['start_time'] = 'This field is required.';
        errors['shiftOrigin']['end_time'] = 'This field is required.';
        // errors['shiftOrigin']['notes'] = 'This field is required.';
    }

    return errors;
}

export const newShiftSwapFormValidator = (values) => {
    let errors = {};
    errors['swap'] = {};
    if (values.swap) {

        if (!values.swap.selectedFlEmp) {
            errors['swap']['selectedFlEmp'] = 'This field is required.'
        }

        if (!values.swap.choosedSwapDate) {
            errors['swap']['choosedSwapDate'] = 'This field is required.'
        }

        if (!values.swap.reason) {
            errors['swap']['reason'] = 'This field is required.'
        }

    } else {
        errors['swap']['selectedFlEmp'] = 'This field is required.';
        errors['swap']['choosedSwapDate'] = 'This field is required.';
        errors['swap']['reason'] = 'This field is required.';
    }

    return errors;
}

export const addAvailabilityValidator = (values) => {
    let errors = {};
    errors['data'] = {};
    if (values.data) {

        if (!values.data.start_date) {
            errors['data']['start_date'] = 'This field is required.'
        }

        if (!values.data.end_date) {
            errors['data']['end_date'] = 'This field is required.'
        }

    } else {
        errors['data']['start_date'] = 'This field is required.';
        errors['data']['end_date'] = 'This field is required.';
    }

    return errors;
}

export const newPreviewShiftValidator = (values) => {
    const isInspectorEligible = storage.get('isInspectorEligible')
    const user = storage.get('user')

    let errors = {};
    errors['shiftDetailJobs'] = {}
    errors['shiftDetailJobs']['jobs'] = []
    errors['shiftDetailJobs']['exceptions'] = []
    if (values.shiftDetailJobs) {
        if(!isInspectorEligible && !(user.role == userRoles.INSPECTOR)){
            if(values.shiftDetailJobs.jobs){
                values.shiftDetailJobs.jobs.map((tycoon, index) => {
                    errors['shiftDetailJobs']['jobs'][index] = {}
                    if (values['shiftDetailJobs']['jobs'][index] && (values['shiftDetailJobs']['jobs'][index]['customer_pay']) ==undefined ) {
                        errors['shiftDetailJobs']['jobs'][index]['customer_pay'] = 'This field is required.'
                    }
                    if ((values['shiftDetailJobs']['jobs'][index] !==undefined)) {
                        if( (!values['shiftDetailJobs']['jobs'][index]['rate']['value']) || (!values['shiftDetailJobs']['jobs'][index]['rate']['label']) ||(!values['shiftDetailJobs']['jobs'][index]['rate']['id'])){
                            errors['shiftDetailJobs']['jobs'][index]['rate'] = 'This field is required.'
                        }
                    }
                    
                })
            }
        }

            if(values.shiftDetailJobs.exceptions){
                values.shiftDetailJobs.exceptions.map((tycoon, index) => {
                    errors['shiftDetailJobs']['exceptions'][index] = {}
                    if (values['shiftDetailJobs']['exceptions'][index] && (!values['shiftDetailJobs']['exceptions'][index]['description']) && (values['shiftDetailJobs']['exceptions'][index]['status']['value'] === 'other') ) {
                        errors['shiftDetailJobs']['exceptions'][index]['description'] = 'This field is required.'
                    }
                    if ((values['shiftDetailJobs']['exceptions'][index] !==undefined)) {
                        if( (!values['shiftDetailJobs']['exceptions'][index]['status']['value']) || (!values['shiftDetailJobs']['exceptions'][index]['status']['label']) ){
                            errors['shiftDetailJobs']['exceptions'][index]['status'] = 'This field is required.'
                        }
                    }
                    
                })
        }
        
    }
    
    return errors;
}

export const newPCTValidator = (values) => {
    let errors = {};
    errors['ticket'] = {};
    if (values.ticket) {
        if (values.ticket.account_id) {

            if (!values.ticket.account_id.value) {
                errors['ticket']['account_id'] = 'This field is required.'
            }
        } else {
            errors['ticket']['account_id'] = 'This field is required.'
        }

        if (values.ticket.floor_id) {

            if (!values.ticket.floor_id.value) {
                errors['ticket']['floor_id'] = 'This field is required.'
            }
        } else {
            errors['ticket']['floor_id'] = 'This field is required.'
        }

        if (values.ticket.department_id) {

            if (!values.ticket.department_id.value) {
                errors['ticket']['department_id'] = 'This field is required.'
            }
        } else {
            errors['ticket']['department_id'] = 'This field is required.'
        }

        if (!values.ticket.phone_number) {
              errors['ticket']['phone_number'] = 'This field is required.'
            
        } else if (values.ticket.phone_number.length < 12) {
            errors['ticket']['phone_number'] = 'Must be 10 digit number'
        }

        if (!values.ticket.first_name) {
            errors['ticket']['first_name'] = 'This field is required.'
        }

        if (!values.ticket.message) {
            errors['ticket']['message'] = 'This field is required.'
        }

        if (!values.ticket.subject) {
            errors['ticket']['subject'] = 'This field is required.'
        }
        if (!values.ticket.last_name) {
            errors['ticket']['last_name'] = 'This field is required.'
        }
        if (!values.ticket.email) {
            errors['ticket']['email'] = 'This field is required.'
        } else if (!EMAIL_REGEXP.test(values['ticket']['email'])) {
            errors['ticket']['email'] = 'Email must be valid';
        }
    } else {
        errors['ticket']['account_id'] = 'This field is required.';
        errors['ticket']['floor_id'] = 'This field is required.';
        errors['ticket']['first_name'] = 'This field is required.';
        errors['ticket']['subject'] = 'This field is required.';
        errors['ticket']['message'] = 'This field is required.';
        errors['ticket']['last_name'] = 'This field is required.';
        errors['ticket']['email'] = 'This field is required.';
        errors['ticket']['department_id'] = 'This field is required.';
        errors['ticket']['phone_number'] = 'This field is required.';
    }

    return errors;
}

export const newJobAssosiationFormValidator = (values,props) => {
    let errors = {};
    errors['item'] = {};
    if (values.item) {

        if (!values.item.name) {
            errors['item']['name'] = 'This field is required.'
        }
        if (props.selectedJobIds.length ==0) {
            errors['item']['selectedJobs'] = 'Please select atleast one job.'
        }
        
    } else {
        errors['item']['name'] = 'This field is required.';
        errors['item']['selectedJobs'] = 'Please select atleast one job.'
    }
    
    return errors;
}


export const vehicleSafetyFormValidator = (values) => {
    let errors = {};
    errors['data'] = {};
    if (values.data) {
        if (!values.data.date) {
            errors['data']['date'] = 'This field is required.';
        }
        if (!values.data.employee_id) {
            errors['data']['employee_id'] = 'This field is required.';
        }
        if (!values.data.unit) {
            errors['data']['unit'] = 'This field is required.';
        }
        if (!values.data.mileage) {
            errors['data']['mileage'] = 'This field is required.';
        }
        if (!values.data.car_wash) {
            errors['data']['car_wash'] = 'This field is required.';
        }
        if (!values.data.seat_belt) {
            errors['data']['seat_belt'] = 'This field is required.';
        }
        if (!values.data.fuel_card) {
            errors['data']['fuel_card'] = 'This field is required.';
        }
        if (!values.data.head_lamps) {
            errors['data']['head_lamps'] = 'This field is required.';
        }
        if (!values.data.brake_lights) {
            errors['data']['brake_lights'] = 'This field is required.';
        }
        if (!values.data.turn_indicators) {
            errors['data']['turn_indicators'] = 'This field is required.';
        }
        if (!values.data.tail_lamps) {
            errors['data']['tail_lamps'] = 'This field is required.';
        }
        if (!values.data.transmission) {
            errors['data']['transmission'] = 'This field is required.';
        }
        if (!values.data.leaks) {
            errors['data']['leaks'] = 'This field is required.';
        }
        if (!values.data.tire_pres_wear) {
            errors['data']['tire_pres_wear'] = 'This field is required.';
        }
        if (!values.data.mirrors) {
            errors['data']['mirrors'] = 'This field is required.';
        }
        if (!values.data.instruments) {
            errors['data']['instruments'] = 'This field is required.';
        }
        if (!values.data.horn) {
            errors['data']['horn'] = 'This field is required.';
        }
        if (!values.data.windows) {
            errors['data']['windows'] = 'This field is required.';
        }
        if (!values.data.upholstery) {
            errors['data']['upholstery'] = 'This field is required.';
        }
        if (!values.data.tools_jack) {
            errors['data']['tools_jack'] = 'This field is required.';
        }
        if (!values.data.accident_form) {
            errors['data']['accident_form'] = 'This field is required.';
        }
        if (!values.data.insurance_reg) {
            errors['data']['insurance_reg'] = 'This field is required.';
        }
        if (!values.data.enterprise_card) {
            errors['data']['enterprise_card'] = 'This field is required.';
        }
        if (!values.data.vehicle_body) {
            errors['data']['vehicle_body'] = 'This field is required.';
        }
        if (!values.data.engine_light) {
            errors['data']['engine_light'] = 'This field is required.';
        }
        if (!values.data.jump_starter) {
            errors['data']['jump_starter'] = 'This field is required.';
        }
        if (!values.data.ready_to_submit) {
            errors['data']['ready_to_submit'] = 'This field is required.';
        }
        if (values.data.email_preference && !values.data.emailaddress) {
            errors['data']['emailaddress'] = 'This field is required.';
        }else if (!EMAIL_REGEXP.test(values['data']['emailaddress'])) {
            errors['data']['emailaddress'] = 'Email must be valid';
        }

        if (values.data.head_lamps == 'false' && !values.data.description_head_lamps) {
            errors['data']['description_head_lamps'] = 'This field is required.';
        }
        
        if (values.data.brake_lights == 'false' && !values.data.description_brake_lights) {
            errors['data']['description_brake_lights'] = 'This field is required.';
        }
        
        if (values.data.turn_indicators == 'false' && !values.data.description_turn_indicators) {
            errors['data']['description_turn_indicators'] = 'This field is required.';
        }
        
        if (values.data.tail_lamps == 'false' && !values.data.description_tail_lamps) {
            errors['data']['description_tail_lamps'] = 'This field is required.';
        }
        
        if (values.data.transmission == 'false' && !values.data.description_transmission) {
            errors['data']['description_transmission'] = 'This field is required.';
        }
        
        if (values.data.leaks == 'false' && !values.data.description_leaks) {
            errors['data']['description_leaks'] = 'This field is required.';
        }
        
        if (values.data.tire_pres_wear == 'false' && !values.data.description_tire_pres_wear) {
            errors['data']['description_tire_pres_wear'] = 'This field is required.';
        }
        
        if (values.data.mirrors == 'false' && !values.data.description_mirrors) {
            errors['data']['description_mirrors'] = 'This field is required.';
        }
        
        if (values.data.instruments == 'false' && !values.data.description_instruments) {
            errors['data']['description_instruments'] = 'This field is required.';
        }
        
        if (values.data.horn == 'false' && !values.data.description_horn) {
            errors['data']['description_horn'] = 'This field is required.';
        }
        
        if (values.data.windows == 'false' && !values.data.description_windows) {
            errors['data']['description_windows'] = 'This field is required.';
        }
        
        if (values.data.upholstery == 'false' && !values.data.description_upholstery) {
            errors['data']['description_upholstery'] = 'This field is required.';
        }
        
        if (values.data.tools_jack == 'false' && !values.data.description_tools_jack) {
            errors['data']['description_tools_jack'] = 'This field is required.';
        }
        
        if (values.data.accident_form == 'false' && !values.data.description_accident_form) {
            errors['data']['description_accident_form'] = 'This field is required.';
        }
        
        if (values.data.insurance_reg == 'false' && !values.data.description_insurance_reg) {
            errors['data']['description_insurance_reg'] = 'This field is required.';
        }
        
        if (values.data.enterprise_card == 'false' && !values.data.description_enterprise_card) {
            errors['data']['description_enterprise_card'] = 'This field is required.';
        }
        
        if (values.data.engine_light == 'true' && !values.data.description_engine_light_on) {
            errors['data']['description_engine_light_on'] = 'This field is required.';
        }

        if ((values.data.vehicle_body  == 'false' || values.data.jump_starter  == 'true') && !values.data.additional_notes) {
            errors['data']['additional_notes'] = 'This field is required.';
        }
        
    } else {
        errors['data']['date'] = 'This field is required.';
        errors['data']['seat_belt'] = 'This field is required.';
        errors['data']['fuel_card'] = 'This field is required.';
        errors['data']['employee_id'] = 'This field is required.';
        errors['data']['unit'] = 'This field is required.';
        errors['data']['mileage'] = 'This field is required.';
        errors['data']['car_wash'] = 'This field is required.';
        errors['data']['head_lamps'] = 'This field is required.';
        errors['data']['brake_lights'] = 'This field is required.';
        errors['data']['turn_indicators'] = 'This field is required.';
        errors['data']['tail_lamps'] = 'This field is required.';
        errors['data']['transmission'] = 'This field is required.';
        errors['data']['leaks'] = 'This field is required.';
        errors['data']['tire_pres_wear'] = 'This field is required.';
        errors['data']['mirrors'] = 'This field is required.';
        errors['data']['instruments'] = 'This field is required.';
        errors['data']['horn'] = 'This field is required.';
        errors['data']['windows'] = 'This field is required.';
        errors['data']['upholstery'] = 'This field is required.';
        errors['data']['tools_jack'] = 'This field is required.';
        errors['data']['accident_form'] = 'This field is required.';
        errors['data']['insurance_reg'] = 'This field is required.';
        errors['data']['enterprise_card'] = 'This field is required.';
        errors['data']['vehicle_body'] = 'This field is required.';
        errors['data']['engine_light'] = 'This field is required.';
        errors['data']['jump_starter'] = 'This field is required.';
        errors['data']['ready_to_submit'] = 'This field is required.';
    }

    return errors;
}

export const trailerSafetyFormValidator = (values) => {
    let errors = {};
    errors['data'] = {};
    if (values.data) {
        if (!values.data.date) {
            errors['data']['date'] = 'This field is required.';
        }
        
        if (!values.data.employee_id) {
            errors['data']['employee_id'] = 'This field is required.';
        }
        
        if (!values.data.trailer_license_plate) {
            errors['data']['trailer_license_plate'] = 'This field is required.';
        }
        
        if (!values.data.generator_hours) {
            errors['data']['generator_hours'] = 'This field is required.';
        }
        
        if (!values.data.brake_lights) {
            errors['data']['brake_lights'] = 'This field is required.';
        }
        
        if (!values.data.turn_signals) {
            errors['data']['turn_signals'] = 'This field is required.';
        }
        
        if (!values.data.tail_lights) {
            errors['data']['tail_lights'] = 'This field is required.';
        }
        
        if (!values.data.tire_pressure) {
            errors['data']['tire_pressure'] = 'This field is required.';
        }

        if (values.data?.tire_pressure == 'right_wheel_psi' && !values.data?.right_wheel_psi) {
            errors['data']['right_wheel_psi'] = 'This field is required.';
        }
        if (values.data?.tire_pressure == 'left_wheel_psi' && !values.data?.left_wheel_psi) {
            errors['data']['left_wheel_psi'] = 'This field is required.';
        }
        
        if (!values.data.insurance) {
            errors['data']['insurance'] = 'This field is required.';
        }
        
        if (!values.data.registration) {
            errors['data']['registration'] = 'This field is required.';
        }
        
        if (!values.data.gas_card) {
            errors['data']['gas_card'] = 'This field is required.';
        }
        
        if (!values.data.tools_psi_gage) {
            errors['data']['tools_psi_gage'] = 'This field is required.';
        }
        
        if (values.data.email_preference && !values.data.emailaddress) {
            errors['data']['emailaddress'] = 'This field is required.';
        }else if (!EMAIL_REGEXP.test(values['data']['emailaddress'])) {
            errors['data']['emailaddress'] = 'Email must be valid';
        }

    } else {
        errors['data']['date'] = 'This field is required.';
        errors['data']['employee_id'] = 'This field is required.';
        errors['data']['trailer_license_plate'] = 'This field is required.';
        errors['data']['generator_hours'] = 'This field is required.';
        errors['data']['brake_lights'] = 'This field is required.';
        errors['data']['turn_signals'] = 'This field is required.';
        errors['data']['tail_lights'] = 'This field is required.';
        errors['data']['tire_pressure'] = 'This field is required.';
        errors['data']['insurance'] = 'This field is required.';
        errors['data']['registration'] = 'This field is required.';
        errors['data']['gas_card'] = 'This field is required.';
        errors['data']['tools_psi_gage'] = 'This field is required.';

    }

    return errors;
};

export const golfCartSafetyFormValidator = (values) => {
    let errors = {};
    errors['data'] = {};
    if (values.data) {
        if (!values.data.date) {
            errors['data']['date'] = 'This field is required.';
        }
        if (!values.data.employee_id) {
            errors['data']['employee_id'] = 'This field is required.';
        }
        if (!values.data.unit_number) {
            errors['data']['unit_number'] = 'This field is required.';
        }
        if (!values.data.hours_of_operations_mileage) {
            errors['data']['hours_of_operations_mileage'] = 'This field is required.';
        }
        if (!values.data.brake_lights) {
            errors['data']['brake_lights'] = 'This field is required.';
        }
        if (values.data.brake_lights  == 'false' && !values.data.description_brake_lights) {
            errors['data']['description_brake_lights'] = 'This field is required.';
        }
        if (!values.data.turn_signals) {
            errors['data']['turn_signals'] = 'This field is required.';
        }
        if (values.data.turn_signals  == 'false' && !values.data.description_turn_signals) {
            errors['data']['description_turn_signals'] = 'This field is required.';
        }
        if (!values.data.headlight) {
            errors['data']['headlight'] = 'This field is required.';
        }
        if (values.data.headlight  == 'false' && !values.data.description_headlight) {
            errors['data']['description_headlight'] = 'This field is required.';
        }
        if (!values.data.extension_cord_quality) {
            errors['data']['extension_cord_quality'] = 'This field is required.';
        }
        if (!values.data.tail_lights) {
            errors['data']['tail_lights'] = 'This field is required.';
        }
        if (values.data.tail_lights  == 'false' && !values.data.description_tail_lights) {
            errors['data']['description_tail_lights'] = 'This field is required.';
        }
        if (!values.data.front_right_psi) {
            errors['data']['front_right_psi'] = 'This field is required.';
        }
        if (!values.data.front_left_psi) {
            errors['data']['front_left_psi'] = 'This field is required.';
        }
        if (!values.data.rear_right_psi) {
            errors['data']['rear_right_psi'] = 'This field is required.';
        }
        if (!values.data.rear_left_psi) {
            errors['data']['rear_left_psi'] = 'This field is required.';
        }
        if (!values.data.charger_plug) {
            errors['data']['charger_plug'] = 'This field is required.';
        }
        if (values.data.charger_plug  == 'false' && !values.data.description_charger_plug) {
            errors['data']['description_charger_plug'] = 'This field is required.';
        }
        if (!values.data.seat_condition) {
            errors['data']['seat_condition'] = 'This field is required.';
        }
        if (!values.data.seat_belt_conditions) {
            errors['data']['seat_belt_conditions'] = 'This field is required.';
        }
        if ((values.data.seat_condition  == 'false' || values.data.seat_belt_conditions  == 'false') && !values.data.description_seat_condition) {
            errors['data']['description_seat_condition'] = 'This field is required.';
        }
        if (!values.data.mirrors) {
            errors['data']['mirrors'] = 'This field is required.';
        }
        if (values.data.mirrors  == 'false' && !values.data.description_mirrors) {
            errors['data']['description_mirrors'] = 'This field is required.';
        }
        if (!values.data.backup_camera) {
            errors['data']['backup_camera'] = 'This field is required.';
        }
        if (values.data.backup_camera  == 'false' && !values.data.description_backup_camera) {
            errors['data']['description_backup_camera'] = 'This field is required.';
        }
        if (!values.data.brakes) {
            errors['data']['brakes'] = 'This field is required.';
        }
        if (values.data.brakes  == 'false' && !values.data.description_brakes) {
            errors['data']['description_brakes'] = 'This field is required.';
        }
        if (!values.data.exterior_body) {
            errors['data']['exterior_body'] = 'This field is required.';
        }
        if (values.data.exterior_body  == 'false' && !values.data.description_exterior_body) {
            errors['data']['description_exterior_body'] = 'This field is required.';
        }
        if (!values.data.tools_psi_gage) {
            errors['data']['tools_psi_gage'] = 'This field is required.';
        }
        
        if (values.data.email_preference && !values.data.emailaddress) {
            errors['data']['emailaddress'] = 'This field is required.';
        }else if (!EMAIL_REGEXP.test(values['data']['emailaddress'])) {
            errors['data']['emailaddress'] = 'Email must be valid';
        }

    } else {
        errors['data']['date'] = 'This field is required.';
        errors['data']['employee_id'] = 'This field is required.';
        errors['data']['unit_number'] = 'This field is required.';
        errors['data']['hours_of_operations_mileage'] = 'This field is required.';
        errors['data']['brake_lights'] = 'This field is required.';
        errors['data']['turn_signals'] = 'This field is required.';
        errors['data']['headlight'] = 'This field is required.';
        errors['data']['extension_cord_quality'] = 'This field is required.';
        errors['data']['tail_lights'] = 'This field is required.';
        errors['data']['front_right_psi'] = 'This field is required.';
        errors['data']['front_left_psi'] = 'This field is required.';
        errors['data']['rear_right_psi'] = 'This field is required.';
        errors['data']['rear_left_psi'] = 'This field is required.';
        errors['data']['charger_plug'] = 'This field is required.';
        errors['data']['seat_condition'] = 'This field is required.';
        errors['data']['seat_belt_conditions'] = 'This field is required.';
        errors['data']['mirrors'] = 'This field is required.';
        errors['data']['backup_camera'] = 'This field is required.';
        errors['data']['brakes'] = 'This field is required.';
        errors['data']['exterior_body'] = 'This field is required.';
        errors['data']['tools_psi_gage'] = 'This field is required.';
    }

    return errors;
};
